import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import { SxProps } from '@mui/system'
import Stack from '@mui/material/Stack'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import StraightenIcon from '@mui/icons-material/Straighten'

// KN imports
import KNAttribute from 'components/KN_Molecules/KNAttribute/KNAttribute'
import { KNWeightIcon, KNVolumeIcon, KNPalletIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import { LegData } from './TripDetails.types'

export interface LegCargoProps {
  leg: LegData
  sx?: SxProps
}

const LegCargo = ({ leg, sx }: LegCargoProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <Stack
      data-test="leg-cargo"
      direction="row"
      alignItems="start"
      sx={{ ...sx, flexWrap: 'wrap', columnGap: 1, rowGap: 0 }}
    >
      <KNAttribute
        name="items"
        value={leg.attributes.numberOfItems}
        suffix={t('screens.cs.trip_details.card.items_count', { count: leg.attributes.numberOfItems })}
        icon={<Inventory2OutlinedIcon />}
      />
      <KNAttribute
        name="weight"
        value={leg.attributes.weightWithUnit ? leg.attributes.weightWithUnit.value : leg.attributes.weight}
        suffix={leg.attributes.weightWithUnit ? leg.attributes.weightWithUnit.unitOfMeasurement : 'KGM'}
        icon={<KNWeightIcon />}
      />
      <KNAttribute
        name="volume"
        value={leg.attributes.volumeWithUnit ? leg.attributes.volumeWithUnit.value : leg.attributes.volume}
        suffix={leg.attributes.volumeWithUnit ? leg.attributes.volumeWithUnit.unitOfMeasurement : 'MTQ'}
        icon={<KNVolumeIcon />}
      />
      <KNAttribute
        name="loading-meters"
        value={
          leg.attributes.loadingMetersWithUnit
            ? leg.attributes.loadingMetersWithUnit.value
            : leg.attributes.loadingMeters
        }
        suffix={leg.attributes.loadingMetersWithUnit ? leg.attributes.loadingMetersWithUnit.unitOfMeasurement : 'MTR'}
        icon={<StraightenIcon />}
      />
      <KNAttribute
        name="number-of-pallets"
        value={leg.attributes.numberOfPallets}
        suffix={t('screens.cs.trip_details.card.pallets_count', { count: leg.attributes.numberOfPallets })}
        icon={<KNPalletIcon />}
        hideEmpty
      />
    </Stack>
  )
}

export default LegCargo
