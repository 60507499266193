// @mui imports
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

// Types
import KNLoaderProps from './types'

const KNLoader: React.FC<KNLoaderProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={70} sx={{ mb: 2 }} />
      {children}
    </Box>
  )
}

export default KNLoader
