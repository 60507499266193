import { ReactElement } from 'react'
import './erroraccessdenied.css'

const TruckNorrisAccessDenied = (): ReactElement | null => {
  return (
    <div style={{ width: '350px', height: '250px', position: 'relative', overflow: 'hidden' }}>
      <div className="bg"></div>
      <div className="chuck bounce-in-right"></div>
    </div>
  )
}

export default TruckNorrisAccessDenied
