import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { processServerErrorMessages } from 'global/helpers/form'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { VehicleData } from './VehicleManager.types'
import { deleteVehicle } from './VehicleManager.service'
import { getDataForDeleteVehicle } from './VehicleManager.helpers'

interface DeleteVehicleDialogPayload {
  vehicle: VehicleData
}

interface DeleteVehicleDialogProps {
  payload: DeleteVehicleDialogPayload
  open: boolean
  onAction: (updatedVehicle: VehicleData, action: string) => void
  onClose: () => void
}

const DeleteVehicleDialog = ({ payload, open, onAction, onClose }: DeleteVehicleDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, formState, setError } = useForm()
  const [deletionConfirmed, setDeletionConfirmed] = useState(false)

  useEffect(() => {
    setDeletionConfirmed(false)
  }, [payload])

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      await deleteVehicle(payload.vehicle.id)
      onAction(getDataForDeleteVehicle(payload.vehicle), 'delete')
      analyticsEvent('polestar_cs_vehicle_deleted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.vehicle.displayLicensePlate} — ${t('screens.cs.vehicle_manager.card.actions.delete')}`}
      actions={
        !payload.vehicle.telematicsReady && (
          <>
            <KNLoadingButton
              data-test="delete-button"
              type="submit"
              color="error"
              variant="contained"
              disabled={!deletionConfirmed}
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('screens.cs.vehicle_manager.delete_vehicle.delete')}
            </KNLoadingButton>
          </>
        )
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      {payload.vehicle.telematicsReady ? (
        <KNTypography color="grey.500">
          {t('screens.cs.vehicle_manager.delete_vehicle.telematics_notice')}
        </KNTypography>
      ) : (
        <>
          <KNTypography color="grey.500">
            {t('screens.cs.vehicle_manager.delete_vehicle.confirmation')}
          </KNTypography>
          <FormControlLabel
            control={
              <Checkbox
                data-test="confirm-checkbox"
                value={deletionConfirmed}
                checked={deletionConfirmed}
                onChange={(): void => setDeletionConfirmed(!deletionConfirmed)}
              />
            }
            label={t('shared.manager.deletion_confirm_checkbox')}
          />
        </>
      )}
    </KNDialog>
  )
}

export default DeleteVehicleDialog
