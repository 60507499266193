import { ReactElement, Ref, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Types
import { KNDialogProps } from './types'

const UpSlideTransition = forwardRef((props: TransitionProps & { children: ReactElement }, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
))

const LeftSlideTransition = forwardRef((props: TransitionProps & { children: ReactElement }, ref: Ref<unknown>) => (
  <Slide direction="left" ref={ref} {...props} />
))

const getSlideTransition = (direction?: 'up' | 'left') => {
  switch (direction) {
    case 'up':
      return UpSlideTransition
    case 'left':
      return LeftSlideTransition
    default:
      return undefined
  }
}

const KNDialog = ({
  open,
  onClose,
  title,
  children,
  actions,
  closeAction = true,
  closeLabel,
  allowBackdropClose = false,
  preventClosing,
  maxWidth,
  slideDirection,
  fullScreen,
  onSubmit,
  sx,
}: KNDialogProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const handleClose = (event, reason): void => {
    if (preventClosing) {
      return
    }
    if (reason && reason === 'backdropClick' && !allowBackdropClose) {
      return
    }
    onClose?.()
  }

  const baseElement = (
    <>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>{children}</DialogContent>
      {(closeAction || actions) && (
        <DialogActions>
          {closeAction && (
            <KNButton color="primary" variant="text" onClick={(event): void => handleClose(event, 'buttonClick')}>
              {closeLabel ?? t('general.close')}
            </KNButton>
          )}
          {actions}
        </DialogActions>
      )}
    </>
  )

  return (
    <Dialog
      data-test="KN-dialog"
      maxWidth={maxWidth ?? 'sm'}
      fullWidth
      fullScreen={fullScreen ?? isMobile}
      open={open}
      onClose={handleClose}
      TransitionComponent={getSlideTransition(slideDirection ?? (isMobile ? 'up' : undefined))}
      sx={sx}
    >
      {onSubmit ? <KNForm onSubmit={onSubmit}>{baseElement}</KNForm> : baseElement}
    </Dialog>
  )
}

export default KNDialog
