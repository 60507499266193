import { ReactElement, useCallback } from 'react'

// @mui imports
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

// KN imports
import { KNButtonGroupProps } from './types'

const KNButtonGroup = ({
  value,
  onChange,
  options,
  orientation,
  size,
  sx,
  fullWidth = true,
  disabled,
}: KNButtonGroupProps): ReactElement => {
  const handleChange = useCallback((event: React.MouseEvent, value: any): void => {
    // NOTE: makes so once the value is selected it can't be unselected
    if (value !== null) {
      onChange?.(value)
    }
  }, [])

  return (
    <ToggleButtonGroup
      orientation={orientation}
      size={size}
      fullWidth={fullWidth}
      color="primary"
      value={value}
      onChange={handleChange}
      exclusive
      data-test={'knButtonGroup'}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value} color={option.color}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default KNButtonGroup
