import { ReactElement } from 'react'
import type { Clusterer } from '@react-google-maps/marker-clusterer'

// KN imports
import { Geofence, GeoPoint, GeoPointsGroup, StopsSequenceGroup } from 'screens/TripDetails/TripDetails.types'
import { StopsLocationGroup } from 'screens/TripDashboard/TripDashboard.types'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'

export interface KNMapProps<T = object> {
  markers: MapMarker[]
  geoPoints?: GeoPoint[]
  groupedGeoPoints?: GeoPointsGroup[]
  zoom?: number
  withHeading?: boolean
  onMarkerClick?: (marker: MapMarker, map: google.maps.Map) => void
  center?: GeoPoint
}

export interface KNMapMarkerProps {
  marker: MapMarker
  onMarkerClick?: (marker: MapMarker) => void
  clusterer?: Clusterer
}

export interface KNMapTimestampMarkerProps<T = object> {
  map: google.maps.Map
  geoPoints: GeoPoint[]
}

export interface KNMapTooltipProps {
  map: google.maps.Map
  markers: MapMarker[]
}

export enum MapMarkerState {
  Default,
  Active,
  Muted,
}

export type MapMarker = BasicMapMarker | SequenceGroupMapMarker | LocationGroupMapMarker

export interface BasicMapMarker {
  id: string
  latitude: number
  longitude: number
  instance?: google.maps.Marker
  type?: string
  label?: string
  heading?: number
  geofence?: number // radius in meters
  color?: string
  state?: MapMarkerState
  tooltip?: ReactElement
  cluster?: string
}

export interface SequenceGroupMapMarker extends BasicMapMarker {
  sequenceGroup: StopsSequenceGroup
}

export interface LocationGroupMapMarker extends BasicMapMarker {
  locationGroup: StopsLocationGroup
}

export interface VehicleMapMarker extends BasicMapMarker {
  vehicle: VehicleData
  tripCids: string[]
}

export const hasSequenceGroup = (marker: MapMarker): marker is SequenceGroupMapMarker =>
  'sequenceGroup' in marker

export const hasLocationGroup = (marker: MapMarker): marker is LocationGroupMapMarker =>
  'locationGroup' in marker

export const hasVehicleData = (marker: MapMarker): marker is VehicleMapMarker =>
  'vehicle' in marker

export interface MapTooltip {
  id: string
  content: ReactElement
  priority?: number
}

export interface MapPixel {
  x: number
  y: number
}

export interface CoordsProps {
  lat: number
  lng: number
}
