// @mui imports
import { createTheme } from '@mui/material/styles'

// KN base styles
import borders from './base/borders'
import shadows from './base/shadows'
import boxShadows from './base/boxShadows'
import colors from './base/colors'
import globals from './base/globals'
import typography from './base/typography'

// KN Components
import accordion from './components/accordion/index'
import accordionDetails from './components/accordion/accordionDetails'
import accordionSummary from './components/accordion/accordionSummary'
import autocomplete from './components/form/autocomplete'
import button from './components/button/index'
import checkbox from './components/form/checkbox'
import chip from './components/chip/chip'
import dateRangePicker from './components/daterangepicker/daterangepicker'
import datePicker from './components/datepicker/datepicker'
import dialog from './components/dialog'
import dialogActions from './components/dialog/dialogActions'
import dialogContent from './components/dialog/dialogContent'
import dialogTitle from './components/dialog/dialogTitle'
import formControl from './components/form/formControl'
import formHelperText from './components/form/formHelperText'
import formLabel from './components/form/formLabel'
import input from './components/form/input'
import inputLabel from './components/form/inputLabel'
import inputOutlined from './components/form/inputOutlined'
import link from './components/link/link'
import menu from './components/menu'
import menuItem from './components/menu/menuItem'
import pagination from './components/pagination'
import paginationItem from './components/pagination/paginationItem'
import popover from './components/popover/popover'
import radio from './components/form/radio'
import stepConnector from './components/stepper/stepConnector'
import stepLabel from './components/stepper/stepLabel'
import switchButton from './components/form/switchButton'
import tableCell from './components/table/tableCell'
import tableRow from './components/table/tableRow'
import tooltip from './components/tooltip/tooltip'

// KN functions
import boxShadow from './functions/boxShadow'
import hexToRgb from './functions/hexToRgb'
import linearGradient from './functions/linearGradient'
import pxToRem from './functions/pxToRem'
import rgba from './functions/rgba'

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  palette: { ...colors },
  borders: { ...borders },
  shadows: { ...shadows },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...dateRangePicker,
        ...datePicker,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiAccordion: { ...accordion },
    MuiAccordionDetails: { ...accordionDetails },
    MuiAccordionSummary: { ...accordionSummary },
    MuiAutocomplete: { ...autocomplete },
    MuiButton: { ...button },
    MuiCheckbox: { ...checkbox },
    MuiChip: { ...chip },
    MuiDialog: { ...dialog },
    MuiDialogActions: { ...dialogActions },
    MuiDialogContent: { ...dialogContent },
    MuiDialogTitle: { ...dialogTitle },
    MuiFormControl: { ...formControl },
    MuiFormHelperText: { ...formHelperText },
    MuiFormLabel: { ...formLabel },
    MuiInput: { ...input },
    MuiInputLabel: { ...inputLabel },
    MuiLink: { ...link },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiOutlinedInput: { ...inputOutlined },
    MuiPagination: { ...pagination },
    MuiPaginationItem: { ...paginationItem },
    MuiPopover: { ...popover },
    MuiRadio: { ...radio },
    MuiStepConnector: { ...stepConnector },
    MuiStepLabel: { ...stepLabel },
    MuiSwitch: { ...switchButton },
    MuiTableCell: { ...tableCell },
    MuiTableRow: { ...tableRow },
    MuiTooltip: { ...tooltip },
  },
})
