import boxShadows from 'assets/theme/base/boxShadows'
import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import pxToRem from 'assets/theme/functions/pxToRem'

const { white, primary, secondary } = colors
const { size } = typography
const { buttonBoxShadow } = boxShadows

const contained = {
  base: {
    border: `${pxToRem(1)} solid inherit`,
    minHeight: pxToRem(40),
    color: white.main,
    padding: `${pxToRem(12)} ${pxToRem(24)}`,
    transition: 'all 300ms ease-in-out',

    '&:hover': {
      boxShadow: buttonBoxShadow.main,
    },

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85,
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(8)} ${pxToRem(20)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  medium: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(16)} ${pxToRem(40)}`,
    fontSize: size.md,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(16)} ${pxToRem(40)}`,
    fontSize: size.lg,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: primary.main,
    borderColor: primary.main,

    '&:hover': {
      backgroundColor: primary.focus,
    },

    '&:focus:not(:hover)': {
      borderColor: primary.main,
      backgroundColor: primary.main,
    },
  },

  secondary: {
    backgroundColor: secondary.main,
    borderColor: secondary.main,

    '&:hover': {
      backgroundColor: secondary.main,
    },

    '&:focus:not(:hover)': {
      borderColor: secondary.main,
      backgroundColor: secondary.main,
    },
  },
}

export default contained
