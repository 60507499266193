// @mui imports
import { styled, Theme } from '@mui/material/styles'

// types
import Chip from '@mui/material/Chip'

const KNChipRoot = styled(Chip)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
  const { palette } = theme
  const { color, variant } = ownerState
  const { white, text, gradients, primary } = palette

  // styles for the chip with variant="contained"
  const containedStyles = (): Record<any, any> => {
    // background value
    const backgroundValue = palette[color] ? palette[color].main : primary.light

    // color value
    let colorValue = white?.main

    if (color === 'white') {
      colorValue = text.main
    } else if (color === 'light') {
      colorValue = gradients.dark?.state || white?.main
    }

    return {
      backgroundColor: backgroundValue,
      color: colorValue,

      '&:disabled': {
        opacity: 0.5,
        color: colorValue,
      },
    }
  }

  // styles for the chip with variant="gradient"
  const gradientStyles = (): Record<any, any> => {
    // background value
    const backgroundValue =
      color === 'white' || !gradients[color]
        ? white?.main
        : `linear-gradient(112.86deg, ${gradients[color].main as string} 5.19%, ${
            gradients[color].state as string
          } 48.68%)`

    // color value
    let colorValue = white?.main

    if (color === 'white') {
      colorValue = text.main
    } else if (color === 'light') {
      colorValue = gradients.dark.state
    }

    return {
      background: backgroundValue,
      color: colorValue,

      '&:disabled': {
        opacity: 0.5,
        color: colorValue,
      },
    }
  }
  return {
    ...(variant === 'contained' && containedStyles()),
    ...(variant === 'gradient' && gradientStyles()),
  }
})

export default KNChipRoot
