import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { CompanyListContext } from 'context/companies/CompanyListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

// Types
import { CompanyType } from 'context/authentication/Company.types'

const CompanyFilters = (): ReactElement => {
  const { t } = useTranslation()
  const [companyListState, companyListDispatch] = useContext(CompanyListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      companyListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    companyListDispatch({ type: 'resetFilters' })
    analyticsEvent('polestar_cv_reset_company_filters_button_clicked')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid data-test="company-search-input" item xs={12} sm={4}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={companyListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('screens.shared.company_manager.filters.search')} />
            )}
            size="small"
          />
        </Grid>
        <Grid data-test="company-type-dropdown" item xs={12} sm={2}>
          <KNAdvancedDropdown
            label={t('screens.shared.company_manager.filters.types')}
            options={Object.keys(CompanyType)
              .filter((type) => type !== 'Operator')
              .map((type) => {
                return {
                  value: type,
                  label: t(`screens.shared.company_manager.company_types.${type}`),
                }
              })}
            value={companyListState.filters.types ?? []}
            onChange={(event): void => {
              setPayload({
                types: event.target.value as string[],
              })
            }}
            size="small"
            multiple
          />
        </Grid>
      </Grid>
      {companyListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="secondary"
          onClick={handleResetClick}
          sx={{
            padding: 0,
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default CompanyFilters
