import { ReactElement, memo, useEffect } from 'react'
import { Link } from 'react-router-dom'

// @mui imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

// KN components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import TruckNorrisAccessDenied from './TruckNorris/TruckNorrisAccessDenied'

// Functional
import { analyticsPageView } from 'global/helpers/analytics'
import { getTruckNorrisQuote } from './Error.helpers'

// Data
import { truckNorrisTranslations } from './ErrorNotFound.data'

const ErrorAccessDenied = (): ReactElement => {
  // Data
  const { translation } = truckNorrisTranslations()
  useEffect(() => {
    analyticsPageView('polestar/access_denied')
  }, [])

  return (
    <Container maxWidth="sm">
      <Stack data-test="notfound-container" spacing={2} justifyContent="center" alignItems="center" mt={8}>
        <TruckNorrisAccessDenied />
        <KNTypography variant="displaySM_SB">{getTruckNorrisQuote('access_denied')}</KNTypography>
        <KNTypography variant="displayXS" color="dark.main">
          {translation.access_denied}
        </KNTypography>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <KNButton variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
            {translation.back}
          </KNButton>
        </Link>
      </Stack>
    </Container>
  )
}

export default memo(ErrorAccessDenied)
