import { parseISO, isEqual, isBefore } from 'date-fns'

import { fetchGet, fetchPost, weblinkHeaders } from 'global/helpers/fetchWrapper'
import {
  LegData,
  StopData,
  AttachmentData,
  VehicleTemperatureMetricValue,
  VehiclePositionMetricValue,
  Geofence,
  StopLegPair,
  TripLogData,
} from './TripDetails.types'

export interface UploadFileResponse {
  id: string
  fileName: string
  checksum: string
  size: number
  timestamp: number
  isDocument: boolean
}

export const getTripLegs = async (tripCid: string, weblinkToken: string | null = null): Promise<LegData[]> => {
  const legs = await fetchGet(`trips/${tripCid}/shipmentlegs`, weblinkHeaders(weblinkToken))
  return legs.map(processShipmentLeg).sort(sortShipmentLegs)
}

export const getBulkTripLegs = async (tripCids: string[], weblinkToken: string | null = null): Promise<LegData[]> => {
  const results: LegData[] = []
  while (tripCids.length > 0) {
    const chunkedTripCids = tripCids.splice(0, 10)
    const chunkedRequests = chunkedTripCids.map((tripCid) => getTripLegs(tripCid, weblinkToken))
    const chunkedResponses = await Promise.all(chunkedRequests)
    chunkedResponses.map((legs: LegData[], index) => {
      legs.map((leg: LegData) => {
        results.push({
          ...leg,
          tripCid: chunkedTripCids[index],
        })
      })
    })
  }
  return results
}

export const getTripLogs = async (tripCid: string, weblinkToken: string | null = null): Promise<TripLogData[]> => {
  const events = await fetchGet(`trips/${tripCid}/logs`, weblinkHeaders(weblinkToken))
  return events.sort(sortTripLogs)
}

export const getFile = async (fileId: string, weblinkToken: string | null = null): Promise<Blob> => {
  try {
    return new Blob([await fetchGet(`files/${fileId}`, weblinkHeaders(weblinkToken))])
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTripVehicleTemperatures = async (
  tripCid: string,
  weblinkToken: string | null = null
): Promise<VehicleTemperatureMetricValue[]> => {
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has('argus')) {
    return await fetchGet(`trips/${tripCid}/argus_temperatures`, weblinkHeaders(weblinkToken))
  }
  return await fetchGet(`trips/${tripCid}/temperatures`, weblinkHeaders(weblinkToken))
}

export const getTripVehiclePositions = async (
  tripCid: string,
  weblinkToken: string | null = null
): Promise<VehiclePositionMetricValue[]> => {
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has('argus')) {
    return await fetchGet(`trips/${tripCid}/argus_positions`, weblinkHeaders(weblinkToken))
  }
  return await fetchGet(`trips/${tripCid}/positions`, weblinkHeaders(weblinkToken))
}

export const getTripGeofences = async (
  tripCid: string,
  weblinkToken: string | null = null
): Promise<Geofence[]> =>
  await fetchGet(`trips/${tripCid}/geofences`, weblinkHeaders(weblinkToken))

export const processShipmentLeg = (leg: LegData): LegData => {
  const defaultAttributes = {
    cargoItemDetails: [],
    dangerousGoodsDetails: [],
    customerReferences: [],
    pupReferences: [],
    delReferences: [],
  }
  leg.attributes = {
    ...defaultAttributes,
    ...leg.attributes,
  }
  leg.wayPoints = leg.wayPoints.map(processStatuses).map(processAvailableStatuses).map(processDocuments).sort(sortStops)
  return leg
}

export const sortShipmentLegs = (a, b) => {
  const aPickup = a.wayPoints.find((stop) => stop.type === 'PUP')
  const bPickup = b.wayPoints.find((stop) => stop.type === 'PUP')
  if (!aPickup || !bPickup) {
    return 0
  }
  // sort by sequence
  const sequence = aPickup.sequence - bPickup.sequence
  if (sequence !== 0) {
    return sequence
  }
  // ...then by early pickup/delivery date
  const firstDate = parseISO(aPickup.earlyDateTime)
  const secondDate = parseISO(bPickup.earlyDateTime)
  const date = isEqual(firstDate, secondDate) ? 0 : isBefore(firstDate, secondDate) ? -1 : 1
  if (date !== 0) {
    return date
  }
  // ...and lastly by shipment number
  return a.shipmentNumber === b.shipmentNumber ? 0 : a.shipmentNumber < b.shipmentNumber ? -1 : 1
}

const processStatuses = (stop: StopData): StopData => {
  // NOTE: "statuses" will not exist when there were no updates
  // NOTE: not all status codes are unique, some depend on additional reason code
  stop.statuses = stop.statuses
    ? stop.statuses
        .map((status) => {
          status.id = status.reasonCode ? `${status.statusCode}_${status.reasonCode}` : status.statusCode
          return status
        })
        .filter((status) => {
          // 3060 - POD uploaded (MACADAM only)
          return !['3060'].includes(status.id)
        })
        .sort(sortStatuses)
    : []
  return stop
}

const processAvailableStatuses = (stop: StopData): StopData => {
  // NOTE: filter out gate-in, gate-out and POD uploaded statuses
  // NOTE: not all status codes are unique, some depend on additional reason code
  stop.availableStatuses =
    stop.availableStatuses
      ?.filter((status) => {
        return !['0490', '0491', '2990', '2991', '1567', '1568', '3060'].includes(status.code)
      })
      .map((status) => {
        status.id = status.reasonCode ? `${status.code}_${status.reasonCode}` : status.code
        return status
      })
      .sort(sortAvailableStatuses) ?? []
  return stop
}

const processDocuments = (stop: StopData): StopData => {
  if (stop.documents) {
    stop.documents.sort(sortDocuments)
  }
  return stop
}

export const sortStops = (a, b): number => {
  // sort stops by types first in specified order
  const typeOrder = ['PUP', 'DEL', 'CUS']
  const aTypeSequence = typeOrder.indexOf(a.type)
  const bTypeSequence = typeOrder.indexOf(b.type)
  return aTypeSequence === bTypeSequence ? 0 : aTypeSequence < bTypeSequence ? -1 : 1
}

export const sortStatuses = (a, b): number => {
  const firstDate = parseISO(a.createdAt)
  const secondDate = parseISO(b.createdAt)
  return isEqual(firstDate, secondDate) ? 0 : isBefore(firstDate, secondDate) ? 1 : -1
}

// sort available statuses in specified order by status code
export const sortAvailableStatuses = (a, b): number => {
  // NOTE: keep up-to-date with available statuses
  const statusOrder = [
    '0500', // picked up
    '0502_DD01', // picked up short
    '0502_DD02', // picked up damaged
    '0501_CI01', // refused
    '0501_CI09', // not picked up
    '3000', // delivered
    '3002_DD01', // delivered short
    '3002_DD02', // delivered damaged
    '3001_DI03', // refused
    '3001_DI28', // not delivered
    '1500', // customs entry started
    '1600', // customs clearance completed
  ]
  const aSequence = statusOrder.indexOf(a.id)
  const bSequence = statusOrder.indexOf(b.id)
  return aSequence === bSequence ? 0 : aSequence < bSequence ? -1 : 1
}

// make POP and POD always first and rest sorted by date
export const sortDocuments = (a, b): number => {
  const fileType = a.fileType === b.fileType ? 0 : ['POP', 'POD'].includes(a.fileType) ? -1 : 1
  if (fileType !== 0) {
    return fileType
  }
  const firstDate = parseISO(a.createdAt)
  const secondDate = parseISO(b.createdAt)
  return isEqual(firstDate, secondDate) ? 0 : isBefore(firstDate, secondDate) ? -1 : 1
}

export const sortTripLogs = (a, b): number => {
  const firstDate = parseISO(a.createdAt)
  const secondDate = parseISO(b.createdAt)
  return isEqual(firstDate, secondDate) ? 0 : isBefore(firstDate, secondDate) ? 1 : -1
}
