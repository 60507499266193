// @mui material components
import { alpha } from '@mui/material'
import Fade from '@mui/material/Fade'

import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import borders from 'assets/theme/base/borders'

import pxToRem from 'assets/theme/functions/pxToRem'

const { size, fontWeightRegular } = typography
const { borderRadius } = borders

// types
type Types = any

const tooltip: Types = {
  defaultProps: {
    arrow: false,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: alpha(colors.primary.dark, 0.85),
      color: colors.white.main,
      fontSize: size.sm,
      textAlign: 'center',
      borderRadius: borderRadius.md,
      padding: `${pxToRem(4)} ${pxToRem(8)}`,
      backdropFilter: 'blur(3px)',
    },
    arrow: {
      color: alpha(colors.primary.dark, 0.85),
    },
  },
}

export default tooltip
