import { ReactNode, Fragment } from 'react'
import { ErrorOption, GlobalError, ValidateResult } from 'react-hook-form'

// KN imports
import { getExtendedErrorMessages } from 'global/helpers/errorHandler'

export const processServerErrorMessages = (error: unknown): Record<string, GlobalError> => {
  return getExtendedErrorMessages(error).reduce((errors: Record<string, GlobalError>, errorMessage, index) => {
    errors[`server_${index + 1}`] = { message: errorMessage.message }
    return errors
  }, {})
}

export const getGlobalErrorMessages = (errors?: Record<string, GlobalError>): ReactNode => {
  if (errors) {
    const messages: string[] = []
    for (const [key, value] of Object.entries(errors)) {
      if (key === 'ref' || !value.message) {
        continue
      }
      messages.push(value.message)
    }
    return messages.map((message, index) => (
      <Fragment key={index}>
        {index > 0 && <br />}
        {message}
      </Fragment>
    ))
  }
  return undefined
}

export const getFieldErrorMessages = (error?: ErrorOption): ReactNode => {
  if (error?.types) {
    const messages: ValidateResult[] = []
    for (const [k, v] of Object.entries(error.types)) {
      messages.push(v)
    }
    return messages.map((message, index) => (
      <Fragment key={index}>
        {index > 0 && <br />}
        {message}
      </Fragment>
    ))
  } else if (error?.message) {
    return error.message
  }
  return undefined
}

export const cleanupRequestValues = (values: Record<string, any> | any[]): Record<string, any> | any[] => {
  if (Array.isArray(values)) {
    // If input is an array, map over the elements and apply cleanup
    return values.map((value) =>
      (typeof value === 'string' ? value.trim() : value) || typeof value === 'boolean' ? value : null
    )
  } else {
    // If input is an object, iterate over key-value pairs and apply cleanup
    const processedValues: Record<string, any> = {}
    for (const [key, value] of Object.entries(values)) {
      processedValues[key] =
        (typeof value === 'string' ? value.trim() : value) || typeof value === 'boolean' ? value : null
    }
    return processedValues
  }
}

export const processDefaultValues = (
  values: Record<string, any>,
  overrides: Record<string, any> = {}
): Record<string, any> => {
  const processedValues = {}
  for (const [key, value] of Object.entries(values)) {
    processedValues[key] = value ?? (Object.prototype.hasOwnProperty.call(overrides, key) ? overrides[key] : '')
  }
  return processedValues
}
