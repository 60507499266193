import { useEffect, useState, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// KN imports
import { UserListContext } from 'context/users/UserListContext'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'
import KNFormAutocomplete from 'components/KN_Molecules/KNForm/KNFormAutocomplete'
import CompanyTypeLabel from 'screens/CompanyManager/CompanyTypeLabel'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { grantRole } from './UserManager.service'
import { sleep } from 'global/helpers/sleep'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'

// Types
import { UserData } from './UserManager.types'
import { Role } from 'context/authentication/Role.types'
import { KNOptionProps } from 'components/KN_Molecules/KNForm/types'

interface GrantRoleDialogPayload {
  user: UserData
}

interface GrantRoleDialogProps {
  payload: GrantRoleDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface GrantRoleFormValues {
  companyCid: string
  role: Role
}

const GrantRoleDialog = ({ payload, open, onAction, onClose }: GrantRoleDialogProps): ReactElement => {
  const { t } = useTranslation()
  const [userListState] = useContext(UserListContext)
  const [options, setOptions] = useState<KNOptionProps[]>([])
  const { handleSubmit, watch, reset, control, formState, setValue, setError } = useForm<GrantRoleFormValues>()

  useEffect(() => {
    if (open) {
      setOptions(
        userListState.companies
          .filter((company) => !payload.user.companies?.find((userCompany) => company.cid === userCompany.cid))
          .map((company) => {
            return {
              value: company.cid,
              label: company.displayName,
              payload: {
                type: company.type,
              },
            }
          })
      )
      reset(
        processDefaultValues(
          {
            companyCid: null,
            role: null,
          },
          {
            companyCid: null,
          }
        )
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<GrantRoleFormValues> = async (data: GrantRoleFormValues) => {
    try {
      await grantRole(payload.user.cid, data)
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      analyticsEvent('polestar_user_role_granted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={`${payload.user.displayName} — ${t('screens.shared.user_manager.grant_role.grant_role')}`}
        actions={
          <>
            <KNLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('screens.shared.user_manager.grant_role.grant')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Stack spacing={2}>
          <KNFormAutocomplete
            name="companyCid"
            label={t('screens.shared.user_manager.grant_role.company')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
            renderOption={(props, option): ReactElement => (
              <Box component="li" {...props} key={option.value}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <CompanyTypeLabel type={option.payload.type} compact />
                  <Box>{option.label}</Box>
                </Stack>
              </Box>
            )}
            options={options}
          />
          <KNFormRadioGroup
            name="role"
            label={t('screens.shared.user_manager.grant_role.role')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
            options={Object.keys(Role).map((role) => ({
              value: role,
              label: t(`screens.shared.user_manager.roles.${role}`),
            }))}
          />
        </Stack>
      </KNDialog>
    </KNForm>
  )
}

export default GrantRoleDialog
