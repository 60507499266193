import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'

// types
type Types = any

const formLabel: Types = {
  styleOverrides: {
    root: {
      color: colors.primary.light,
      fontSize: typography.textMD_SB.fontSize,
      fontWeight: typography.textMD_SB.fontWeight,
      marginBottom: '0.25rem',
    },
  },
}

export default formLabel
