import colors from 'assets/theme/base/colors'
import boxShadows from 'assets/theme/base/boxShadows'
import pxToRem from 'assets/theme/functions/pxToRem'
import linearGradient from 'assets/theme/functions/linearGradient'

const { white, gradients, transparent, text } = colors
const { md } = boxShadows

// types
type Types = any

const switchButton: Types = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    switchBase: {
      color: gradients.dark.main,

      '&:hover': {
        backgroundColor: transparent.main,
      },

      '&.Mui-checked': {
        color: gradients.dark.main,

        '&:hover': {
          backgroundColor: transparent.main,
        },

        '& .MuiSwitch-thumb': {},

        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: '0.3 !important',
      },

      '&.Mui-focusVisible .MuiSwitch-thumb': {
        backgroundImage: linearGradient(
          gradients.info.main,
          gradients.info.state
        ),
      },
    },

    thumb: {
      backgroundColor: white.main,
      boxShadow: md,
    },

    track: {
      width: pxToRem(32),
      height: pxToRem(15),
      backgroundColor: text.disabled,
      opacity: 1,
    },

    checked: {},
  },
}

export default switchButton
