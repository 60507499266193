import { useContext, useEffect, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

// @mui material imports //
import Box from '@mui/material/Box'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'

// Functional //
import { getTimeZones, saveUserPreferences } from 'screens/UserManager/UserManager.service'
import { analyticsEvent } from 'global/helpers/analytics'

// Context //
import { UserContext } from 'context/authentication/UserContext'

// Types //
import { TimeZone } from 'screens/UserManager/UserManager.types'
import { KNAdvancedDropdownOptionsProps } from 'components/KN_Molecules/KNAdvancedDropdown/types'

const Timezone = ({ timeZoneId }): ReactElement | null => {
  const [timeZones, setTimeZones] = useState<TimeZone[]>([])
  const [localTimeValueZone, setLocalTimezoneValue] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [localTimeZoneOptions, setLocalTimezoneOptions] = useState<KNAdvancedDropdownOptionsProps[]>([])
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  useEffect(() => {
    const getTimeZonesData = async (): Promise<void> => {
      setTimeZones(await getTimeZones())
    }
    void getTimeZonesData()
  }, [])

  useEffect(() => {
    if (timeZoneId) setLocalTimezoneValue(timeZoneId)
  }, [timeZoneId])

  useEffect(() => {
    const timezoneOptions = timeZones.map((timeZone) => {
      return {
        label: timeZone.displayName,
        value: timeZone.id,
      }
    })
    if (timeZones) setLocalTimezoneOptions(timezoneOptions)
  }, [timeZones])

  if (!user) {
    return null
  }
  return (
    <Box>
      <KNTypography variant="textMD_SB" color="primary.light">
        {t('screens.shared.profile.timezone')}
      </KNTypography>
      <KNAdvancedDropdown
        options={localTimeZoneOptions}
        predefinedValue={localTimeZoneOptions.find(
          (timezone) => timezone.value === Intl.DateTimeFormat().resolvedOptions().timeZone
        )}
        value={localTimeValueZone}
        onChange={async (event): Promise<void> => {
          const newTimezone = event.target.value as string
          setLocalTimezoneValue(newTimezone)
          analyticsEvent('shipmentinsight_timezone_change')
          await saveUserPreferences({ timeZoneId: newTimezone })
          localStorage.setItem('userTimezone', newTimezone)
        }}
      />
    </Box>
  )
}

export default Timezone
