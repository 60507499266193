import { Role } from 'context/authentication/Role.types'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { CompanyModule, CompanyType, VisibilityLevel } from 'context/authentication/Company.types'

export const isCompanyType = (type: CompanyType): boolean => getSelectedCompany()?.type === type

export const hasRole = (role: Role): boolean => {
  const currentRole = getSelectedCompany()?.role
  if (!currentRole) {
    return false
  }
  switch (role) {
    case Role.Admin:
      return currentRole === Role.Admin
    case Role.Editor:
      return currentRole === Role.Editor || currentRole === Role.Admin
    case Role.Viewer:
      return currentRole === Role.Viewer || currentRole === Role.Editor || currentRole === Role.Admin
    case Role.Pairer:
      return currentRole === Role.Pairer
    case Role.Guest:
      return currentRole === Role.Guest
    default:
      return false
  }
}

export const hasVisibilityLevel = (visibilityLevel: VisibilityLevel): boolean =>
  getSelectedCompany()?.properties?.visibilityLevel === visibilityLevel

export const hasAccess = (module: CompanyModule): boolean => getSelectedCompany()?.modules[module] === true
