import { ReactElement } from 'react'

// @mui imports
import Menu from '@mui/material/Menu'

import { KNMenuProps } from './types'

const KNMenu = ({ children, open, onClose, anchorEl, rightAligned, bottomAligned, sx }: KNMenuProps): ReactElement => {
  const handleClose = (): void => {
    onClose()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: rightAligned ? 'right' : 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: rightAligned ? 'right' : 'left', vertical: bottomAligned ? 'bottom' : 'top' }}
    >
      {children}
    </Menu>
  )
}

export default KNMenu
