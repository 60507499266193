import {
  createContext,
  ReactNode,
  ReactElement,
  useContext,
  useReducer,
} from 'react'
import NavigationContextProps from './NavigationContext.types'

interface Action {
  type: 'setNavigationProps'
  payload: NavigationContextProps
}
type Dispatch = (action: Action) => void
interface State {
  navigationContext: NavigationContextProps
}
interface NavigationContextProviderProps {
  children: ReactNode
}
interface NavigationStateContextType {
  navigationState: State
  dispatch: Dispatch
}

const NavigationStateContext = createContext<
  NavigationStateContextType | undefined
>(undefined)

function navigationReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setNavigationProps': {
      return { navigationContext: action.payload }
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function NavigationContextProvider({
  children,
}: NavigationContextProviderProps): ReactElement {
  const [navigationState, dispatch] = useReducer(navigationReducer, {
    navigationContext: {
      breadcrumbHome: { name: 'home', link: '/' },
      breadcrumbActive: '',
      activeLink: '',
      scrollPosition: 0,
    },
  })
  const value = { navigationState, dispatch }

  return (
    <NavigationStateContext.Provider value={value}>
      {children}
    </NavigationStateContext.Provider>
  )
}

function useNavigationContext(): NavigationStateContextType {
  const context = useContext(NavigationStateContext)
  if (context === undefined) {
    throw new Error('error')
  }
  return context
}

export { NavigationContextProvider, useNavigationContext }
