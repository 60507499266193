import { useState, ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'

// KN imports
import LegCard from './LegCard'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { LegData, StopData } from './TripDetails.types'

export interface ShipmentsViewProps {
  trip: TripData
  legs: LegData[]
  weblinkToken?: string
  onChange: (updatedStops: StopData[]) => void
}

const ShipmentsView = ({ trip, legs, weblinkToken, onChange }: ShipmentsViewProps): ReactElement => (
  <Stack data-test="trip-card" spacing={2} sx={{ flexGrow: 1 }}>
    {legs.map((leg) => (
      <LegCard key={leg.shipmentNumber} trip={trip} leg={leg} weblinkToken={weblinkToken} onChange={onChange} />
    ))}
  </Stack>
)

export default ShipmentsView
