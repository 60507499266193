export const humanReadableBytes = (bytes: number, decimals = 2): string => {
  // TODO: translate "Bytes"
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  if (bytes === 0) {
    return `0 ${sizes[0]}`
  }
  const k = 1024
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // no decimals for bytes and kilobytes
  const formatted = parseFloat((bytes / Math.pow(k, i)).toFixed(i >= 2 ? decimals : 0))
  return `${formatted} ${sizes[i]}`
}
