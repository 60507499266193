import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningIcon from '@mui/icons-material/Warning'
import Collapse from '@mui/material/Collapse'
import { useTheme } from '@mui/material/styles'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import { processServerErrorMessages, getGlobalErrorMessages, processDefaultValues } from 'global/helpers/form'
import LocationLink from 'screens/TripDashboard/LocationLink'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import StopHeader from 'screens/TripDetails/StopHeader'
import StatusUpdateFields from './StatusUpdateFields'
import DocumentsUploadFields from './DocumentsUploadFields'
import DocumentLink from './DocumentLink'
import DocumentsUploadDialog from './DocumentsUploadDialog'
import { updateStopStatusWithAttachments } from './StatusManager.service'
import { getUpdatedStopForUpdateStopStatusWithAttachments } from './StatusManager.helpers'
import { LegData, StopData } from 'screens/TripDetails/TripDetails.types'
import { getDefaultStatus, rewriteTimezone, getTimezoneBase } from './StatusUpdateDialog'
import { StatusUpdateWithAttachmentsFormValues } from './StatusUpdateWithAttachmentsDialog'

interface QuickStatusUpdateStopProps {
  trip: TripData
  leg: LegData
  stop: StopData
  expanded: boolean
  onChange: (panel: string) => void
  onAction: (updatedStops: StopData[]) => void
}

const QuickStatusUpdateStop: React.FC<QuickStatusUpdateStopProps> = ({
  trip,
  leg,
  stop,
  expanded,
  onChange,
  onAction,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()
  const [pendingFiles, setPendingFiles] = useState(false)
  const form = useForm<StatusUpdateWithAttachmentsFormValues>({
    defaultValues: processDefaultValues({
      wayPointType: stop.type,
      statusId: getDefaultStatus(stop.type),
      createdAtDate: stop.earlyDateTime ? new Date(stop.earlyDateTime) : new Date(),
      createdAtTime: null,
      shipmentNumber: leg.shipmentNumber,
      paperless: false,
    }),
    criteriaMode: 'all',
  })
  const { handleSubmit, reset, formState, setError } = form
  const [documentsUploadDialogOpen, setDocumentsUploadDialogOpen] = useState(false)

  const handleDocumentsUploadClick = useCallback((): void => setDocumentsUploadDialogOpen(true), [])
  const handleDocumentsUploadAction = useCallback((updatedStops: StopData[]): void => {
    setDocumentsUploadDialogOpen(false)
    onAction(updatedStops)
  }, [])
  const handleDocumentsUploadClose = useCallback((): void => {
    setDocumentsUploadDialogOpen(false)
  }, [])

  const onSubmit: SubmitHandler<StatusUpdateWithAttachmentsFormValues> = async (
    data: StatusUpdateWithAttachmentsFormValues
  ) => {
    // NOTE: rewrites input timezone to stop's timezone
    if (data.createdAtDate && data.createdAtTime) {
      data.createdAt = rewriteTimezone(data.createdAtDate, data.createdAtTime, getTimezoneBase(stop, leg))
    }
    try {
      await updateStopStatusWithAttachments(trip.entityId, stop.wayPointCid, data)
      onAction([getUpdatedStopForUpdateStopStatusWithAttachments(stop, data)])
      analyticsEvent('polestar_cs_status_with_attachments_updated', [
        getRouteName(location.pathname),
        data.paperless ? 'paperless' : 'regular',
      ])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <>
      <FormProvider {...form}>
        <KNForm onSubmit={onSubmit}>
          <Stack
            data-test="quick-update-dialog-sections"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={(): void => {
              onChange(stop.wayPointCid)
            }}
          >
            <StopHeader stop={stop} />
            <ExpandMoreIcon
              data-test="expand-icon"
              sx={{
                color: 'text.main',
                transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
                marginLeft: 'auto',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </Stack>
          {formState.errors?.root && <Alert severity="error">{getGlobalErrorMessages(formState.errors.root)}</Alert>}
          <Collapse in={expanded}>
            <Stack spacing={2} mt={2}>
              <Box data-test="location-container">
                <KNTypography color="text.main">{stop.address.name.join(' ')}</KNTypography>
                <LocationLink
                  countryCode={stop.address.countryCode}
                  city={stop.address.city.join(', ')}
                  zipCode={stop.address.zipCode}
                  street={stop.address.street.join(', ')}
                  marker={stop.geoPoint ? {
                    id: stop.wayPointCid,
                    latitude: stop.geoPoint.latitude,
                    longitude: stop.geoPoint.longitude,
                    type: stop.type,
                  } : undefined}
                  lineBreaks="all"
                />
              </Box>
              {stop.documents && (
                <Box>
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('screens.cs.trip_details.card.attachments')}
                  </KNTypography>
                  <KNTypography color="text.main">
                    {stop.documents.map((file) => (
                      <DocumentLink key={file.id} file={file} />
                    ))}
                  </KNTypography>
                </Box>
              )}
              {stop.availableStatuses.length > 0 && (
                <>
                  {stop.type === 'CUS' && (
                    <KNCaption color="warning" icon={<WarningIcon />}>
                      {t('screens.cs.trip_details.update_status.customs_local_time')}
                    </KNCaption>
                  )}
                  <StatusUpdateFields
                    data-test="status"
                    payload={{
                      tripId: trip.entityId,
                      leg,
                      stop,
                    }}
                  />
                  <DocumentsUploadFields
                    payload={{
                      leg,
                      stop,
                    }}
                    onPending={(pending: boolean) => setPendingFiles(pending)}
                  />
                </>
              )}
              <Stack
                spacing={1}
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent="flex-end"
                alignItems={{ xs: 'stretch', sm: 'center' }}
              >
                {stop.availableStatuses.length > 0 ? (
                  <KNLoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={formState.isSubmitting || pendingFiles}
                    onClick={handleSubmit(onSubmit)}
                    data-test="loading"
                  >
                    {t('screens.cs.trip_details.card.actions.update_status_and_documents')}
                  </KNLoadingButton>
                ) : (
                  <KNButton variant="text" color="primary" onClick={handleDocumentsUploadClick} data-test="upload">
                    {t('screens.cs.trip_details.card.actions.upload_documents')}
                  </KNButton>
                )}
              </Stack>
            </Stack>
          </Collapse>
        </KNForm>
      </FormProvider>

      <DocumentsUploadDialog
        data-test="documents-upload-dialog"
        payload={{
          trip,
          leg,
          stop,
        }}
        open={documentsUploadDialogOpen}
        onAction={handleDocumentsUploadAction}
        onClose={handleDocumentsUploadClose}
      />
    </>
  )
}

export default QuickStatusUpdateStop
