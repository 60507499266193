import { CSSInterpolation } from '@mui/system'

const toggleButton = {
  styleOverrides: {
    root: ({ ownerState, theme }): CSSInterpolation => ({
      textTransform: 'none' as const,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.lineHeight.lg,
      padding: '6px 16px',
      justifyContent: 'start',
    }),
  },
}

export default toggleButton
