import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'

export interface VehicleFiltersValues {
  keywords?: string[]
  vehicleType?: string[]
}

export interface VehicleListState {
  backgroundLoading: boolean
  filters: VehicleFiltersValues
  filtersDirty: boolean
}

export type VehicleListAction =
  | { type: 'setBackgroundLoading'; payload: boolean }
  | { type: 'setFilters'; payload: VehicleFiltersValues }
  | { type: 'resetFilters' }

const initialFilters: VehicleFiltersValues = {
  keywords: [],
  vehicleType: ['TRUCK', 'TRAILER'],
}

const initialState: VehicleListState = {
  backgroundLoading: false,
  filters: initialFilters,
  filtersDirty: false,
}

const reducer = (state: VehicleListState, action: VehicleListAction): VehicleListState => {
  switch (action.type) {
    case 'setBackgroundLoading':
      return {
        ...state,
        backgroundLoading: action.payload,
      }
    case 'setFilters':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        filtersDirty: JSON.stringify({ ...state.filters, ...action.payload }) !== JSON.stringify(initialFilters),
      }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as VehicleListContext, StoreProvider as VehicleListProvider }
