import i18n from 'i18n'
import { zonedDate } from 'global/helpers/dateFormatters'

interface ErrorBaseParams {
  type: string
  code: string
  status: number
  details: string
  // NOTE: severity has to be of type string instead of specific levels
  // to match index signature of extra params below when combined into ErrorDetails
  // https://www.typescriptlang.org/docs/handbook/2/objects.html#index-signatures
  severity: string
}

type ErrorExtraParams = Record<string, string | number>

interface ErrorDetails extends ErrorBaseParams, ErrorExtraParams {}

interface LegacyError {
  message: string
}

export interface ErrorMessage {
  message: string
  [key: string]: string | number
}

const isErrorDetailsArray = (value: unknown): value is ErrorDetails[] =>
  Array.isArray(value) &&
  (value as ErrorDetails[])[0].type !== undefined &&
  (value as ErrorDetails[])[0].code !== undefined

export const isErrorMessageArray = (value: unknown): value is ErrorMessage[] =>
  Array.isArray(value) && (value as ErrorMessage[])[0].message !== undefined

export const handleErrors = (errors: ErrorDetails[] | LegacyError): ErrorMessage[] => {
  const processedErrors: ErrorMessage[] = []
  if (isErrorDetailsArray(errors)) {
    // array, so it must be ErrorDetails[]
    for (const error of errors) {
      const { type, code, status, details, severity, ...params } = error
      processedErrors.push({
        message: i18n.exists(`errors.${code}`) ? i18n.t(`errors.${code}`, processParams(code, params)) : details,
        ...params,
      })
    }
  } else {
    // some backwards compatibility
    processedErrors.push({
      message: errors.message,
    })
  }
  return processedErrors
}

const processParams = (code: string, params: ErrorExtraParams): ErrorExtraParams => {
  switch (code) {
    // carrier solution
    case 'WaypointStatusInvalidWaypointType':
      return {
        ...params,
        actualWaypointType: i18n.t(`waypoints.types.${params.actualWaypointType}`),
        commandWaypointType: i18n.t(`waypoints.types.${params.commandWaypointType}`),
      }
    case 'WaypointStatusAlreadySet':
      return {
        ...params,
        waypointStatus: i18n.t(`waypoints.statuses.${params.waypointStatus}`),
      }
    case 'WaypointStatusPrevStatusMissing':
      return {
        ...params,
        waypointStatus: i18n.t(`waypoints.statuses.${params.waypointStatus}`),
      }
    case 'WaypointStatusFinalStatusAlreadySet':
      return {
        ...params,
        finalStatus: i18n.t(`waypoints.statuses.${params.finalStatus}`),
        receivedAt: zonedDate(params.receivedAt as string, 'full') ?? '',
      }
    case 'WaypointStatusInvalidStatus':
      return {
        ...params,
        waypointStatus: i18n.t(`waypoints.statuses.${params.waypointStatus}`),
        waypointType: i18n.t(`waypoints.types.${params.waypointType}`),
      }
    case 'InvalidFileType':
      return {
        ...params,
        fileType: i18n.t(`attachments.types.${params.fileType}`),
        waypointType: i18n.t(`waypoints.types.${params.waypointType}`),
      }
    case 'FileTypeAlreadyAttached':
      return {
        ...params,
        fileType: i18n.t(`attachments.types.${params.fileType}`),
      }
  }
  return params
}

export const getErrorMessage = (errors: unknown): string => {
  if (isErrorMessageArray(errors)) {
    return errors
      .filter((error) => error.message)
      .map((error) => error.message)
      .join(' ')
  }
  if (errors instanceof Error) {
    return errors.message
  }
  return String(errors) || i18n.t('errors.GenericError')
}

export const getExtendedErrorMessages = (errors: unknown): ErrorMessage[] => {
  if (isErrorMessageArray(errors)) {
    return errors
  }
  if (errors instanceof Error) {
    return [
      {
        message: errors.message,
      },
    ]
  }
  return [
    {
      message: String(errors) || i18n.t('errors.GenericError'),
    },
  ]
}
