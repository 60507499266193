import { matchPath } from 'react-router-dom'

interface MenuRoute {
  path: string
  exact?: boolean
  strict?: boolean
  name: string
  menu: string
}

// NOTE: keep up-to-date with available routes
const routes: MenuRoute[] = [
  {
    path: '/login',
    exact: true,
    name: 'login',
    menu: 'login',
  },
  {
    path: '/profile',
    exact: true,
    name: 'profile',
    menu: 'profile',
  },
  {
    path: '/trips',
    exact: true,
    name: 'trips_list',
    menu: 'trips',
  },
  {
    path: '/trips/:tripCid',
    exact: true,
    name: 'trip_details',
    menu: 'trips',
  },
  {
    path: '/live-map',
    exact: true,
    name: 'live_map',
    menu: 'live_map',
  },
  {
    path: '/drivers',
    exact: true,
    name: 'drivers_list',
    menu: 'drivers',
  },
  {
    path: '/vehicles',
    exact: true,
    name: 'vehicles_list',
    menu: 'vehicles',
  },
  {
    path: '/wl/:jwt',
    exact: true,
    name: 'weblink',
    menu: 'weblink',
  },
  {
    path: '/users',
    exact: true,
    name: 'users_list',
    menu: 'users',
  },
  {
    path: '/companies',
    exact: true,
    name: 'companies_list',
    menu: 'companies',
  },
  {
    path: '/carriers',
    exact: true,
    name: 'carriers_list',
    menu: 'carriers',
  },
  {
    path: '/help&info',
    exact: true,
    name: 'release_notes',
    menu: 'releasenotes',
  },
  {
    path: '/playground',
    exact: true,
    name: 'playground',
    menu: 'playground',
  },
  {
    path: '/rejected-messages',
    exact: true,
    name: 'rejected-messages',
    menu: 'rejected-messages',
  },
  {
    path: '/access-denied',
    exact: true,
    name: 'access-denied',
    menu: 'access-denied',
  },
]

export const getActiveRoute = (location: string): MenuRoute | undefined =>
  routes.find(
    (route) =>
      matchPath(location, {
        path: route.path,
        exact: route.exact ?? false,
        strict: route.strict ?? false,
      }) !== null
  )

export const getRouteName = (location: string): string => {
  const activeRoute = getActiveRoute(location)
  return activeRoute?.name ?? 'unknown'
}

export const getActiveMenu = (location: string): string | null => {
  const activeRoute = getActiveRoute(location)
  return activeRoute?.menu ?? null
}
