import { Fragment, useCallback, ReactElement } from 'react'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'

// @mui imports
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import KNMap from 'components/KN_Molecules/KNMap/KNMap'
import { KNDetailsPopoverMapProps } from './types'

const KNDetailsPopoverMap = ({
  markers,
  geoPoints,
  groupedGeoPoints,
  zoom,
}: KNDetailsPopoverMapProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        width: isMobile ? '20rem' : '30rem',
        height: isMobile ? '20rem' : '30rem',
        marginTop: '-1px',
        backgroundColor: '#e9ecef',
        '&:first-child': {
          marginTop: 0,
        },
        '& > div > div': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <KNMap markers={markers} geoPoints={geoPoints} groupedGeoPoints={groupedGeoPoints} zoom={zoom} withHeading />
    </Box>
  )
}

export default KNDetailsPopoverMap
