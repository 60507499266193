import i18n from 'i18n'
import { DriveStep } from 'driver.js'

export const getGuideName = (route: string): string | null => {
  switch (route) {
    case 'visibility_dashboard':
      return 'welcomeGuide'
    case 'trips_list':
      return 'welcomeGuideTripList'
    case 'trip_details':
      return 'welcomeGuideTripDetails'
    default:
      return null
  }
}

export const getGuideSteps = (guideName: string): DriveStep[] => {
  switch (guideName) {
    case 'welcomeGuide':
      return [
        {
          popover: {
            title: i18n.t('screens.cv.visibility_dashboard.guide.welcome_title'),
            description: i18n.t('screens.cv.visibility_dashboard.guide.welcome_message'),
          },
        },
        {
          element: '#shipment-insight-container',
          popover: {
            title: i18n.t('screens.cv.visibility_dashboard.guide.list_title'),
            description: i18n.t('screens.cv.visibility_dashboard.guide.list_message'),
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#live-counters-container',
          popover: {
            title: i18n.t('screens.cv.visibility_dashboard.guide.counters_title'),
            description: i18n.t('screens.cv.visibility_dashboard.guide.counters_message'),
            side: 'bottom',
            align: 'start',
          },
        },
        {
          element: '#shipment-insight-filters',
          popover: {
            title: i18n.t('screens.cv.visibility_dashboard.guide.filters_title'),
            description: i18n.t('screens.cv.visibility_dashboard.guide.filters_message'),
            side: 'top',
            align: 'start',
          },
        },
      ]
    case 'welcomeGuideTripList':
      return [
        {
          element: '[data-guide="predefined-filters"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.predefined_filters.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.predefined_filters.description'),
            side: 'bottom',
            align: 'center',
          },
        },
        {
          element: '[data-guide="trip-filters"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.trip_filters.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.trip_filters.description'),
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '[data-guide="datatable"] tr[data-index="0"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.trip_row.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.trip_row.description'),
            side: 'top',
            align: 'center',
          },
          onHighlightStarted: (element, step, options) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
          },
          onDeselected: (element) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
          },
        },
        {
          element: '[data-guide="datatable"] tr[data-index="0"] [data-guide="trip-summary"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.trip_summary.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.trip_summary.description'),
            side: 'top',
            align: 'center',
          },
          onHighlightStarted: (element, step, options) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
          },
          onDeselected: (element) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
          },
        },
        {
          element: '[data-guide="datatable"] tr[data-index="0"] [data-guide="driver-vehicle-actions"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.driver_vehicle_actions.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.driver_vehicle_actions.description'),
            side: 'top',
            align: 'center',
          },
          onHighlightStarted: (element, step, options) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
          },
          onDeselected: (element) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
          },
        },
        {
          element: '[data-guide="datatable"] tr[data-index="0"] [data-guide="datatable-actions"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.trip_actions.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.trip_actions.description'),
            side: 'top',
            align: 'center',
          },
          onHighlightStarted: (element, step, options) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.add('forced-hover')
          },
          onDeselected: (element) => {
            document.querySelector('[data-guide="datatable"] tr[data-index="0"]')?.classList.remove('forced-hover')
          },
        },
        {
          element: '[data-guide="menu-profile"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.menu_profile.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.menu_profile.description'),
            side: 'right',
            align: 'center',
          },
        },
        {
          element: '[data-guide="feedback"]',
          popover: {
            title: i18n.t('screens.cs.trip_dashboard.guide.feedback.title'),
            description: i18n.t('screens.cs.trip_dashboard.guide.feedback.description'),
            side: 'left',
            align: 'center',
          },
        },
      ]
    case 'welcomeGuideTripDetails':
      return [
        {
          element: '[data-guide="leg-summary"]',
          popover: {
            title: i18n.t('screens.cs.trip_details.guide.leg_summary.title'),
            description: i18n.t('screens.cs.trip_details.guide.leg_summary.description'),
            side: 'bottom',
            align: 'center',
          },
        },
        {
          element: '[data-guide="PUP-card"] [data-guide="primary-actions"]',
          popover: {
            title: i18n.t('screens.cs.trip_details.guide.pup_primary_actions.title'),
            description: i18n.t('screens.cs.trip_details.guide.pup_primary_actions.description'),
            side: 'bottom',
            align: 'center',
          },
        },
        {
          element: '[data-guide="DEL-card"] [data-guide="primary-actions"]',
          popover: {
            title: i18n.t('screens.cs.trip_details.guide.del_primary_actions.title'),
            description: i18n.t('screens.cs.trip_details.guide.del_primary_actions.description'),
            side: 'bottom',
            align: 'center',
          },
        },
        {
          element: '[data-guide="trip-summary"]',
          popover: {
            title: i18n.t('screens.cs.trip_details.guide.trip_summary.title'),
            description: i18n.t('screens.cs.trip_details.guide.trip_summary.description'),
            side: 'bottom',
            align: 'center',
          },
        },
        {
          element: '[data-guide="trip-share"]',
          popover: {
            title: i18n.t('screens.cs.trip_details.guide.trip_share.title'),
            description: i18n.t('screens.cs.trip_details.guide.trip_share.description'),
            side: 'bottom',
            align: 'center',
          },
        },
      ]
    default:
      return []
  }
}
