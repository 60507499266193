import colors from 'assets/theme/base/colors'

const tableRow = {
  styleOverrides: {
    root: {
      '&.MuiTableRow-hover': {
        'td, th': {
          transition: 'border-color 100ms ease-out',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          'td, th': {
            borderBottomColor: colors.primary.main,
          },
        },
      },
    },
  },
}

export default tableRow
