import { FC, forwardRef } from 'react'

// Types
import KNTypographyProps from './types'

// Custom styles for KNTypography
import KNTypographyRoot from './KNTypographyRoot'

const KNTypography: FC<KNTypographyProps | any> = forwardRef(({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => {
  return (
    <KNTypographyRoot color={color} fontWeight={fontWeight} textTransform={textTransform} opacity={opacity} {...rest} ref={ref}>
      {children}
    </KNTypographyRoot>
  )
})

export default KNTypography
