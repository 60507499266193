import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { processServerErrorMessages } from 'global/helpers/form'
import { deleteTrip } from './TripDashboard.service'
import { getDataForDeleteTrip } from './TripDashboard.helpers'

// Types
import { TripData } from './TripDashboard.types'

interface DeleteTripDialogPayload {
  trip: TripData
}

interface DeleteTripDialogProps {
  payload: DeleteTripDialogPayload
  open: boolean
  onAction: (updatedTrip: TripData, action: string) => void
  onClose: () => void
}

const DeleteTripDialog = ({ payload, open, onAction, onClose }: DeleteTripDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, formState, setError } = useForm()
  const [deletionConfirmed, setDeletionConfirmed] = useState(false)

  useEffect(() => {
    setDeletionConfirmed(false)
  }, [payload])

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      await deleteTrip(payload.trip.entityId)
      onAction(getDataForDeleteTrip(payload.trip), 'delete')
      analyticsEvent('polestar_cs_trip_deleted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.trip.tripNumber} — ${t('screens.cs.trip_dashboard.card.actions.delete')}`}
      actions={
        <>
          <KNLoadingButton
            data-test="delete-button"
            type="submit"
            color="error"
            variant="contained"
            disabled={!deletionConfirmed}
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('screens.cs.trip_dashboard.delete_trip.delete')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <KNTypography color="grey.500">
        {t('screens.cs.trip_dashboard.delete_trip.confirmation')}
      </KNTypography>
      <FormControlLabel
        control={
          <Checkbox
            data-test="confirm-checkbox"
            value={deletionConfirmed}
            checked={deletionConfirmed}
            onChange={(): void => setDeletionConfirmed(!deletionConfirmed)}
          />
        }
        label={t('shared.manager.deletion_confirm_checkbox')}
      />
    </KNDialog>
  )
}

export default DeleteTripDialog
