import { useCallback, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports


// KN imports
import { TripListContext } from 'context/trips/TripListContext'
import KNButtonGroup from 'components/KN_Molecules/KNButtonGroup/KNButtonGroup'

export interface ViewSwitcherProps {
  stopsViewAvailable?: boolean
}

const ViewSwitcher = ({ stopsViewAvailable }: ViewSwitcherProps): ReactElement => {
  const { t } = useTranslation()
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const availableViews = stopsViewAvailable ? ['shipments', 'stops', 'sequence_map'] : ['shipments', 'locations_map']

  const handleViewChange = useCallback((value) => {
    tripListDispatch({ type: 'setDetailsView', payload: value })
  }, [])

  return (
    <KNButtonGroup
      value={tripListState.detailsView}
      onChange={handleViewChange}
      options={availableViews.map((view) => ({
        value: view,
        label: t(`screens.cs.trip_details.views.${view}`),
      }))}
    />
  )
}

export default ViewSwitcher
