import { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

// Types
import { MessageFiltersProps, MessageFiltersValues } from './MessageManager.types'

const initialFilters: MessageFiltersValues = {
  keywords: [],
}

const MessageFilters = ({ onChange }: MessageFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<MessageFiltersValues>(initialFilters)
  const [debouncedFilters] = useDebounce(filters, 100)
  const [filtersDirty, setFiltersDirty] = useState(false)

  useEffect(() => {
    onChange(debouncedFilters)
  }, [debouncedFilters])

  const updateFilters = useCallback((updatedFilters: MessageFiltersValues) => {
    setFilters({
      ...filters,
      ...updatedFilters,
    })
    setFiltersDirty(true)
  }, [filters])

  const handleResetClick = useCallback((): void => {
    setFilters(initialFilters)
    setFiltersDirty(false)
    analyticsEvent('polestar_reset_message_filters_button_clicked')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid data-test="search-messages-filter" item xs={12} sm={4}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={filters.keywords ?? []}
            onChange={(event, newValue): void => {
              updateFilters({
                keywords: [...newValue] as string[],
              })
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('screens.cs.message_manager.filters.search')} />
            )}
            size="small"
          />
        </Grid>
      </Grid>
      {filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="secondary"
          data-test="reset-message-filters-button"
          onClick={handleResetClick}
          sx={{
            padding: 0,
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default MessageFilters
