import { ReactElement } from 'react'
import theme from 'assets/theme'
import { getEstimatedSpeedColor } from 'screens/TripDetails/TripDetails.helpers'
import { GeoPoint } from 'screens/TripDetails/TripDetails.types'
import { MapMarker, MapPixel } from './types'
import {
  encodeIconToDataUrl,
  KNGenericMapIcon,
  KNGeofenceMapIcon,
  KNPickupMapIcon,
  KNDeliveryMapIcon,
  KNCustomsMapIcon,
  KNVehicleMapIcon,
  KNHeadingIcon,
  KNDwellMapIcon,
} from 'components/KN_Molecules/KNIcon/KNMapIcon'

const DEG_TO_RAD = Math.PI / 180
const RAD_TO_DEG = 180 / Math.PI

export const getDistance = (firstGeoPoint: GeoPoint, secondGeoPoint: GeoPoint): number => {
  const R = 6371.071 // radius of Earth in kilometers
  const firstLatitudeRadians = firstGeoPoint.latitude * DEG_TO_RAD
  const secondLatitudeRadians = secondGeoPoint.latitude * DEG_TO_RAD
  const latitudeDifference = secondLatitudeRadians - firstLatitudeRadians
  const longitudeDifference = (secondGeoPoint.longitude - firstGeoPoint.longitude) * DEG_TO_RAD
  return (
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(latitudeDifference / 2) * Math.sin(latitudeDifference / 2) +
          Math.cos(firstLatitudeRadians) *
            Math.cos(secondLatitudeRadians) *
            Math.sin(longitudeDifference / 2) *
            Math.sin(longitudeDifference / 2)
      )
    )
  )
}

export const getDistanceInPixels = (firstX: number, firstY: number, secondX: number, secondY: number): number =>
  Math.sqrt(Math.pow(secondX - firstX, 2) + Math.pow(secondY - firstY, 2))

export const getHeading = (firstGeoPoint: GeoPoint, secondGeoPoint: GeoPoint): number => {
  const firstLatitudeRadians = firstGeoPoint.latitude * DEG_TO_RAD
  const secondLatitudeRadians = secondGeoPoint.latitude * DEG_TO_RAD
  const longitudeDifference = (secondGeoPoint.longitude - firstGeoPoint.longitude) * DEG_TO_RAD
  const y = Math.sin(longitudeDifference) * Math.cos(secondLatitudeRadians)
  const x =
    Math.cos(firstLatitudeRadians) * Math.sin(secondLatitudeRadians) -
    Math.sin(firstLatitudeRadians) * Math.cos(secondLatitudeRadians) * Math.cos(longitudeDifference)
  return (Math.atan2(y, x) * RAD_TO_DEG + 360) % 360
}

export const getCoordsFromPixel = (
  map: google.maps.Map,
  screenX: number,
  screenY: number
): google.maps.LatLng | null => {
  const mapBounds = map.getBounds()
  const mapZoom = map.getZoom()
  const mapProjection = map.getProjection()
  if (!mapBounds || !mapZoom || !mapProjection) {
    return null
  }
  const mapDiv = map.getDiv()
  const projectionScale = Math.pow(2, mapZoom)
  const northEast = mapBounds.getNorthEast()
  const southWest = mapBounds.getSouthWest()
  const topRight = mapProjection.fromLatLngToPoint(northEast)
  const bottomLeft = mapProjection.fromLatLngToPoint(southWest)
  if (!topRight || !bottomLeft) {
    return null
  }
  const worldX = (screenX / mapDiv.clientWidth) * (bottomLeft.x - topRight.x) + topRight.x
  const worldY = (screenY / mapDiv.clientHeight) * (bottomLeft.y - topRight.y) + topRight.y
  return mapProjection.fromPointToLatLng(new google.maps.Point(worldX, worldY))
}

export const getPixelFromCoords = (
  map: google.maps.Map,
  latitude: number,
  longitude: number
): MapPixel | null => {
  const mapBounds = map.getBounds()
  const mapZoom = map.getZoom()
  const mapProjection = map.getProjection()
  const mapBoundingClientRect = map.getDiv().firstElementChild?.getBoundingClientRect()
  if (!mapBounds || !mapZoom || !mapProjection || !mapBoundingClientRect) {
    return null
  }
  const projectionScale = Math.pow(2, mapZoom)
  const northEast = mapBounds.getNorthEast()
  const southWest = mapBounds.getSouthWest()
  const topRight = mapProjection.fromLatLngToPoint(northEast)
  const bottomLeft = mapProjection.fromLatLngToPoint(southWest)
  if (!topRight || !bottomLeft) {
    return null
  }
  const point = mapProjection.fromLatLngToPoint({ lat: latitude, lng: longitude })
  if (!point) {
    return null
  }
  return {
    x: mapBoundingClientRect.x + (point.x - bottomLeft.x) * projectionScale,
    y: mapBoundingClientRect.y + (point.y - topRight.y) * projectionScale,
  }
}

export const getIconDefaultColor = (type?: string): string => {
  switch (type) {
    case 'PUP':
      return theme.palette.secondary.main
    case 'DEL':
      return theme.palette.primary.main
    case 'CUS':
      return theme.palette.secondary.main
    case 'VEHICLE':
      return theme.palette.success.main
    case 'HEADING':
      return theme.palette.primary.main
    case 'GEOFENCE':
      return theme.palette.primary.light
    case 'DWELL':
      return '#ffa8a8'
    default:
      return theme.palette.primary.main
  }
}

export const getBaseMarkerIcon = (marker: MapMarker): ReactElement => {
  const defaultColor = marker.color ?? getIconDefaultColor(marker.type)
  switch (marker.type) {
    case 'PUP':
      return <KNPickupMapIcon color={defaultColor} state={marker.state} />
    case 'DEL':
      return <KNDeliveryMapIcon color={defaultColor} state={marker.state} />
    case 'CUS':
      return <KNCustomsMapIcon color={defaultColor} state={marker.state} />
    case 'VEHICLE':
      return <KNVehicleMapIcon color={defaultColor} state={marker.state} />
    case 'HEADING':
      return <KNHeadingIcon color={defaultColor} heading={marker.heading} state={marker.state} />
    case 'GEOFENCE':
      return <KNGeofenceMapIcon color={defaultColor} state={marker.state} />
    case 'DWELL':
      return <KNDwellMapIcon color={defaultColor} state={marker.state} />
    default:
      return <KNGenericMapIcon color={defaultColor} label={marker.label} state={marker.state} />
  }
}

export const getMarkerIcon = (marker: MapMarker): google.maps.Icon => {
  const baseIcon = {
    anchor: new google.maps.Point(16, 28),
    size: { width: 32, height: 32 } as google.maps.Size,
    scaledSize: { width: 32, height: 32 } as google.maps.Size,
    url: encodeIconToDataUrl(getBaseMarkerIcon(marker)),
  }
  switch (marker.type) {
    case 'VEHICLE':
    case 'HEADING':
      return {
        ...baseIcon,
        anchor: new google.maps.Point(16, 16),
      }
    default:
      return {
        ...baseIcon,
      }
  }
}

export const getZIndex = (type?: string): number => {
  switch (type) {
    case 'PUP':
    case 'DEL':
      return 300
    case 'VEHICLE':
      return 400
    case 'GEOPOINT':
      return 200
    case 'HEADING':
      return 500
    case 'DWELL':
      return 25
    case 'GEOFENCE':
      return 50
    case 'POLYLINE':
      return 20
    case 'GENERIC':
    default:
      return 100
  }
}

export const getGeofenceOptions = (marker?: MapMarker) => {
  return {
    clickable: false,
    draggable: false,
    editable: false,
    strokeColor: marker?.color ?? getIconDefaultColor(marker?.type),
    strokeWeight: 2,
    strokeOpacity: 0.5,
    fillColor: marker?.color ?? getIconDefaultColor(marker?.type),
    fillOpacity: 0.1,
    zIndex: getZIndex('GEOFENCE'),
  }
}

export const getPolylineOptions = (label?: string) => {
  const circle = {
    path: 'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2',
    strokeOpacity: 0,
    fillOpacity: 1,
    scale: 0.2,
  }
  const baseOptions = {
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: true,
    strokeColor: theme.palette.secondary.main,
    strokeWeight: 3,
    strokeOpacity: 1,
    zIndex: getZIndex('POLYLINE'),
  }
  switch (label) {
    case 'route':
      return {
        ...baseOptions,
        strokeOpacity: 0,
        icons: [
          {
            icon: circle,
            offset: '0',
            repeat: '8px',
          },
        ],
      }
    case 'vehicle_slow':
    case 'vehicle_medium':
    case 'vehicle_fast':
      return {
        ...baseOptions,
        strokeColor: getEstimatedSpeedColor(label),
      }
    case 'interruption':
      return {
        ...baseOptions,
        strokeColor: getEstimatedSpeedColor(label),
        strokeOpacity: 0,
        icons: [
          {
            icon: circle,
            offset: '0',
            repeat: '8px',
          },
        ],
      }
    default:
      return baseOptions
  }
}
