import { ReactElement, Fragment } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTableMenu from './KNDataTableMenu'
import { KNDataTableRowProps, KNDataTableAction } from './types'

const KNDataTableRow = <T extends object>({
  columns,
  row,
  primaryActions = [],
  secondaryActions = [],
}: KNDataTableRowProps<T>): ReactElement => {
  const visiblePrimaryActions = primaryActions.filter((action) => action.isVisible?.(row) ?? true)
  const visibleSecondaryActions = secondaryActions.filter((action) => action.isVisible?.(row) ?? true)

  // NOTE: component isn't wrapped in <TableRow> due to react-virtuoso
  return (
    <>
      {columns.map((column) => (
        <TableCell key={column.name}>
          {column.getValue ? (
            column.getValue(row)
          ) : (
            <KNTypography noWrap color="inherit">
              {row[column.name]}
            </KNTypography>
          )}
        </TableCell>
      ))}
      <TableCell>
        {(visiblePrimaryActions.length > 0 || visibleSecondaryActions.length > 0) && (
          <Stack direction="row" justifyContent="end" sx={{ marginY: '-6px' }} data-guide="datatable-actions">
            {visiblePrimaryActions.map((action) => (
              <Fragment key={action.name}>
                <Tooltip title={action.label} enterDelay={500} placement="top" arrow>
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.stopPropagation()
                      action.onClick?.(row)
                    }}
                    size="small"
                    className="hover-opacity"
                    sx={{ color: 'primary.main' }}
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              </Fragment>
            ))}
            {visibleSecondaryActions.length > 0 && (
              <KNDataTableMenu row={row} actions={visibleSecondaryActions} rightAligned />
            )}
          </Stack>
        )}
      </TableCell>
    </>
  )
}

export default KNDataTableRow
