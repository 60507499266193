import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'
import { ErrorMessage } from 'global/helpers/errorHandler'
import { GeoPoint } from '../../screens/TripDetails/TripDetails.types'

export interface StopsViewState {
  activeStopsGroup: number | string | null
  stopsErrors: ErrorMessage[]
  geoPoint: GeoPoint
}

export type StopsViewAction =
  | { type: 'setActiveStopsGroup'; payload: number | string | null }
  | { type: 'setStopsErrors'; payload: ErrorMessage[] }
  | { type: 'clearStopsErrors' }
  | { type: 'setStopGeoPoint'; payload: GeoPoint }

const initialState: StopsViewState = {
  activeStopsGroup: null,
  stopsErrors: [],
  geoPoint: {latitude:0, longitude:0 },
}

const reducer = (state: StopsViewState, action: StopsViewAction): StopsViewState => {
  switch (action.type) {
    case 'setActiveStopsGroup':
      return {
        ...state,
        // make setting active marker toggleable and reset do default when unchecked
        activeStopsGroup: state.activeStopsGroup !== action.payload ? action.payload : initialState.activeStopsGroup,
      }
    case 'setStopsErrors':
      return {
        ...state,
        stopsErrors: action.payload,
      }
    case 'clearStopsErrors':
      return {
        ...state,
        stopsErrors: [],
      }
    case 'setStopGeoPoint':
      return {
        ...state,
        geoPoint: action.payload,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as StopsViewContext, StoreProvider as StopsViewProvider }
