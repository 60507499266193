import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem'
import boxShadow from 'assets/theme/functions/boxShadow'

const { primary, grey, transparent } = colors
const { borderRadius } = borders

// types
type Types = any

const inputOutlined: Types = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      borderRadius: borderRadius.lg,

      '.MuiOutlinedInput-notchedOutline': {
        top: 0,
        '& legend': {
          lineHeight: 0,
        },
      },

      '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
        borderColor: primary.light,
        borderWidth: pxToRem(1),
        boxShadow: `${boxShadow(
          [0, 0],
          [0, 3],
          primary.contrastText,
          1
        )}, ${boxShadow([0, 1], [2, 0], primary.contrastText, 1)}`,
        transition: 'all 200ms ease-in-out',
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: primary.main,
          borderWidth: pxToRem(1),
          boxShadow: `${boxShadow(
            [0, 0],
            [0, 3],
            primary.contrastText,
            1
          )}, ${boxShadow([0, 1], [2, 0], primary.contrastText, 1)}`,
        },
      },
    },

    input: {
      color: grey[700],
      backgroundColor: transparent.main,
      paddingTop: '12px',
      paddingBottom: '12px',
    },

    inputSizeSmall: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },

    multiline: {
      color: grey[700],
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}

export default inputOutlined
