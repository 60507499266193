import { useState, useEffect, ReactElement, Fragment } from 'react'
import { uniqueId } from 'lodash'
import { Controller, FieldValues, FieldPath, FieldPathValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// @mui imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { getErrorMessage } from 'global/helpers/errorHandler'
import { humanReadableBytes } from 'global/helpers/numberFormatters'
import { validateImage } from 'global/helpers/validators'
import { resizeImage } from 'global/helpers/imageTransformation'
import { getFieldErrorMessages } from 'global/helpers/form'
import { UploadFileResponse } from 'screens/StatusManager/StatusManager.service'
import KNFormFileItem from './KNFormFileItem'
import { KNFormFileProps } from './types'

interface FileItem {
  id: string
  file: File
  pending: boolean
  value?: string | File | Promise<string | File>
  response?: UploadFileResponse
}

const InvisibleInput = styled('input')({
  display: 'none',
})

const KNFormFile = <T extends FieldValues>({
  name,
  control,
  setError,
  clearErrors,
  setValue,
  rules,
  label,
  size,
  sx,
  disabled,
  multiple,
  accept,
  processFile,
  minFileSize,
  maxFileSize,
  validateDocuments = false,
  onPending,
}: KNFormFileProps<T>): ReactElement => {
  const { t } = useTranslation()
  const [files, setFiles] = useState<FileItem[]>([])
  const [pending, setPending] = useState(false)
  const uniqueFieldId = uniqueId('field-')

  const getFileSizeMessage = (): string | undefined => {
    if (minFileSize && maxFileSize) {
      return t('form.validation.min_max_file_size', {
        min: humanReadableBytes(minFileSize),
        max: humanReadableBytes(maxFileSize),
      })
    } else if (minFileSize) {
      return t('form.validation.min_file_size', {
        min: humanReadableBytes(minFileSize),
      })
    } else if (maxFileSize) {
      return t('form.validation.max_file_size', {
        max: humanReadableBytes(maxFileSize),
      })
    }
    return undefined
  }

  const isInvalidFileFormat = (type: string): boolean =>{
    if (!accept) {
      return true
    }
    const acceptedFiles = accept.split(',').map((item: string) => item.trim())
    return !acceptedFiles.includes(type)
  }

  const processPendingFiles = async (): Promise<void> => {
    clearErrors?.(name)
    const processedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.pending) {
          if (validateImage(file.file.type)) {
            file.file = await resizeImage(file.file)
          }
          if (isInvalidFileFormat(file.file.type)) {
            file.value = undefined
            setError?.(name, { message: t('form.validation.incorrect_file_format') })
          } else if ((minFileSize && file.file.size < minFileSize) || (maxFileSize && file.file.size > maxFileSize)) {
            file.value = undefined
            setError?.(name, { message: getFileSizeMessage() })
          } else {
            try {
              if (processFile) {
                const processedFile = await processFile(file.file)
                if (processedFile instanceof File) {
                  file.value = processedFile
                } else {
                  file.value = processedFile.id
                  file.response = processedFile
                }
              } else {
                file.value = file.file
              }
            } catch (error) {
              file.value = undefined
              setError?.(name, { message: getErrorMessage(error) })
            }
          }
          file.pending = false
        }
        return file
      })
    )
    const validFiles = processedFiles.filter((file) => Boolean(file.value))
    setFiles(validFiles)
    setPending(false)
    if (setValue) {
      // https://github.com/react-hook-form/react-hook-form/discussions/7246#discussioncomment-3101599
      if (validFiles.length === 0) {
        setValue(name, null as FieldPathValue<T, FieldPath<T>>)
      } else if (multiple) {
        setValue(name, validFiles.map((file) => file.value) as FieldPathValue<T, FieldPath<T>>)
      } else {
        setValue(name, validFiles[0].value as FieldPathValue<T, FieldPath<T>>)
      }
    }
  }

  useEffect(() => {
    onPending?.(pending)
    if (pending) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      processPendingFiles()
    }
  }, [pending])

  useEffect(() => {
    if (disabled) {
      setFiles([])
      setPending(true)
    }
  }, [disabled])

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
        <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting}>
          <label htmlFor={uniqueFieldId} style={{ alignSelf: 'flex-start' }}>
            <InvisibleInput
              id={uniqueFieldId}
              multiple={multiple}
              type="file"
              accept={accept}
              onChange={(event): void => {
                if (event.target.files) {
                  const newFiles = Array.from(event.target.files).map((file) => {
                    return {
                      id: uniqueId('file-'),
                      file,
                      pending: true,
                    }
                  })
                  setFiles(multiple ? [...files, ...newFiles] : newFiles)
                  setPending(true)
                }
                event.target.value = ''
              }}
              disabled={formState.isSubmitting || disabled}
            />
            <Button
              variant="contained"
              data-test={`form-file-${name}`}
              component="span"
              size={size}
              color="secondary"
              disabled={formState.isSubmitting || disabled}
            >
              {label ?? t('form.select_file')}
            </Button>
          </label>
          {files.length > 0 && (
            <List disablePadding>
              {files.map((file, index) => (
                <Fragment key={file.id}>
                  <KNFormFileItem
                    file={file.file}
                    pending={file.pending}
                    disabled={formState.isSubmitting || disabled}
                    onDelete={(): void => {
                      const newFiles = [...files]
                      newFiles.splice(index, 1)
                      setFiles(newFiles)
                      setPending(true)
                    }}
                  />
                  {validateDocuments && !file.pending && !file.response?.isDocument && (
                    <ListItem disableGutters disablePadding>
                      <FormHelperText sx={{ color: 'warning.main' }}>{t('form.not_a_document')}</FormHelperText>
                    </ListItem>
                  )}
                </Fragment>
              ))}
            </List>
          )}
          {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default KNFormFile
