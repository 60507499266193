import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormTagsProps } from './types'

const KNFormTags = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  size,
  sx,
  disabled,
}: KNFormTagsProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl data-test={name} fullWidth sx={sx} error={invalid}>
        <Autocomplete
          size={size}
          disabled={formState.isSubmitting || disabled}
          renderTags={(tagValue, getTagProps): ReactElement[] =>
            tagValue.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip {...getTagProps({ index })} size="small" label={option} />
            ))
          }
          renderInput={(params): ReactElement => <TextField {...params} label={label} error={invalid} />}
          value={value}
          onChange={(event, value, reason): void => {
            onChange(value)
          }}
          options={[]}
          multiple
          freeSolo
          disableClearable
          // isOptionEqualToValue={(option, value): boolean => option.value === value.value}
        />
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormTags
