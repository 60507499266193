import { useEffect, useState, useCallback, useContext, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// KN imports
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Functional
import { UserContext } from 'context/authentication/UserContext'
import { setSelectedCompany } from 'context/authentication/User.helpers'

// Types
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { LegData } from 'screens/TripDetails/TripDetails.types'
import { Company } from 'context/authentication/Company.types'

const getUserCompanyByGroupId = (companies: Company[] = [], groupId: string) => {
  return companies.find((company) => company.groupIds.includes(groupId)) ?? null
}

interface BackButtonProps {
  trip: TripData
  legs: LegData[]
}

const BackButton = ({ trip, legs }: BackButtonProps): ReactElement | null => {
  const { t } = useTranslation()
  const history = useHistory()
  const { user } = useContext(UserContext)
  const [company, setCompany] = useState<Company | null>(null)

  useEffect(() => {
    const relatedCompany = getUserCompanyByGroupId(user?.companies, trip.groupId)
    if (!relatedCompany) {
      return
    }
    setSelectedCompany(relatedCompany)
    history.push(`/trips/${trip.entityId}`)
  }, [user])

  const handleLoginClick = useCallback(() => {
    history.push('/login')
  }, [])

  return (
    <KNButton
      variant="contained"
      size="small"
      onClick={handleLoginClick}
    >
      {t('general.go_to_login')}
    </KNButton>
  )
}

export default BackButton
