import i18n from 'i18n'

export const releaseNotesTranslations = (): Record<any, any> => {
  const releaseNotes = 'screens.shared.release_notes'
  return {
    translation: {
      screenName: i18n.t(`${releaseNotes}.screenName`),
      user_guides: i18n.t(`${releaseNotes}.user_guides`),
      release_notes: i18n.t(`${releaseNotes}.release_notes`),
    },
  }
}
