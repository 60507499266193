import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, ValidateResult, useForm } from 'react-hook-form'

// @mui imports
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNFormTags from 'components/KN_Molecules/KNForm/KNFormTags'
import KNFormCheckbox from 'components/KN_Molecules/KNForm/KNFormCheckbox'

// Functional
import { sleep } from 'global/helpers/sleep'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import { createCompany, editCompany } from './CompanyManager.service'
import { analyticsEvent } from 'global/helpers/analytics'

// Types
import { Company, CompanyModules, CompanyType, VisibilityLevel } from 'context/authentication/Company.types'

interface CreateOrEditCompanyDialogPayload {
  company: Company
}

interface CreateOrEditCompanyDialogProps {
  payload?: CreateOrEditCompanyDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

interface CompanyProperties {
  address: string
  visibilityLevel: VisibilityLevel
}

export interface CreateOrEditCompanyFormValues {
  type: CompanyType
  displayName: string
  customerIdentifiers?: string[]
  properties?: Partial<CompanyProperties>
  modules?: CompanyModules | null
}

const CreateOrEditCompanyDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: CreateOrEditCompanyDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, watch, reset, control, formState, getValues, setValue, setError, clearErrors } =
    useForm<CreateOrEditCompanyFormValues>()
  const watchedDisplayName = watch('displayName')
  const watchedCustomerIdentifiers = watch('customerIdentifiers')
  const watchedType = watch('type')
  const watchedVisibilityLevel = watch('properties.visibilityLevel')
  const watchedRoadModule = watch('modules.road_shipments')
  const watchedAirModule = watch('modules.air_shipments')
  const watchedTemperatureModule = watch('modules.temperature')

  const isSubmitDisabled = (): boolean => {
    const requiredFieldsFilled = watchedDisplayName && watchedCustomerIdentifiers?.length && watchedType

    const visibilityLevelAndModulesRequired =
      watchedType !== 'Customer' ||
      (watchedVisibilityLevel && (watchedVisibilityLevel === 'Max' || watchedVisibilityLevel === 'Advanced')
        ? watchedRoadModule || watchedAirModule
        : watchedVisibilityLevel)

    return !requiredFieldsFilled || !visibilityLevelAndModulesRequired || formState.isSubmitting
  }
  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          type: payload?.company.type,
          displayName: payload?.company.displayName,
          customerIdentifiers: payload?.company.customerIdentifiers ?? [],
          properties: processDefaultValues({
            visibilityLevel: payload?.company.properties?.visibilityLevel,
            address: payload?.company.properties?.address,
          }),
          modules: payload?.company.modules ?? null,
        })
      )
    }
  }, [open])

  useEffect(() => {
    // !payload because we empty these only if a new company needs to be created //
    if (watchedType && !payload) setValue('properties', {})
    if (watchedType === 'Carrier') setValue('modules', null)
    else
      !payload &&
        setValue('modules', {
          road_shipments: false,
          air_shipments: false,
          location: false,
          temperature: false,
          temperature_prediction: false,
          polestar_tro: false,
          eta: false,
        })
  }, [watchedType])

  const onSubmit: SubmitHandler<CreateOrEditCompanyFormValues> = async (data: CreateOrEditCompanyFormValues) => {
    try {
      if (payload) {
        await editCompany(payload.company.cid, data)
      } else {
        await createCompany(data)
      }
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      analyticsEvent(payload ? 'polestar_cv_company_edited' : 'polestar_cv_company_created')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={
          payload
            ? `${payload.company.displayName} — ${t('screens.shared.company_manager.add_edit_company.edit_details')}`
            : t('screens.shared.company_manager.add_edit_company.new_company')
        }
        actions={
          <>
            <KNLoadingButton
              data-test="update-button"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitDisabled()}
            >
              {payload
                ? t('screens.shared.company_manager.add_edit_company.update')
                : t('screens.shared.company_manager.add_edit_company.create')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Stack spacing={2}>
          <KNFormText
            name="displayName"
            label={t('screens.shared.company_manager.add_edit_company.display_name')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
          <KNFormTags
            name="customerIdentifiers"
            label={t('screens.shared.company_manager.add_edit_company.customer_ids')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
          <KNFormDropdown
            name="type"
            label={t('screens.shared.company_manager.add_edit_company.type')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
            options={Object.keys(CompanyType)
              .filter((type) => type !== 'Operator')
              .map((type) => {
                return {
                  value: type,
                  label: t(`screens.shared.company_manager.company_types.${type}`),
                }
              })}
          />
          {watchedType === 'Carrier' && (
            <KNFormText
              name="properties.address"
              label={t('screens.shared.company_manager.add_edit_company.address')}
              control={control}
            />
          )}
          {watchedType === 'Customer' && (
            <>
              <KNFormDropdown
                name="properties.visibilityLevel"
                label={t('screens.shared.company_manager.add_edit_company.visibility_level')}
                control={control}
                options={Object.keys(VisibilityLevel).map((level) => ({
                  value: level,
                  label: t(`screens.shared.company_manager.visibility_types.${level}`),
                }))}
              />
              {watchedVisibilityLevel && watchedVisibilityLevel !== 'Basic' && (
                <Stack data-test="access-modules" spacing={1}>
                  <InputLabel>{t('screens.shared.company_manager.add_edit_company.access_modules')}</InputLabel>
                  <KNFormCheckbox
                    name="modules.road_shipments"
                    control={control}
                    onCheck={(): void => clearErrors('modules.air_shipments')}
                    label={t('screens.shared.company_manager.add_edit_company.modules.road_shipments')}
                    rules={{
                      validate: {
                        isNumber: (value): ValidateResult => {
                          if (!value && !getValues('modules.air_shipments'))
                            return t('screens.shared.company_manager.add_edit_company.shipment_type_error')
                          return true
                        },
                      },
                    }}
                  />
                  <KNFormCheckbox
                    name="modules.air_shipments"
                    control={control}
                    onCheck={(): void => clearErrors('modules.road_shipments')}
                    label={t('screens.shared.company_manager.add_edit_company.modules.air_shipments')}
                    rules={{
                      validate: {
                        isNumber: (value): ValidateResult => {
                          if (!value && !getValues('modules.road_shipments'))
                            return t('screens.shared.company_manager.add_edit_company.shipment_type_error')
                          return true
                        },
                      },
                    }}
                  />
                  <Divider />
                  <InputLabel>{t('screens.shared.company_manager.add_edit_company.additional_modules')}</InputLabel>
                  <KNFormCheckbox
                    name="modules.location"
                    control={control}
                    label={t('screens.shared.company_manager.add_edit_company.modules.map')}
                  />
                  <KNFormCheckbox
                    name="modules.temperature"
                    control={control}
                    label={t('screens.shared.company_manager.add_edit_company.modules.temperature_charts')}
                  />
                  {watchedTemperatureModule && (
                    <>
                      <KNFormCheckbox
                        name="modules.temperature_prediction"
                        control={control}
                        label={t('screens.shared.company_manager.add_edit_company.modules.temperature_prediction')}
                      />
                      <KNFormCheckbox
                        name="modules.polestar_tro"
                        control={control}
                        label={t('screens.shared.company_manager.add_edit_company.modules.polestar_tro')}
                      />
                    </>
                  )}
                  <KNFormCheckbox
                    name="modules.eta"
                    control={control}
                    label={t('screens.shared.company_manager.add_edit_company.modules.eta')}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </KNDialog>
    </KNForm>
  )
}

export default CreateOrEditCompanyDialog
