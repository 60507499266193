// types
interface ColorsTypes {
  main: string
  focus: string
  light: string
  contrastText: string
}

interface GradientsTypes {
  main: string
  state: string
}

interface Types {
  background:
    | {
        default: string
      }
    | any
  white:
    | {
        main: string
      }
    | any
  text:
    | {
        main: string
        focus: string
        primary?: string
        secondary?: string
        disabled?: string
      }
    | any
  transparent:
    | {
        main: string
      }
    | any
  black:
    | {
        light: string
        main: string
        focus: string
      }
    | any
  primary: ColorsTypes | any
  secondary: ColorsTypes | any
  info: ColorsTypes | any
  success: ColorsTypes | any
  warning: ColorsTypes | any
  error: ColorsTypes | any
  light: ColorsTypes | any
  dark: ColorsTypes | any
  grey: any
  gradients:
    | {
        primary: GradientsTypes
        secondary: GradientsTypes
        info: GradientsTypes
        success: GradientsTypes
        warning: GradientsTypes
        error: GradientsTypes
        light: GradientsTypes
        dark: GradientsTypes
      }
    | any
}

const colors: Types = {
  background: {
    default: '#FFFFFF',
  },

  transparent: {
    main: 'transparent',
  },

  white: {
    main: '#FFFFFF',
    focus: '#FFFFFF',
  },

  black: {
    main: '#000000',
    light: '#141414',
    focus: '#000000',
  },

  primary: {
    main: '#003369',
    focus: '#001E3D',
    dark: '#001E3D',
    light: '#99ADC3',
    contrastText: '#E0E6EE',
  },

  secondary: {
    main: '#008AC4',
    focus: '#0C688F',
    dark: '#0C688F',
    light: '#ADE7FF',
    contrastText: '#D6F3FF',
  },

  text: {
    main: '#212529',
    focus: '#63676C',
    disabled: '#DADADA',
  },

  info: {
    main: '#D2D6EF',
    light: '#F4F5FB',
  },

  success: {
    main: '#08C792',
    contrastText: '#ffffff',
  },

  warning: {
    main: '#FAB005',
    light: '#FDDF9B',
  },

  error: {
    main: '#D1495B',
    dark: '#B82F41',
  },

  light: {
    main: '#e9ecef',
    focus: '#e9ecef',
    light: '#F4F5F7',
  },

  dark: {
    main: '#212529',
    focus: '#63676C',
    disabled: '#DADADA',
  },

  grey: {
    100: '#fcfcfc',
    200: '#e2e1e1',
    300: '#c7c5c5',
    400: '#9d9a9a',
    500: '#736f6f',
    600: '#393737',
  },

  gradients: {
    primary: {
      main: '#12316E',
      state: '#0099da',
    },

    secondary: {
      main: '#0099da',
      state: '#12316E',
    },

    info: {
      main: '#2152ff',
      state: '#21d4fd',
    },

    success: {
      main: '#17ad37',
      state: '#98ec2d',
    },

    warning: {
      main: '#f53939',
      state: '#fbcf33',
    },

    error: {
      main: '#ea0606',
      state: '#ff667c',
    },

    light: {
      main: '#ced4da',
      state: '#ebeff4',
    },

    dark: {
      main: '#141727',
      state: '#3a416f',
    },
  },
}

export default colors
