import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  areIntervalsOverlapping,
  endOfDay,
  isBefore,
  isAfter,
  isValid,
  isWithinInterval,
  parseISO,
  startOfDay,
} from 'date-fns'
import { minTime, maxTime } from 'date-fns/constants'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// KN Components
import { TripListContext } from 'context/trips/TripListContext'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import PredefinedFilters from './PredefinedFilters'
import TripListFilters from './TripListFilters'
import TripTable from './TripTable'
import BruceBanner from 'modules/BruceBanner/BruceBanner'

// Functional
import { sleep } from 'global/helpers/sleep'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { getVehicles } from 'screens/VehicleManager/VehicleManager.service'
import { getDrivers } from 'screens/DriverManager/DriverManager.service'
import { getUserPreferences } from 'screens/UserManager/UserManager.service'
import { processBranches, processDriversAndVehicles } from './TripDashboard.helpers'
import { getCounters, getTrips, getFilteredTrips } from './TripDashboard.service'

// Types
import { CountersData, BranchesData, TripData } from './TripDashboard.types'

const TripDashboard = (): ReactElement => {
  const { t } = useTranslation()
  const [countersData, setCountersData] = useState<CountersData>()
  const [branchesData, setBranchesData] = useState<BranchesData>()
  const [tripsData, setTripsData] = useState<TripData[]>([])
  const [filteredTripsData, setFilteredTripsData] = useState<TripData[]>([])
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(false)
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  const validRangeInterval = {
    start: new Date('2000-01-01'),
    end: new Date('2100-01-01'),
  }

  const fetchInitialData = async (): Promise<void> => {
    setLoading(true)
    const [vehicles, drivers, counters, trips] = await Promise.all([
      tripListState.vehiclesPreloaded ? tripListState.vehicles : getVehicles(),
      tripListState.driversPreloaded ? tripListState.drivers : getDrivers(),
      getCounters(),
      tripListState.predefinedFilter ? getFilteredTrips(tripListState.predefinedFilter) : getTrips(),
    ])
    if (!tripListState.vehiclesPreloaded) {
      tripListDispatch({ type: 'setVehicles', payload: vehicles })
    }
    if (!tripListState.driversPreloaded) {
      tripListDispatch({ type: 'setDrivers', payload: drivers })
    }
    setCountersData(counters)
    setTripsData(trips.map((trip) => processDriversAndVehicles(trip, vehicles, drivers)))
    setBranchesData(processBranches(trips))
    setLoading(false)
  }

  const fetchData = async (backgroundLoading = false): Promise<void> => {
    if (backgroundLoading) {
      tripListDispatch({ type: 'setBackgroundLoading', payload: true })
      await sleep(1000)
    } else {
      setDataLoading(true)
    }
    const [counters, trips] = await Promise.all([
      getCounters(),
      tripListState.predefinedFilter ? getFilteredTrips(tripListState.predefinedFilter) : getTrips(),
    ])
    setCountersData(counters)
    setTripsData(trips.map((trip) => processDriversAndVehicles(trip, tripListState.vehicles, tripListState.drivers)))
    setBranchesData(processBranches(trips))
    if (backgroundLoading) {
      tripListDispatch({ type: 'setBackgroundLoading', payload: false })
    } else {
      setDataLoading(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchInitialData()
    analyticsPageView('polestar/cs/trips_list')
  }, [])

  useEffect(() => {
    // NOTE: avoid calling counters and trips endpoints twice on initial load
    if (!loading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData()
    }
  }, [tripListState.predefinedFilter])

  useEffect(() => {
    setFilteredTripsData(getFilteredTripsData())
  }, [tripsData, tripListState.filters])

  const handleOnChange = useCallback(
    async (updatedTrip: TripData, action: string): Promise<void> => {
      if (action === 'delete') {
        const updatedTripsData = tripsData.filter((trip) => updatedTrip.entityId !== trip.entityId)
        setTripsData(updatedTripsData)
      }
      await fetchData(true)
    },
    [tripListState]
  )

  const getFilteredTripsData = (): TripData[] =>
    tripsData.filter((trip) => {
      const lowercaseKeywords = tripListState.filters.keywords?.map((keyword: string) => keyword.toLowerCase())
      let keywordsCondition = true
      if (lowercaseKeywords?.length) {
        const lowercaseValues = [
          trip.voyageNumber,
          trip.pickUpPlace,
          trip.countryCodePickUpPlace,
          trip.countryPickUpPlace ?? '',
          trip.deliveryPlace,
          trip.countryCodeDeliveryPlace,
          trip.countryDeliveryPlace ?? '',
          trip.assignedDriver?.name ?? '',
          trip.assignedVehicle?.licensePlate ?? '',
          trip.secondaryAssignedVehicle?.licensePlate ?? '',
          ...trip.shipmentNumbers.map((shipmentNumber: string) => shipmentNumber.toLowerCase()),
          ...trip.customerReferences,
        ].map((value: string) => value.toLowerCase())
        keywordsCondition = lowercaseValues
          .map((value) => {
            return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
          })
          .some((condition: boolean) => condition)
      }

      let statusCondition = true
      const statusFilters = tripListState.filters.status
      if (statusFilters?.length) {
        statusCondition = statusFilters
          .map((status) => {
            return trip.status === status
          })
          .some((condition: boolean) => condition)
      }

      let dateCondition = true
      const fromDateFilter =
        tripListState.filters.fromDate &&
        isValid(tripListState.filters.fromDate) &&
        isWithinInterval(tripListState.filters.fromDate, validRangeInterval) &&
        startOfDay(tripListState.filters.fromDate)
      const toDateFilter =
        tripListState.filters.toDate &&
        isValid(tripListState.filters.toDate) &&
        isWithinInterval(tripListState.filters.toDate, validRangeInterval) &&
        endOfDay(tripListState.filters.toDate)
      const parsedEarlyPickUpDate = parseISO(trip.earlyPickUpDate)
      const parsedLateDeliveryDate = parseISO(trip.lateDeliveryDate)
      // NOTE: handle edge cases when pickup date is after delivery for some reason
      const pickupInterval = {
        start: isBefore(parsedEarlyPickUpDate, parsedLateDeliveryDate)
          ? startOfDay(parsedEarlyPickUpDate)
          : startOfDay(parsedLateDeliveryDate),
        end: isAfter(parsedLateDeliveryDate, parsedEarlyPickUpDate)
          ? endOfDay(parsedLateDeliveryDate)
          : endOfDay(parsedEarlyPickUpDate),
      }
      if (fromDateFilter && toDateFilter) {
        if (isAfter(toDateFilter, fromDateFilter)) {
          const dateFilterInterval = {
            start: fromDateFilter,
            end: toDateFilter,
          }
          dateCondition = areIntervalsOverlapping(pickupInterval, dateFilterInterval, { inclusive: true })
        }
      } else if (fromDateFilter) {
        const fromDateFilterInterval = {
          start: fromDateFilter,
          end: maxTime,
        }
        dateCondition = areIntervalsOverlapping(pickupInterval, fromDateFilterInterval, { inclusive: true })
      } else if (toDateFilter) {
        const toDateFilterInterval = {
          start: minTime,
          end: toDateFilter,
        }
        dateCondition = areIntervalsOverlapping(pickupInterval, toDateFilterInterval, { inclusive: true })
      }

      let branchesCondition = true
      const branchesFilters = tripListState.filters.branches
      if (branchesFilters?.length) {
        branchesCondition = branchesFilters
          .map((branch) => {
            return trip.logicalSender === branch
          })
          .some((condition: boolean) => condition)
      }

      return [keywordsCondition, statusCondition, dateCondition, branchesCondition].every(
        (condition: boolean) => condition
      )
    })

  return (
    <Container maxWidth="xl" data-test="trips-container">
      <BruceBanner moduleName="cs" />
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.cs.trip_dashboard.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          {countersData && (
            <PredefinedFilters
              counters={countersData}
              sx={{
                mb: 2,
              }}
            />
          )}

          <Paper elevation={8} sx={{ padding: 2 }}>
            <Stack direction="column" spacing={1} mb={1}>
              <KNTypography data-test="trips-count" variant="h4">
                {t('screens.cs.trip_dashboard.trips_count', {
                  count: filteredTripsData.length,
                })}
              </KNTypography>
              <TripListFilters branches={branchesData} />
            </Stack>
            {dataLoading ? (
              <KNLoader>
                <KNTypography>{t('screens.cs.trip_dashboard.loading')}</KNTypography>
              </KNLoader>
            ) : (
              <TripTable trips={filteredTripsData} onChange={handleOnChange} />
            )}
          </Paper>
        </>
      )}
    </Container>
  )
}

export default TripDashboard
