import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormCheckboxProps } from './types'

const KNFormCheckbox = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  size,
  sx,
  disabled,
  checkboxProps,
  onCheck,
}: KNFormCheckboxProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl data-test={name} fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting || disabled}>
        <FormControlLabel
          control={
            <Checkbox
              {...checkboxProps}
              checked={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                onCheck?.()
                onChange(event.target.checked)
              }}
              size={size}
              sx={{
                py: 0,
              }}
            />
          }
          label={label}
        />
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormCheckbox
