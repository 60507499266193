import typography from 'assets/theme/base/typography'

const { size, fontWeightRegular } = typography

// types
type Types = any

const stepLabel: Types = {
  styleOverrides: {
    label: {
      fontWeight: fontWeightRegular,
      fontSize: size.xs,
      color: '#9fc9ff',
      textTransform: 'uppercase',
    },
  },
}

export default stepLabel
