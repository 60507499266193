import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem'
import linearGradient from 'assets/theme/functions/linearGradient'

const { borderWidth, borderColor } = borders
const { transparent, info, primary } = colors

// types
type Types = any

const checkbox: Types = {
  styleOverrides: {
    root: {
      '& .MuiSvgIcon-root': {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: pxToRem(20),
        height: pxToRem(20),
        color: transparent.main,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: pxToRem(5.6),
      },

      '&:hover': {
        backgroundColor: transparent.main,
      },

      '&.Mui-focusVisible': {
        border: `${borderWidth[2]} solid ${
          primary?.main as string
        } !important`,
      },
    },

    colorPrimary: {
      color: borderColor,

      '&.Mui-checked': {
        color: info.main,

        '& .MuiSvgIcon-root': {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 0 25 25'%3e%3cpath fill='none' stroke='%23fff' stroke-width='2' d='M9 16.2L4.8 12L3.4 13.4L9 19L21 7L19.6 5.6z'/%3e%3c/svg%3e"), ${linearGradient(
            primary.main,
            primary.main,
          )}`,
          borderColor: primary.main,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      '&.Mui-checked': {
        color: info.main,

        '& .MuiSvgIcon-root': {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 0 25 25'%3e%3cpath fill='none' stroke='%23fff' stroke-width='2' d='M9 16.2L4.8 12L3.4 13.4L9 19L21 7L19.6 5.6z'/%3e%3c/svg%3e"), ${linearGradient(
            info.main,
            info.main,
          )}`,
          borderColor: info.main,
        },
      },
    },
  },
}

export default checkbox
