// @mui imports
import Button from '@mui/material/Button'
import { styled, Theme } from '@mui/material/styles'

const KNButtonRoot = styled(Button)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
  const { palette, boxShadows } = theme
  const { color, variant, textTransform } = ownerState

  const { white, text, gradients } = palette
  const buttonBoxShadow = boxShadows?.buttonBoxShadow

  // styles for the button with variant="contained"
  const containedStyles = (): Record<any, any> => {
    // background color value
    const backgroundValue = palette[color] ? palette[color].main : white?.main

    // backgroundColor value when button is focused
    const focusedBackgroundValue = palette[color] ? palette[color].focus : white?.focus

    // color value
    const colorValue = white?.main

    // color value when button is focused
    let focusedColorValue = white?.main

    if (color === 'white') {
      focusedColorValue = text?.main
    } else if (color === 'primary' || color === 'error' || color === 'dark') {
      focusedColorValue = white?.main
    }

    return {
      background: backgroundValue,
      color: colorValue,
      transition: 'opacity 0.5s ease',

      '&:hover': {
        backgroundColor: backgroundValue,
        boxShadow: buttonBoxShadow?.main,
        color: colorValue,
      },

      '&:focus:not(:hover)': {
        backgroundColor: focusedBackgroundValue,
        boxShadow: buttonBoxShadow?.main,
        color: colorValue,
      },

      '&:disabled': {
        opacity: 0.5,
        color: focusedColorValue,
      },
    }
  }

  // styles for the button with variant="gradient"
  const gradientStyles = (): Record<any, any> => {
    // background value
    const backgroundValue =
      color === 'white' || !gradients[color]
        ? white?.main
        : `linear-gradient(112.86deg, ${gradients[color].main as string} 5.19%, ${
            gradients[color].state as string
          } 48.68%)`

    // color value
    let colorValue = white?.main

    if (color === 'white') {
      colorValue = text?.main
    } else if (color === 'light') {
      colorValue = gradients?.dark.state
    }

    return {
      background: backgroundValue,
      color: colorValue,

      '&:hover': {
        boxShadow: buttonBoxShadow?.main,
        color: colorValue,
      },

      '&:focus:not(:hover)': {
        color: colorValue,
      },

      '&:disabled': {
        opacity: 0.5,
        color: colorValue,
      },
    }
  }

  // styles for the button with variant="text"
  const textStyles = (): Record<any, any> => {
    // color value
    const colorValue = palette[color] ? palette[color].main : white.main

    // color value when button is focused
    const focusedColorValue = palette[color] ? palette[color].focus : white.focus

    return {
      color: colorValue,
      '&:hover': {
        backgroundColor: 'transparent',
        color: focusedColorValue,
      },

      '&:focus:not(:hover)': {
        color: focusedColorValue,
      },
    }
  }

  // styles for the button with variant="outlined"
  const outlinedStyles = (): Record<any, any> => {
    // color value
    const colorValue = palette[color] ? palette[color].main : white.main

    // color value when button is focused
    const focusedColorValue = palette[color] ? palette[color].focus : white.focus

    return {
      color: colorValue,
      border: `1px solid ${colorValue as string}`,
      '&:hover': {
        borderColor: focusedColorValue,
      },

      '&:focus:not(:hover)': {
        borderColor: focusedColorValue,
      },

      '&:disabled': {
        border: `1px solid rgba(0, 0, 0, .25)`,
      },
    }
  }

  return {
    ...(variant === 'contained' && containedStyles()),
    ...(variant === 'gradient' && gradientStyles()),
    ...(variant === 'text' && textStyles()),
    ...(variant === 'outlined' && outlinedStyles()),
    textTransform: textTransform,
    textDecoration: 'none',
  }
})

export default KNButtonRoot
