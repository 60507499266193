import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'

// types
type Types = any

const inputLabel: Types = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      position: 'relative',
      transform: 'none',
      transition: 'none',

      '&.Mui-focused': {
        color: colors.primary.light,
      },

      '&.MuiInputLabel-shrink': {
        '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
          display: 'none',
        },
      },
    },
  },
}

export default inputLabel
