// Functional
import { fetchGet, fetchPost, fetchDelete, fetchPut, getChunkedResults } from 'global/helpers/fetchWrapper'

// KN imports
import { NotificationsFormValues } from 'screens/ProfilePage/Notifications'
import { CreateOrEditUserFormValues } from './CreateOrEditUserDialog'
import { BulkCreateUsersDialogFormValues } from './BulkCreateUsersDialog'
import { GrantRoleFormValues } from './GrantRoleDialog'
import { RevokeRoleFormValues } from './RevokeRoleDialog'

// Types
import { PaginatedResults } from 'global/types/pagination'
import { StoreLanguageCommand, UserPreferencesData, TimeZone, UserData } from './UserManager.types'

export const getPaginatedUsers = async (page = 0, pageSize = 1000): Promise<PaginatedResults<UserData>> =>
  await fetchGet(`users?page=${page}&size=${pageSize}`)

export const getUsers = async (): Promise<UserData[]> => {
  const users = await getChunkedResults<UserData>(getPaginatedUsers)
  return users.sort(sortUsers)
}

export const createUser = async (data: CreateOrEditUserFormValues): Promise<void> => await fetchPost('users', data)

export const editUser = async (cid: string, data: CreateOrEditUserFormValues): Promise<void> => {
  const processedData = {
    ...data,
    userCid: cid,
  }
  return await fetchPut(`users/${cid}`, processedData)
}

export const deleteUser = async (cid: string): Promise<void> => await fetchDelete(`users/${cid}`)

export const uploadBulkUsers = async (data: BulkCreateUsersDialogFormValues): Promise<string[]> => {
  const formData = new FormData()
  formData.append('form', data.csv)
  return await fetchPost('users/bulk', formData)
}

export const grantRole = async (cid: string, data: GrantRoleFormValues): Promise<void> => {
  const processedData = {
    ...data,
    userCid: cid,
  }
  return await fetchPost(`users/${cid}/grant`, processedData)
}

export const revokeRole = async (cid: string, data: RevokeRoleFormValues): Promise<void> => {
  const processedData = {
    ...data,
    userCid: cid,
  }
  return await fetchPost(`users/${cid}/revoke`, processedData)
}

export const storeLanguage = async (data: StoreLanguageCommand): Promise<void> => await fetchPost('user/language', data)

export const getUserPreferences = async (): Promise<UserPreferencesData> => await fetchGet('user/preferences')

export const saveUserPreferences = async (data: UserPreferencesData): Promise<void> =>
  await fetchPost('user/preferences', data)

export const updateNotificationPreferences = async (data: NotificationsFormValues): Promise<void> =>
  await fetchPost('user/preferences', data)

export const getTimeZones = async (): Promise<TimeZone[]> => {
  return await fetchGet('user/preferences/timezones')
}

const sortUsers = (a, b) =>
  a.displayName === b.displayName ? 0 : a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
