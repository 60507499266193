import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const strokeWidth = 2

export const KNRoadIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M22 4 2 60M42 4l20 56" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M32 12v10m0 8v10m0 8v10" />
  </SvgIcon>
)

export const KNLoopIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M15.998 53.997A8.251 8.251 0 0 1 8 45.75v-27.5A8.25 8.25 0 0 1 16.25 10H37"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M48 10.004a8.25 8.25 0 0 1 8 8.246v27.5A8.25 8.25 0 0 1 47.75 54H27"
    />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="m28 18 9-8-9-8" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="m36 46-9 8 9 8" />
  </SvgIcon>
)

export const KNVehicleIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M38 19h14l6 12 4 4v8" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M2 43V7h36v36" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M44 51H20M8 51H2v-8h60v8h-6" />
    <circle fill="none" stroke="currentColor" strokeWidth={strokeWidth} cx="14" cy="51" r="6" />
    <circle fill="none" stroke="currentColor" strokeWidth={strokeWidth} cx="50" cy="51" r="6" />
  </SvgIcon>
)

export const KNProfileIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M28 8H8v54h48V8H36" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M28 2h8v10h-8z" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M44 48H20m16 8H20" />
    <circle fill="none" stroke="currentColor" strokeWidth={strokeWidth} cx="32" cy="25" r="6" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M42 41a10 10 0 1 0-20 0z" />
  </SvgIcon>
)

export const KNPairingIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M37 30.3l.8.8a9.3 9.3 0 0 0 13.2 0l8.3-8.3a9.3 9.3 0 0 0 0-13.2l-5-5a9.3 9.3 0 0 0-13.2 0L32.8 13a9.3 9.3 0 0 0 0 13.2l.8.8m3.4-3.3l-.8-.8a4.7 4.7 0 0 1 0-6.6L44.4 8A4.7 4.7 0 0 1 51 8l5 5a4.7 4.7 0 0 1 0 6.6l-8.3 8.3a4.7 4.7 0 0 1-6.6 0l-.8-.8M27 33.7l-.8-.8a9.3 9.3 0 0 0-13.2 0l-8.3 8.2a9.3 9.3 0 0 0 0 13.2l5 5a9.3 9.3 0 0 0 13.2 0l8.3-8.3a9.3 9.3 0 0 0 0-13.2l-.8-.8M27 40.3l.8.8a4.7 4.7 0 0 1 0 6.6L19.6 56a4.7 4.7 0 0 1-6.6 0l-5-5a4.7 4.7 0 0 1 0-6.6l8.3-8.3a4.7 4.7 0 0 1 6.6 0l.8.8"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M45.4 18.6a2.3 2.3 0 0 1 0 3.3l-24 24a2.3 2.3 0 0 1-3.3 0 2.3 2.3 0 0 1 0-3.3l24-24a2.3 2.3 0 0 1 3.3 0z"
    />
  </SvgIcon>
)

export const KNUnpairingIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M14.9 26h-13m22-11V2m-8 16L6.7 8.8M48.9 38h13m-22 11v13m8-16l9.2 9.2"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M27 41.1a4.7 4.7 0 0 1 0 6.6L18.7 56a4.7 4.7 0 0 1-6.6 0l-4-4a4.7 4.7 0 0 1 0-6.6l8.3-8.3a4.7 4.7 0 0 1 6.6 0l2.2-4.2a9.4 9.4 0 0 0-12.2.9l-8.2 8.3a9.3 9.3 0 0 0 0 13.2l4 4a9.3 9.3 0 0 0 13.2 0l8.3-8.3a9.4 9.4 0 0 0 .9-12.2zm10.2-18.2a4.7 4.7 0 0 1 0-6.6L45.5 8a4.7 4.7 0 0 1 6.6 0l4 4a4.7 4.7 0 0 1 0 6.6l-8.3 8.3a4.7 4.7 0 0 1-6.6 0L39 31.1a9.4 9.4 0 0 0 12.2-.9l8.3-8.3a9.3 9.3 0 0 0 0-13.2l-4-4a9.3 9.3 0 0 0-13.2 0L33.9 13a9.4 9.4 0 0 0-.9 12.2z"
    />
  </SvgIcon>
)

export const KNVisibilityIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M32 8V2" />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M22 28v34m20-34v34m-28 0h36M18 28h28m-20 0V16m12 12V16m-14 0h16m-14 0v-2a6 6 0 1 1 12 0v2"
    />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M16 16L4 10m12 12L4 28m44-6l12 6M48 16l12-6" />
  </SvgIcon>
)

export const KNUserIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M46 26c0 6.1-3.4 11.5-7 14.8V44c0 2 1 5.1 11 7a15.5 15.5 0 0 1 12 11H2a13.4 13.4 0 0 1 11-11c10-1.8 12-5 12-7v-3.2c-3.6-3.3-7-8.6-7-14.8v-9.6C18 6 25.4 2 32 2s14 4 14 14.4z"
    />
  </SvgIcon>
)

export const KNUsersIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M27.4 12.2c.9-3.3 3.2-7.4 9-7.4 0 0 16-5.1 16 11.2 0 13.7 3.1 15.2 6.4 18.5A56.6 56.6 0 0 1 46 36v1.6c0 1.6-.3 4.8 7.7 6.4s8.3 8 8.3 8H41"
      data-name="layer2"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M38.1 51.2c-8-1.1-8-4-8-5.6v-.9a14.6 14.6 0 0 0 4.2-7c2.4 0 3-5.1 3-5.8s-.2-3.6-1.3-3.9c4-14-8-20-18.4-12.8-4.4 0-5.8 6.4-4.2 12.8-1.1 0-1.3 3-1.3 3.8s.6 5.8 3 5.8c.8 2.9 3 5.3 4.9 7v1c0 1.6-.6 4.6-8.7 5.6S2 60 2 60h44s.1-7.7-7.9-8.8z"
    />
  </SvgIcon>
)

export const KNCompaniesIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <circle cx="32" cy="39" r="7" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M32 46a12.1 12.1 0 0 0-12 12v2h24v-2a12.1 12.1 0 0 0-12-12z"
    />
    <circle cx="52" cy="10" r="6" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M62 28c0-7.5-4.5-12-10-12s-10 4.5-10 12z" />
    <circle cx="12" cy="10" r="6" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M22 28c0-7.5-4.5-12-10-12S2 20.5 2 28z" />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M12 34l8 8m32-8l-8 8M24 14h16" />
  </SvgIcon>
)

export const KNCarriersIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <circle cx="9.12" cy="51.19" r="5.44" fill="none" strokeWidth={strokeWidth} stroke="currentColor" />
    <circle cx="47.31" cy="51.19" r="5.44" fill="none" strokeWidth={strokeWidth} stroke="currentColor" />
    <path
      fill="none"
      strokeWidth={strokeWidth}
      d="M42.04 52.14H14.56M3.46 44.25h24.41M25.45 7.37l2.42 4.84v39.92M37.55 36.41h-2.42M32.71 36.41h-2.42M48.44 36.41V25.52M41.18 25.52v10.89h7.26l10.16 2a2.41 2.41 0 0 1 1.94 2.37v11.36h-7.26"
      stroke="currentColor"
    />
    <path
      fill="none"
      strokeWidth={strokeWidth}
      d="M27.87 17.05v8.47h20.57c0-3.33-7.44-8.39-20.57-8.47zM8.08 39.25h11.15v5H8.08z"
      stroke="currentColor"
    />
  </SvgIcon>
)

export const KNTripIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M11 44l-8 8 8 8m42-40l8-8-8-8" />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M60.8 12H12A10 10 0 0 0 2 22a10 10 0 0 0 10 10h40a10 10 0 0 1 10 10 10 10 0 0 1-10 10H3"
    />
  </SvgIcon>
)

export const KNDriverIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <circle cx="32" cy="32" r="30" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M32 22c9.1 0 16.3 4 21.3 4A21.6 21.6 0 0 0 32 10a21.6 21.6 0 0 0-21.3 16c4.9 0 12.2-4 21.3-4zm6 14c-2.6 3.6-1.3 11.1.9 16.9C47.4 50 53.6 43.4 54 34c-4.3 0-14.4-.3-16 2zm-12 0c2.6 3.6 1.3 11.1-.9 16.9C16.6 50 10.3 43.4 10 34c4.3 0 14.4-.3 16 2z"
    />
  </SvgIcon>
)

export const KNHideMenuIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M34 18H6m28 14H6m28 14H6" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M58 20L44 31.994 58 44" />
  </SvgIcon>
)

export const KNRevealIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M30 18h28M30 32h28M30 46h28" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M6 20l14 11.994L6 44" />
  </SvgIcon>
)

export const KNHomeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M2 31L32 5l30 26" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M54 19v-9h-9" />
    <path fill="none" stroke="currentColor" strokeWidth={4} d="M10 32.167V62h14V42h16v20h14V32.083" />
  </SvgIcon>
)

export const KNReleaseNotesIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M2 13h10m-7.5 4.3l5-8.6m0 8.6l-5-8.6M2 31h10m-7.5 4.3l5-8.6m0 8.6l-5-8.6M2 49h10m-7.5 4.3l5-8.6m0 8.6l-5-8.6"
    />
    <path fill="none" stroke="currentColor" strokeWidth={strokeWidth} d="M22 13h40M22 31h40M22 49h40" />
  </SvgIcon>
)

export const KNHelpAndInfoIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      d="M22 21.41c0-3.6 2.758-9.41 10.587-9.41C40.417 12 42 19.035 42 21.41s-.738 5.116-5.07 9.448C32.595 35.19 32 37.44 32 39.5V42"
    />
    <circle cx="32" cy="49" r="3" fill="none" stroke="currentColor" strokeWidth={strokeWidth} />
  </SvgIcon>
)

export const KNShipmentIdIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      strokeWidth="2"
      className="cls-1"
      d="M32.1,62V12m-12,14h24m-34,14a22,22,0,0,0,44,0"
      fill="none"
      stroke="currentColor"
    />
    <circle strokeWidth="5" className="cls-1" cx="32.1" cy="7" r="5" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M9.9,38.7,5.5,45.2" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M58.5,45.2l-4.4-6.5" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M53.9,40l-5.5,3.7" fill="none" stroke="currentColor" />
    <path strokeWidth="5" className="cls-1" d="M15.6,43.7,10.1,40" fill="none" stroke="currentColor" />
  </SvgIcon>
)

export const KNShipmentDescriptionIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      data-name="layer2"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M57 2v60"
      strokeLinejoin="miter"
      strokeLinecap="butt"
    />
    <path
      data-name="layer1"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M7 10v8h4v-4h11.5v36H15v4h20v-4h-7.5V14H39v4h4v-8H7z"
      strokeLinejoin="miter"
      strokeLinecap="butt"
    />
  </SvgIcon>
)

export const KNEditNotificationsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <path
      data-name="layer1"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q11 3 21.5 6.5T582-777l-63 62q-10-2-19-3.5t-20-1.5q-66 0-113 47t-47 113v280h320v-84l80-80v164h80v80H160Zm320-240Zm0 0v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T823-660L603-440H480Zm300-263-37-37 37 37ZM540-500h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"
      strokeLinejoin="miter"
      strokeLinecap="butt"
    />
  </SvgIcon>
)

export const KNStarsMarkerIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon width="40" height="43" viewBox="0 0 40 43" stroke="none" {...props}>
    <path
      d="M8.33331 15C8.33331 8.55001 13.55 3.33334 20 3.33334C26.45 3.33334 31.6666 8.55001 31.6666 15C31.6666 23.75 20 36.6667 20 36.6667C20 36.6667 8.33331 23.75 8.33331 15ZM15.8333 15C15.8333 17.3 17.7 19.1667 20 19.1667C22.3 19.1667 24.1666 17.3 24.1666 15C24.1666 12.7 22.3 10.8333 20 10.8333C17.7 10.8333 15.8333 12.7 15.8333 15Z"
      fill="currentColor"
    />
    <path
      d="M24.25 35.5C24.25 32.05 27.0437 29.25 30.4937 29.25C33.95 29.25 36.75 32.05 36.75 35.5C36.75 38.95 33.95 41.75 30.4937 41.75C27.0437 41.75 24.25 38.95 24.25 35.5ZM30.5 37.6562L33.1438 39.25L32.4438 36.2375L34.775 34.2188L31.7 33.9563L30.5 31.125L29.3 33.9625L26.225 34.225L28.5563 36.2437L27.8563 39.25L30.5 37.6562Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export const KNCheckMarkerIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon width="40" height="42" viewBox="0 0 40 42" stroke="none" {...props}>
    <path
      d="M8.33331 15C8.33331 8.54999 13.55 3.33333 20 3.33333C26.45 3.33333 31.6666 8.54999 31.6666 15C31.6666 23.75 20 36.6667 20 36.6667C20 36.6667 8.33331 23.75 8.33331 15ZM15.8333 15C15.8333 17.3 17.7 19.1667 20 19.1667C22.3 19.1667 24.1666 17.3 24.1666 15C24.1666 12.7 22.3 10.8333 20 10.8333C17.7 10.8333 15.8333 12.7 15.8333 15Z"
      fill="currentColor"
    />
    <path
      d="M24.25 34.5C24.25 31.05 27.05 28.25 30.5 28.25C33.95 28.25 36.75 31.05 36.75 34.5C36.75 37.95 33.95 40.75 30.5 40.75C27.05 40.75 24.25 37.95 24.25 34.5ZM26.125 34.5L29.25 37.625L34.875 32L33.9937 31.1125L29.25 35.8563L27.0062 33.6188L26.125 34.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export const KNTemperatureIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" stroke="none" {...props}>
    <path
      d="M25 8.33333V21.6667C27.0167 23.1833 28.3334 25.6167 28.3334 28.3333C28.3334 32.9333 24.6 36.6667 20 36.6667C15.4 36.6667 11.6667 32.9333 11.6667 28.3333C11.6667 25.6167 12.9834 23.1833 15 21.6667V8.33333C15 5.56667 17.2334 3.33333 20 3.33333C22.7667 3.33333 25 5.56667 25 8.33333ZM20 6.66667C19.0834 6.66667 18.3334 7.41667 18.3334 8.33333V18.3333H21.6667V15H20V13.3333H21.6667V10H20V8.33333H21.6667C21.6667 7.41667 20.9167 6.66667 20 6.66667Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export const KNStatusChangeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon width="40" height="43" viewBox="0 0 40 43" stroke="none" {...props}>
    <path
      d="M16.0125 5.93509C10.2959 6.69342 5.87712 11.5934 5.87712 17.4997C5.87712 23.4059 10.2959 28.3059 16.0125 29.0643V32.0101C8.67712 31.2372 2.96045 25.0247 2.96045 17.4997C2.96045 9.97467 8.67712 3.76217 16.0125 2.98926V5.93509ZM18.9875 32.0098V29.064C21.1167 28.8015 23.0562 27.9556 24.675 26.7015L26.7458 28.7869C24.5875 30.5515 21.9187 31.7181 18.9875 32.0098ZM29.0937 16.0412H32.0396C31.7479 13.1099 30.5812 10.4412 28.8167 8.28285L26.7312 10.3683C27.9854 11.9724 28.8312 13.912 29.0937 16.0412ZM18.9875 2.98926C21.9042 3.28092 24.5875 4.44759 26.7458 6.21217L24.675 8.29759C23.0562 7.04342 21.1167 6.19759 18.9875 5.93509V2.98926ZM26.7312 24.6458L28.8167 26.7166C30.5812 24.5583 31.7479 21.8895 32.0396 18.9583H29.0937C28.8312 21.0875 27.9854 23.0271 26.7312 24.6458ZM23.3333 16.1875C23.3333 12.5562 20.5625 10.2083 17.5 10.2083C14.4375 10.2083 11.6667 12.5562 11.6667 16.1875C11.6667 18.6083 13.6062 21.4812 17.5 24.7916C21.3937 21.4812 23.3333 18.6083 23.3333 16.1875ZM17.5 17.5C16.6396 17.5 15.9396 16.8 15.9396 15.9395C15.9396 15.0791 16.6396 14.3791 17.5 14.3791C18.3604 14.3791 19.0604 15.0791 19.0604 15.9395C19.0604 16.8 18.3604 17.5 17.5 17.5Z"
      fill="currentColor"
    />
    <path
      d="M25.25 34.5C25.25 31.05 28.0437 28.25 31.4937 28.25C34.95 28.25 37.75 31.05 37.75 34.5C37.75 37.95 34.95 40.75 31.4937 40.75C28.0437 40.75 25.25 37.95 25.25 34.5ZM31.5 36.6562L34.1438 38.25L33.4438 35.2375L35.775 33.2188L32.7 32.9563L31.5 30.125L30.3 32.9625L27.225 33.225L29.5563 35.2437L28.8563 38.25L31.5 36.6562Z"
      fill="currentColor"
    />
  </SvgIcon>
)
