import { useReducer, Dispatch, Reducer, createContext, ReactElement } from 'react'

const createStore = <S extends object, A extends object>(reducer: Reducer<S, A>, initialState: S): { StoreContext: React.Context<[S, Dispatch<A>]>, StoreProvider: (props: any) => ReactElement } => {
  const contextValues: [S, Dispatch<A>] = [
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (action): void => {},
  ]

  const StoreContext = createContext<[S, Dispatch<A>]>(contextValues)

  const StoreProvider = (props: any): ReactElement => {
    const store = useReducer(reducer, initialState)
    return (
      <StoreContext.Provider value={store}>
        {props.children}
      </StoreContext.Provider>
    )
  }

  return { StoreContext, StoreProvider }
}

export default createStore
