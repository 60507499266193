import { memo, useContext, useEffect, ReactElement, useState } from 'react'
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next'

// @mui material imports //
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

// KN Components //
import { KNProfileIcon } from 'components/KN_Molecules/KNIcon/KNIcon'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import AccountInformation from './AccountInformation'
import Notifications from './Notifications'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'
import Timezone from './Timezone'

// Functional //
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { UserContext } from 'context/authentication/UserContext'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { getUserPreferences, storeLanguage } from 'screens/UserManager/UserManager.service'
import { getLanguages } from './ProfilePage.helpers'

// Types //
import { UserPreferencesData } from 'screens/UserManager/UserManager.types'

const ProfilePage = (): ReactElement | null => {
  const [userPreferences, setUserPreferences] = useState<UserPreferencesData>()
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const company = getSelectedCompany()

  if (!user || !company) {
    return null
  }

  useEffect(() => {
    const getUserPreferencesData = async (): Promise<void> => {
      setUserPreferences(await getUserPreferences())
    }
    analyticsPageView('polestar/profile')
    analyticsPageView('shipmentinsight/profile')
    void getUserPreferencesData()
  }, [])

  return (
    <Container data-test="profile-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={16}
            sx={{
              p: 2,
              backgroundColor: 'primary.main',
            }}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <KNProfileIcon fontSize="large" sx={{ mr: 1, color: 'primary.light' }} />
              <Box>
                <KNTypography color="white" fontSize="0.75rem" fontWeight={500}>
                  {t('screens.shared.profile.profile')}
                </KNTypography>
                <KNTypography color="white" fontWeight={500}>
                  {`${user.displayName ?? user.email ?? user.uid} — ${company.displayName}`}
                </KNTypography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <AccountInformation />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Paper
              elevation={8}
              sx={{
                padding: 2,
              }}
            >
              <KNTypography mb={1} fontWeight={500}>
                {t('screens.shared.profile.preferences')}
              </KNTypography>
              <Stack direction="column" spacing={2}>
                <Box>
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('screens.shared.profile.language')}
                  </KNTypography>
                  <KNAdvancedDropdown
                    options={getLanguages().map((language) => {
                      return {
                        label: language.label,
                        value: language.languageCode,
                      }
                    })}
                    value={i18n.language}
                    onChange={async (event): Promise<void> => {
                      const newLanguage = event.target.value as string
                      await i18n.changeLanguage(newLanguage)
                      await storeLanguage({
                        language: i18n.language.toUpperCase(),
                      })
                      localStorage.setItem('userLanguage', newLanguage)
                      analyticsEvent('polestar_change_language', [newLanguage])
                      analyticsEvent('shipmentinsight_language_change', [newLanguage])
                    }}
                  />
                </Box>
                {company.type == 'Customer' && <Timezone timeZoneId={userPreferences?.timeZoneId} />}
              </Stack>
            </Paper>
            {company.type == 'Carrier' && <Notifications />}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default memo(ProfilePage)
