import pxToRem from 'assets/theme/functions/pxToRem'

const accordionDetails = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(0)} ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)} `,
    },
  },
}

export default accordionDetails
