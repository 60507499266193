import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

// KN imports
import KNTooltipButton from 'components/KN_Molecules/KNTooltipButton/KNTooltipButton'
import { KNTooltipButtonHandle } from 'components/KN_Molecules/KNTooltipButton/types'
import KNClipboardFieldProps from './types'

const KNClipboardField: React.FC<KNClipboardFieldProps> = ({
  value,
  label,
  size,
  loading,
  onAction,
  sx,
}) => {
  const { t } = useTranslation()
  const buttonRef = useRef<KNTooltipButtonHandle>(null)
  const handleCopy = async (): Promise<void> => {
    buttonRef.current?.showTooltip()
    await navigator.clipboard.writeText(value as string)
    onAction?.()
  }

  return (
    <Stack
      spacing={{ xs: 0.5, sm: 1 }}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'start', sm: 'center' }}
      sx={sx}
    >
      <TextField
        label={label}
        value={value}
        size={size}
        fullWidth
        disabled={loading}
        InputProps={{
          readOnly: true,
        }}
      />
      <KNTooltipButton
        ref={buttonRef}
        tooltip={t('form.copied')}
        variant="contained"
        size="small"
        color="secondary"
        loading={loading}
        onClick={handleCopy}
      >
        {t('form.copy')}
      </KNTooltipButton>
    </Stack>
  )
}

export default KNClipboardField
