import { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// @mui imports
import MenuItem from '@mui/material/MenuItem'

import { KNMenuLinkProps } from './types'

const KNMenuLink = ({ label, icon, to, onClick }: KNMenuLinkProps): ReactElement => (
  <MenuItem component={RouterLink} to={to} onClick={onClick}>
    {icon}
  </MenuItem>
)

export default KNMenuLink
