import { Fragment, useMemo, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// KN imports
import { zonedDate } from 'global/helpers/dateFormatters'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import { MessageData } from './MessageManager.types'

interface MessageTableProps {
  messages: MessageData[]
}

const MessageTable = ({ messages }: MessageTableProps): ReactElement => {
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        name: 'uuid',
        label: t('screens.cs.message_manager.table.uuid'),
        sx: { width: '20rem' },
      },
      {
        name: 'date',
        label: t('screens.cs.message_manager.table.date'),
        getValue: (row: MessageData) => zonedDate(row.date, 'full') ?? '',
        sx: { width: '10rem' },
      },
      {
        name: 'errors',
        label: t('screens.cs.message_manager.table.errors'),
        getValue: (row: MessageData) => (
          <>
            {row.errors?.map((error, index) => (
              <Fragment key={index}>
                {index != 0 && <br />}
                {error}
              </Fragment>
            ))}
          </>
        ),
      },
    ],
    []
  )

  return (
    <KNDataTable
      columns={columns}
      data={messages}
      sx={{
        marginX: -2,
      }}
    />
  )
}

export default MessageTable
