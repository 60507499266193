export const isMeaBranch = (logicalSender: string): boolean => {
  const meaBranches = [
    'AEAJM',
    'OMMCT',
    'ZAJNB',
    'QADOH',
    'EGCAI',
    'SADMM',
    'IQBSR',
    'IQERB',
    'TZDAR',
    'KENBO',
    'TRIST',
    'TRBTZ',
    'TRANK',
    'TRIZM',
  ]
  for (const branch of meaBranches) {
    if (logicalSender.startsWith(branch)) {
      return true
    }
  }
  return false
}
