import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { set as dateFnsSet } from 'date-fns'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import WarningIcon from '@mui/icons-material/Warning'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import i18n from 'i18n'
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate } from 'global/helpers/dateFormatters'
import { getRouteName } from 'global/helpers/activeRoute'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import StatusUpdateFields from './StatusUpdateFields'
import { updateStopStatus } from './StatusManager.service'
import { getUpdatedStopForUpdateStopStatus } from './StatusManager.helpers'
import { LegData, StopData } from 'screens/TripDetails/TripDetails.types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'

interface StatusUpdateDialogPayload {
  trip: TripData
  leg: LegData
  stop: StopData
  weblinkToken?: string
}

interface StatusUpdateDialogProps {
  payload: StatusUpdateDialogPayload
  open: boolean
  onAction: (updatedStops: StopData[]) => void
  onClose: () => void
}

export interface StatusUpdateFormValues {
  wayPointType: 'PUP' | 'CUS' | 'DEL'
  statusId: string
  statusCode: string
  reasonCode?: string
  createdAt?: string
  createdAtDate?: Date | null
  createdAtTime?: Date | null
  shipmentNumber: string
  comment?: string
  signee?: string
  paperless?: boolean
  signature?: string
}

export const getStatusUpdateLabel = (type: string): string => {
  switch (type) {
    case 'PUP':
      return i18n.t('screens.cs.trip_details.update_status.pickup_status')
    case 'CUS':
      return i18n.t('screens.cs.trip_details.update_status.customs_status')
    case 'DEL':
      return i18n.t('screens.cs.trip_details.update_status.delivery_status')
    default:
      return i18n.t('screens.cs.trip_details.update_status.status')
  }
}

export const getDefaultStatus = (type: string): string => {
  switch (type) {
    case 'PUP':
      return '0500'
    case 'DEL':
      return '3000'
    default:
      return ''
  }
}

export const rewriteTimezone = (chosenDate: Date, chosenTime: Date, timezoneBase: string): string => {
  const chosenDatetime = dateFnsSet(chosenDate, {
    hours: chosenTime.getHours(),
    minutes: chosenTime.getMinutes(),
    seconds: 0,
  })
  const chosenDatetimeWithoutOffset = zonedDate(chosenDatetime, 'iso_no_tz') ?? ''
  const stopDateOffset = zonedDate(timezoneBase, 'offset') ?? 'Z'
  return `${chosenDatetimeWithoutOffset}${stopDateOffset}`
}

export const getTimezoneBase = (stop: StopData, leg: LegData) => {
  if (stop.type === 'CUS') {
    return leg.wayPoints.filter((stop) => stop.type === 'PUP')?.[0].earlyDateTime
  }
  return stop.earlyDateTime
}

const StatusUpdateDialog = ({ payload, open, onAction, onClose }: StatusUpdateDialogProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const location = useLocation()
  const form = useForm<StatusUpdateFormValues>({
    criteriaMode: 'all',
  })
  const { handleSubmit, reset, formState, setError } = form

  useEffect(() => {
    reset(
      processDefaultValues({
        wayPointType: payload.stop.type,
        statusId: getDefaultStatus(payload.stop.type),
        createdAtDate: payload.stop.earlyDateTime ? new Date(payload.stop.earlyDateTime) : new Date(),
        createdAtTime: null,
        shipmentNumber: payload.leg.shipmentNumber,
        paperless: isMobile && payload.weblinkToken && payload.leg.paperlessAvailable,
      })
    )
  }, [payload])

  const onSubmit: SubmitHandler<StatusUpdateFormValues> = async (data: StatusUpdateFormValues) => {
    // NOTE: rewrites input timezone to stop's timezone
    if (data.createdAtDate && data.createdAtTime) {
      data.createdAt = rewriteTimezone(
        data.createdAtDate,
        data.createdAtTime,
        getTimezoneBase(payload.stop, payload.leg)
      )
    }
    try {
      await updateStopStatus(payload.trip.entityId, payload.stop.wayPointCid, data, payload.weblinkToken)
      onAction([getUpdatedStopForUpdateStopStatus(payload.stop, data)])
      analyticsEvent('polestar_cs_status_updated', [
        getRouteName(location.pathname),
        data.paperless ? 'paperless' : 'regular',
      ])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <FormProvider {...form}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={`${payload.leg.shipmentNumber} — ${getStatusUpdateLabel(payload.stop.type)}`}
        actions={
          <>
            <KNLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('screens.cs.trip_details.update_status.update_status')}
            </KNLoadingButton>
          </>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <DialogContentText component="div" mb={2}>
          <KNCaption color="warning" icon={<WarningIcon />}>
            {t('screens.cs.trip_details.update_status.warning')}
          </KNCaption>
          {payload.stop.type === 'CUS' && (
            <KNCaption color="warning" icon={<WarningIcon />}>
              {t('screens.cs.trip_details.update_status.customs_local_time')}
            </KNCaption>
          )}
        </DialogContentText>
        <StatusUpdateFields
          payload={{
            tripId: payload.trip.entityId,
            stop: payload.stop,
            leg: payload.leg,
            weblinkToken: payload.weblinkToken,
          }}
        />
      </KNDialog>
    </FormProvider>
  )
}

export default StatusUpdateDialog
