import i18n from 'i18n'

export const loginTranslations = (): Record<any, any> => {
  const login = 'shared.login'
  return {
    translation: {
      carrierSolution: i18n.t(`${login}.carrierSolution`),
      applicationDescription: i18n.t(`${login}.applicationDescription`),
      email: i18n.t(`${login}.email`),
      password: i18n.t(`${login}.password`),
      loading: i18n.t(`${login}.loading`),
      forgottenPassword: i18n.t(`${login}.forgottenPassword`),
      loginButton: i18n.t(`${login}.loginButton`),
      resetPasswordButton: i18n.t(`${login}.resetPasswordButton`),
      resetDescription: i18n.t(`${login}.resetDescription`),
      backButton: i18n.t(`${login}.backButton`),
      resetSuccessful: i18n.t(`${login}.resetSuccessful`),
      resetSuccessfulMsg: i18n.t(`${login}.resetSuccessfulMsg`),
      resetFail: i18n.t(`${login}.resetFail`),
      resetFailMsg: i18n.t(`${login}.resetFailMsg`),
    },
  }
}
