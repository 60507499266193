import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Driver, PopoverDOM, driver } from 'driver.js'
import 'driver.js/dist/driver.css'

// @mui imports //
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import HelpIcon from '@mui/icons-material/Help'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports //
import SupportRequestDialog from './SupportRequestDialog'

// Functional //
import { getRouteName } from 'global/helpers/activeRoute'
import { sleep } from 'global/helpers/sleep'
import { getUserPreferences, saveUserPreferences } from 'screens/UserManager/UserManager.service'
import { getWeblinkToken } from 'screens/Weblink/Weblink.service'
import { getGuideName, getGuideSteps } from './Support.helpers'
import { analyticsEvent } from 'global/helpers/analytics'

// Types //
import { WelcomeGuideStatus } from 'screens/UserManager/UserManager.types'
import { SupportProps } from './Support.types'

const FloatingButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '2rem',
    minWidth: '2rem',
    padding: '0 !important',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  [theme.breakpoints.up('sm')]: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}))

const Support = ({ moduleName }: SupportProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const { jwt } = useParams<{ jwt: string }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [activeGuide, setActiveGuide] = useState<Driver>()
  const [weblinkToken, setWeblinkToken] = useState<string>()
  const [supportRequestDialogOpen, setSupportRequestDialogOpen] = useState(false)

  const fetchWeblinkToken = async (): Promise<void> => {
    if (jwt) {
      try {
        const { token } = await getWeblinkToken(jwt)
        setWeblinkToken(token)
      } catch (error) {
        //
      }
    }
  }

  const fetchGuideState = async (): Promise<void> => {
    const guideName = getGuideName(getRouteName(location.pathname))
    if (!guideName) {
      return
    }

    const guide = driver({
      showProgress: true,
      showButtons: ['next', 'close'],
      nextBtnText: t('general.next'),
      doneBtnText: t('general.close'),
      progressText: '{{current}} / {{total}}',
      steps: getGuideSteps(guideName),
      onDestroyed: async () => {
        try {
          await saveUserPreferences({ [guideName]: WelcomeGuideStatus.DONE })
        } catch {
          //
        }
      },
    })
    setActiveGuide(guide)

    try {
      await sleep(2000)
      const preferences = await getUserPreferences()
      if (preferences[guideName] === WelcomeGuideStatus.PENDING) {
        guide.drive()
      }
    } catch {
      //
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchGuideState()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchWeblinkToken()
  }, [])

  return (
    <>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={1}
        sx={{
          position: 'fixed',
          zIndex: 1200,
          ...(isMobile
            ? {
                bottom: '4rem',
                right: 0,
              }
            : {
                bottom: '4rem',
                right: 0,
                transform: 'rotate(90deg)',
                transformOrigin: '100% 0',
              }),
        }}
      >
        {activeGuide && (
          <FloatingButton
            data-test="guide-button"
            variant="contained"
            size="small"
            color="primary"
            startIcon={isMobile && <HelpIcon />}
            onClick={(): void => {
              analyticsEvent('shipmentinsight_guide_click')
              activeGuide.drive()
            }}
          >
            {!isMobile && t('shared.guides.guide')}
          </FloatingButton>
        )}
        <FloatingButton
          data-test="feedback-support-button"
          data-guide="feedback"
          variant="contained"
          size="small"
          color="primary"
          startIcon={isMobile && <QuestionAnswerIcon />}
          onClick={(): void => setSupportRequestDialogOpen(true)}
        >
          {!isMobile && t('shared.support.feedback_support')}
        </FloatingButton>
      </Stack>

      <SupportRequestDialog
        moduleName={moduleName}
        payload={{
          weblinkToken,
        }}
        open={supportRequestDialogOpen}
        onAction={(): void => {
          setSupportRequestDialogOpen(false)
        }}
        onClose={(): void => {
          setSupportRequestDialogOpen(false)
        }}
      />
    </>
  )
}

export default Support
