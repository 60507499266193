import { useCallback, ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

// KN imports
import KNMenu from 'components/KN_Molecules/KNMenu/KNMenu'
import KNMenuItem from 'components/KN_Molecules/KNMenu/KNMenuItem'
import KNMenuLink from 'components/KN_Molecules/KNMenu/KNMenuLink'
import useMenuState from 'components/KN_Molecules/KNMenu/useMenuState'
import { KNDataTableMenuProps, KNDataTableAction } from './types'

const KNDataTableMenu = <T extends object>({
  row,
  label,
  icon,
  actions,
  rightAligned,
}: KNDataTableMenuProps<T>): ReactElement => {
  const {
    anchorEl: actionsAnchorEl,
    open: actionsOpen,
    handleClick: handleActionsClick,
    handleClose: handleActionsClose,
  } = useMenuState()

  const handlePropagation = useCallback((event) => event.stopPropagation(), [])

  return (
    <Box onClick={handlePropagation}>
      <Tooltip title={label} enterDelay={500} placement="top" arrow>
        <IconButton
          onClick={handleActionsClick}
          size="small"
          className="hover-opacity"
          sx={{ color: 'primary.main' }}
        >
          {icon ?? <MoreHorizIcon />}
        </IconButton>
      </Tooltip>
      <KNMenu
        open={actionsOpen}
        onClose={handleActionsClose}
        anchorEl={actionsAnchorEl}
        rightAligned={rightAligned}
      >
        {actions.map((action) => {
          if (action.getLink) {
            return (
              <KNMenuLink
                key={action.name}
                to={action.getLink(row)}
                label={action.label}
                onClick={() => {
                  action.onClick?.(row)
                }}
              />
            )
          }
          return (
            <KNMenuItem
              key={action.name}
              label={action.label}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                action.onClick?.(row)
              }}
            />
          )
        })}
      </KNMenu>
    </Box>
  )
}

export default KNDataTableMenu
