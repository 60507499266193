import { minFileSize } from 'screens/StatusManager/StatusManager.service'

const maxWidth = 2400
const maxHeight = 2400
const quality = 0.5

const fitConstraints = (width: number, height: number, maxWidth: number, maxHeight: number): [number, number] => {
  let newWidth = width
  let newHeight = height
  if (width > height) {
    if (width > maxWidth) {
      newWidth = maxWidth
      newHeight = height * (maxWidth / width)
    }
  } else {
    if (height > maxHeight) {
      newHeight = maxHeight
      newWidth = width * (maxHeight / height)
    }
  }
  return [Math.round(newWidth), Math.round(newHeight)]
}

export const resizeImage = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const image = new Image()
        image.addEventListener('load', () => {
          const [newWidth, newHeight] = fitConstraints(image.naturalWidth, image.naturalHeight, maxWidth, maxHeight)
          const canvas = document.createElement('canvas')
          canvas.width = newWidth
          canvas.height = newHeight
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const ctx = canvas.getContext('2d')!
          ctx.drawImage(image, 0, 0, newWidth, newHeight)
          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: file.type })
              // NOTE: sometimes resized file can turn out smaller than minimal file size accepted
              // ...so return original file instead
              resolve(resizedFile.size < minFileSize ? file : resizedFile)
            } else {
              reject(new Error('Invalid blob'))
            }
          }, file.type, quality)
        })
        image.src = reader.result as string
      }, false)
      reader.readAsDataURL(file)
    } catch (error) {
      reject(error)
    }
  })
}
