import { useContext } from 'react'
import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'

// Functional
import { getDateFormat } from 'global/helpers/dateFormatters'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Types
import { BruceBannerInputProps, BruceBannerMessageProps, BruceBannerType } from './BruceBanner.type'

export const messageValidator = (
  messages: BruceBannerInputProps[],
  moduleName: string,
  userTimezone: string
): BruceBannerMessageProps | undefined => {
  const readMessage = localStorage.getItem('bannerMessageRead')
  const timestampNow = format(new Date(), 'T')
  const validatedCheckedMessage = messages.find((message) => message.updateDate === Number(readMessage))
  if (validatedCheckedMessage) {
    return undefined
  } else {
    const activeMessage = messages.find((message) => message.visibleUntil > Number(timestampNow))
    if (moduleName === 'global' && activeMessage?.type !== BruceBannerType.downtime) return undefined
    if (activeMessage) {
      return {
        ...activeMessage,
        pastToday: Number(activeMessage.updateDate) < Number(timestampNow),
        visible: activeMessage.visibleUntil > Number(timestampNow),
        visualLiveDate: activeMessage?.liveDate
          ? format(utcToZonedTime(new Date(activeMessage.liveDate), userTimezone), getDateFormat('full_timezoned'))
          : 0,
        visualDate: format(
          utcToZonedTime(new Date(activeMessage?.updateDate), userTimezone),
          getDateFormat('full_timezoned')
        ),
      }
    }
  }
}
