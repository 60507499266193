import { ReactElement, ReactNode } from 'react'
import reactStringReplace from 'react-string-replace'

export const hasHighlights = (text?: string, highlights: string[] = []): boolean => {
  if (!text) {
    return false
  }
  if (highlights.length === 0) {
    return false
  }
  const highlightsRegexp = new RegExp(`(${highlights.join('|')})`, 'gi')
  return text.search(highlightsRegexp) >= 0
}

export const replaceHighlights = (text?: string, highlights: string[] = []): ReactElement => {
  if (!text) {
    return <></>
  }
  if (highlights.length === 0) {
    return <>{text}</>
  }
  const highlightsRegexp = new RegExp(`(${highlights.join('|')})`, 'gi')
  const tagRegexp = new RegExp('<em>([^<]+)</em>', 'gi')
  return (
    <>
      {reactStringReplace(text.replaceAll(highlightsRegexp, '<em>$1</em>'), tagRegexp, (match: string, index: number) => (
        <em>{match}</em>
      ))}
    </>
  )
}
