import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'

export interface DriverFiltersValues {
  keywords?: string[]
}

export interface DriverListState {
  backgroundLoading: boolean
  filters: DriverFiltersValues
  filtersDirty: boolean
}

export type DriverListAction =
  | { type: 'setBackgroundLoading'; payload: boolean }
  | { type: 'setFilters'; payload: DriverFiltersValues }
  | { type: 'resetFilters' }

const initialFilters: DriverFiltersValues = {
  keywords: [],
}

const initialState: DriverListState = {
  backgroundLoading: false,
  filters: initialFilters,
  filtersDirty: false,
}

const reducer = (state: DriverListState, action: DriverListAction): DriverListState => {
  switch (action.type) {
    case 'setBackgroundLoading':
      return {
        ...state,
        backgroundLoading: action.payload,
      }
    case 'setFilters':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        filtersDirty: JSON.stringify({ ...state.filters, ...action.payload }) !== JSON.stringify(initialFilters),
      }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as DriverListContext, StoreProvider as DriverListProvider }
