import { ReactElement, useState } from 'react'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'

// KN components //
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'

// Modules //
import CreateUserDialog from './CreateUserDialog'
import GrantRoleDialog from './GrantRoleDialog'
import ListItem from '@mui/material/ListItem'

// Functional //
import { regexEmail } from 'global/helpers/validators'
import { getOtherUsersOptions, getUserRoles } from './PlaygroundForms.helpers'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import { FormUserProps, UserProps } from 'screens/Playground/Playground.types'

const FormUser = ({
  control,
  setValue,
  getValues,
  users,
  resetUser,
  filteredUsersOptions,
  selectedCompany,
}: FormUserProps): ReactElement => {
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState<boolean>(false)
  const [grantRoleDialogOpen, setGrantRoleDialogOpen] = useState<boolean>(false)
  const [userCreated, setUserCreated] = useState<boolean>(false)
  const [userRightsGranted, setUserRightsGranted] = useState<boolean>(false)

  const { translation } = playgroundTranslations()

  return (
    <Stack spacing={2} mt={1}>
      <KNTypography variant="textLG_SB">{translation.userData}</KNTypography>
      <Box>
        <Accordion
          sx={{ maxWidth: 'sm', '&:after': { left: 0 }, '.MuiAccordionSummary-root': { padding: 0 } }}
          disableGutters
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <KNTypography variant="textMD_SB" color="primary.light">
              {translation.userAccessListText}
            </KNTypography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ color: 'primary.light' }}>
              {filteredUsersOptions.map((user) => (
                <ListItem key={user.value} disablePadding>
                  {user.label}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        {/* KEEP THE DROPDOWN FOR THE FUTURE
          <KNFormDropdown
            sx={{
              maxWidth: 'sm',
            }}
            name="user.email"
            label={translation.userEmailLabel}
            control={control}
            options={filteredUsersOptions}
            onSetValue={(event): void => {
              setUserCreated(false)
              setUserRightsGranted(false)
              const chosenUser = users.find((user) => user.email === event.target.value)
              const userRole = chosenUser?.roles[selectedCompany.cid]
              setValue('user.role', userRole)
            }}
          /> */}
      </Box>

      <Box mt={1}>
        <Box>
          <KNTypography variant="textMD_SB" color="primary.light">
            {translation.userExistingOutsideCompany}
          </KNTypography>
        </Box>
        <Box>
          <KNTypography variant="textMD" color="primary.light">
            {translation.userExistingOutsideCompanyText}
          </KNTypography>
          <Box mt={1}>
            <KNButton
              color="primary"
              variant="contained"
              size="small"
              onClick={(): void => {
                setGrantRoleDialogOpen(true)
              }}
            >
              {translation.userGrantRightsButton}
            </KNButton>
          </Box>
        </Box>
        <Divider
          sx={{
            mt: 3,
            maxWidth: 'sm',
          }}
        />
      </Box>

      <Box mt={1}>
        <KNTypography variant="textMD" color="primary.light">
          {translation.userCreateNewText}
        </KNTypography>
        <Box mt={1}>
          <KNButton
            sx={{ mr: 2 }}
            color="primary"
            variant="contained"
            size="small"
            onClick={(): void => {
              setCreateUserDialogOpen(true)
            }}
          >
            {userCreated ? translation.userEditButton : translation.userCreateButton}
          </KNButton>
        </Box>
      </Box>

      {(userCreated || userRightsGranted) && (
        <>
          <Box mt={1}>
            <KNFormText
              name="user.email"
              label={translation.userEmailLabel}
              sx={{
                maxWidth: 'sm',
              }}
              rules={{
                pattern: {
                  value: regexEmail,
                  message: translation.invalidEmail,
                },
              }}
              disabled
              control={control}
            />
          </Box>
          {userCreated && (
            <Box mt={1}>
              <KNFormText
                name="user.displayName"
                label={translation.userDisplayNameLabel}
                sx={{
                  maxWidth: 'sm',
                }}
                disabled
                control={control}
              />
            </Box>
          )}
          <Box mt={1}>
            <KNFormRadioGroup
              label={translation.userRoleLabel}
              name="user.role"
              disabled
              control={control}
              options={getUserRoles()}
            />
          </Box>
          <Box mt={1}>
            <KNButton
              sx={{ mr: 2 }}
              color="error"
              variant="contained"
              size="small"
              onClick={(): void => {
                resetUser()
                setUserCreated(false)
                setUserRightsGranted(false)
              }}
            >
              {translation.userRemoveButton}
            </KNButton>
          </Box>
        </>
      )}
      {createUserDialogOpen && (
        <CreateUserDialog
          userData={getValues('user')}
          dialogOpen={createUserDialogOpen}
          handleClose={(): void => {
            setCreateUserDialogOpen(false)
          }}
          handleSave={(data: UserProps): void => {
            setValue('user', data)
            setUserCreated(true)
            setUserRightsGranted(false)
          }}
        />
      )}
      {grantRoleDialogOpen && (
        <GrantRoleDialog
          usersOptions={getOtherUsersOptions(users, selectedCompany)}
          dialogOpen={grantRoleDialogOpen}
          handleClose={(): void => {
            setGrantRoleDialogOpen(false)
          }}
          handleSave={(data: UserProps): void => {
            setValue('user', data)
            setUserRightsGranted(true)
            setUserCreated(false)
          }}
        />
      )}
    </Stack>
  )
}

export default FormUser
