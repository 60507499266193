import { useState, useEffect, useCallback, ReactElement } from 'react'

import RedirectDialog from './RedirectDialog'

const EtrucknowRedirect = (): ReactElement | null => {
  const [redirectDialogOpen, setRedirectDialogOpen] = useState(false)
  const isEtrucknow = window.location.hostname.includes('etrucknow.com')

  if (!isEtrucknow) {
    return null
  }

  useEffect(() => {
    setRedirectDialogOpen(true)
  }, [])

  return (
    <RedirectDialog
      open={redirectDialogOpen}
    />
  )
}

export default EtrucknowRedirect
