import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import KNSignature from 'components/KN_Molecules/KNSignature/KNSignature'
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormSignatureProps } from './types'

const KNFormSignature = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  size,
  sx,
  disabled,
}: KNFormSignatureProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting || disabled}>
        {label && <FormLabel sx={{ marginBottom: 1 }}>{label}</FormLabel>}
        <KNSignature onChange={(output) => onChange(output)} />
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormSignature
