import { useContext, ReactElement, Fragment } from 'react'

// @mui imports
import { SxProps } from '@mui/system'
import Stack from '@mui/material/Stack'

// KN imports
import { TripListContext } from 'context/trips/TripListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'

export interface PrimaryActionsProps {
  actions: KNDataCardAction[]
  sx?: SxProps
}

const PrimaryActions = ({ actions, sx }: PrimaryActionsProps): ReactElement => {
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  return (
    <Stack
      data-test="actions-container"
      data-guide="primary-actions"
      spacing={1}
      direction={{ xs: 'column-reverse', sm: 'row' }}
      justifyContent="flex-end"
      alignItems={{ xs: 'stretch', sm: 'center' }}
      sx={sx}
    >
      {actions.map((action: KNDataCardAction) => (
        <KNButton
          dataAttribute={action.label.replaceAll(' ', '-')}
          key={action.label}
          variant={action.variant ?? 'contained'}
          color={action.color ?? 'primary'}
          size="small"
          startIcon={action.icon}
          onClick={(): void => action.handler()}
          disabled={tripListState.backgroundLoading}
        >
          {action.label}
        </KNButton>
      ))}
    </Stack>
  )
}

export default PrimaryActions
