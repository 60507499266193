import boxShadows from 'assets/theme/base/boxShadows'
import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import pxToRem from 'assets/theme/functions/pxToRem'

const { transparent, primary, secondary, white } = colors
const { size } = typography
const { buttonBoxShadow } = boxShadows

const outlined = {
  base: {
    minHeight: pxToRem(40),
    color: primary.main,
    borderColor: primary.light,
    padding: `${pxToRem(12)} ${pxToRem(24)}`,

    '&:hover': {
      backgroundColor: transparent.main,
      boxShadow: buttonBoxShadow.main,
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(8)} ${pxToRem(20)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(16)} ${pxToRem(40)}`,
    fontSize: size.lg,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: transparent.main,
    borderColor: primary.main,
    color: primary.main,

    '&:hover': {
      backgroundColor: primary.focus,
      color: white.main,
    },
  },

  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main,
    color: secondary.main,

    '&:hover': {
      backgroundColor: secondary.focus,
      color: white.main,
    },
  },
}

export default outlined
