import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { parseISO, isThisYear, isSameDay, formatDistance } from 'date-fns'
import i18n from 'i18n'

// @mui imports
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'

// KN imports
import theme from 'assets/theme'
import { sleep } from 'global/helpers/sleep'
import { zonedDate, compactZonedDate } from 'global/helpers/dateFormatters'
import { StopsViewContext } from 'context/trips/StopsViewContext'
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNMap from 'components/KN_Molecules/KNMap/KNMap'
import { getDistance } from 'components/KN_Molecules/KNMap/KNMap.helpers'
import {
  MapMarker,
  MapMarkerState,
  BasicMapMarker,
  LocationGroupMapMarker,
  hasLocationGroup,
} from 'components/KN_Molecules/KNMap/types'
import { groupStopsByLocation } from 'screens/TripDashboard/TripDashboard.helpers'
import { TripData, StopsLocationGroup } from 'screens/TripDashboard/TripDashboard.types'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'
import GroupedStopsCard from './GroupedStopsCard'
import {
  getStopTooltip,
  getDwellLocations,
  getDwellMarkers,
  getGeofenceMarkers,
  getVehicleMarker,
} from './SequenceMapView'
import { getTripGeofences, getTripVehiclePositions } from './TripDetails.service'
import {
  groupStopsBySequence,
  getStopsGroupStateColor,
  positionDataTransformer,
  groupGeoPointsBySpeed,
} from './TripDetails.helpers'
import { getTypeIcon } from './StopHeader'
import {
  LegData,
  StopData,
  TripLogData,
  StopsSequenceGroup,
  Geofence,
  GeoPoint,
  GeoPointsGroup,
} from './TripDetails.types'

export interface LocationsMapViewProps {
  trip: TripData
  legs: LegData[]
  tripLogs: TripLogData[]
  weblinkToken?: string
  onChange: (updatedStops: StopData[]) => void
}

const getLocationMarkers = (groups: StopsLocationGroup[], tripLogs: TripLogData[]): LocationGroupMapMarker[] =>
  groups.reduce((markers: LocationGroupMapMarker[], group: StopsLocationGroup) => {
    if (group.stopLegPairs[0].stop.geoPoint) {
      markers.push({
        id: group.stopLegPairs[0].stop.wayPointCid,
        latitude: group.stopLegPairs[0].stop.geoPoint.latitude,
        longitude: group.stopLegPairs[0].stop.geoPoint.longitude,
        geofence: group.stopLegPairs[0].stop.geofenceRadius ?? 500,
        tooltip: getStopTooltip(group, tripLogs),
        cluster: 'markers',
        locationGroup: group,
      })
    }
    return markers
  }, [])

const LocationsMapView = ({ trip, legs, tripLogs, weblinkToken, onChange }: LocationsMapViewProps): ReactElement => {
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)
  const [groupedStopsData, setGroupedStopsData] = useState<StopsLocationGroup[]>([])
  const [loading, setLoading] = useState(true)
  const [markers, setMarkers] = useState<MapMarker[]>([])
  const [geoPoints, setGeoPoints] = useState<GeoPoint[]>([])
  const [groupedGeoPoints, setGroupedGeoPoints] = useState<GeoPointsGroup[]>([])
  const [centeredGeoPoint, setCenteredGeoPoint] = useState<GeoPoint>()
  const [zoom, setZoom] = useState<number>()

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const groupedStops = groupStopsByLocation(legs)
    setGroupedStopsData(groupedStops)

    try {
      const geofences = await getTripGeofences(trip.entityId, weblinkToken)
      const vehiclePositions = positionDataTransformer(await getTripVehiclePositions(trip.entityId, weblinkToken))
      const groupedVehiclePositions = groupGeoPointsBySpeed(vehiclePositions)
      if (vehiclePositions.length > 0) {
        const dwellLocations = getDwellLocations(vehiclePositions)
        const baseMarkers: MapMarker[] = [
          ...getDwellMarkers(dwellLocations),
          ...getGeofenceMarkers(geofences, tripLogs),
          ...getLocationMarkers(groupedStops, tripLogs),
        ]
        const lastVehiclePosition = vehiclePositions[vehiclePositions.length - 1]
        if (trip.assignedVehicle) {
          baseMarkers.push(getVehicleMarker(trip.assignedVehicle, lastVehiclePosition))
        }
        setMarkers(baseMarkers)
        setGeoPoints(vehiclePositions)
        setGroupedGeoPoints(groupedVehiclePositions)
      } else {
        setMarkers(getLocationMarkers(groupedStops, tripLogs))
      }
    } catch (error) {
      //
    }
    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [legs])

  useEffect(() => {
    setMarkers(markers.map((marker) => {
      marker.state = MapMarkerState.Default
      if (hasLocationGroup(marker)) {
        marker.state = stopsViewState.activeStopsGroup === marker.locationGroup.id ? MapMarkerState.Active : MapMarkerState.Default
      }
      return marker
    }))
  }, [stopsViewState.activeStopsGroup])

  useEffect(() => {
    if (stopsViewState.geoPoint.latitude!== 0) {
      setCenteredGeoPoint(stopsViewState.geoPoint)
      setZoom(15)
    }
  }, [stopsViewState.geoPoint])

  const handleMarkerClick = useCallback((marker: MapMarker, map) => {
    if (hasLocationGroup(marker)) {
      stopsViewDispatch({
        type: 'setActiveStopsGroup',
        payload: marker.locationGroup.id,
      })
    }
  }, [])

  return (
    <Stack spacing={0} sx={{ flexGrow: 1 }}>
      <Paper
        elevation={8}
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          backgroundColor: '#e9ecef',
        }}
      >
        {loading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={64} color="primary" />
          </Stack>
        ) : (
          <KNMap
            markers={markers}
            geoPoints={geoPoints}
            groupedGeoPoints={groupedGeoPoints}
            withHeading
            onMarkerClick={handleMarkerClick}
            center={centeredGeoPoint}
            zoom={zoom}
          />
        )}
      </Paper>
      {groupedStopsData
        .filter((group) => stopsViewState.activeStopsGroup === group.id)
        .map((group) => (
          <GroupedStopsCard
            key={group.id}
            trip={trip}
            group={group}
            weblinkToken={weblinkToken}
            onChange={onChange}
            minimal
          />
        ))}
    </Stack>
  )
}

export default LocationsMapView
