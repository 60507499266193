const norrisNotFoundQuotes = [
  "404: Chuck Norris Can't Find This Page – It's That Hidden!",
  "Whoa, You've Discovered Chuck Norris's Hideout: 404 Error",
  "404: Even Chuck Norris Couldn't Deliver This Page!",
  'Chuck Norris is on Break – 404 Page Missing in Action',
  '404: Chuck Norris Tried, But the Page Took Off on a Joyride',
  "Chuck Norris Says, '404 Error: Where's My Payload?'",
  '404: Chuck Norris Has Gone Rogue, Page MIA',
  'Chuck Norris Took a Detour – 404 Page Lost in Transit',
  "404: Chuck Norris Wonders If You're on the Wrong Route",
  'Chuck Norris Is Impressed – You Found the 404 Page!',
  "404: Chuck Norris Couldn't Locate This Page – It's That Elusive!",
  "Whoa, You've Stumbled into Chuck Norris's Mystery Zone: 404 Error",
]

const norrisAccessDeniedQuotes = [
  'Access Denied: Chuck Norris Says No Way, Jose!',
  'Oops! Chuck Norris Just Closed the Door on You: Access Denied',
  "Access Denied: Even Chuck Norris Can't Crack This Nut!",
  "Denied! Chuck Norris's Firewall is Stronger Than Titanium!",
  "Access Denied: Chuck Norris's Permission Slip Got Lost in the Mail!",
  'Denied! Chuck Norris Thinks You Should Try Again... Never!',
  "Access Denied: Chuck Norris Thinks You're Trying to Crash His Party!",
  "You Shall Not Pass! Access Denied by Chuck Norris's Magical Barrier!",
  "Access Denied: Chuck Norris's Guard Dog is Meaner Than a Junkyard Rottweiler!",
  'No Entry Allowed! Chuck Norris Locked the Door and Swallowed the Key!',
  'Access Denied: Chuck Norris Just Rolled a Natural 20 on Defense!',
  "Whoopsie! You've Just Entered Chuck Norris's No-Fly Zone: Access Denied",
]

export const getTruckNorrisQuote = (pageType: 'not_found' | 'access_denied'): string => {
  const norrisQuotes = pageType === 'not_found' ? norrisNotFoundQuotes : norrisAccessDeniedQuotes
  const randomIndex = Math.floor(Math.random() * norrisQuotes.length)
  const randomQuote = norrisQuotes[randomIndex]
  return randomQuote
}
