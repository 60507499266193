import { groupStopsBySequence } from 'screens/TripDetails/TripDetails.helpers'
import { LegData, StopData, GeoPoint, GeoPointsGroup, StopsSequenceGroup, StopLegPair } from 'screens/TripDetails/TripDetails.types'
import { BranchesData, TripData, StopsLocationGroup } from './TripDashboard.types'
import { AssignDriverFormValues } from './AssignDriverDialog'
import { AssignVehicleFormValues } from './AssignVehicleDialog'

export const getUpdatedTripForAssignDriver = (trip: TripData, data: AssignDriverFormValues): TripData => {
  return {
    ...trip,
    assignedDriverId: data.driverCid ?? undefined,
  }
}

export const getUpdatedTripForAssignVehicle = (trip: TripData, data: AssignVehicleFormValues): TripData => {
  return {
    ...trip,
    assignedVehicleLicensePlate: (data.withTrailer ? data.trailerLicensePlate : data.licensePlate) ?? undefined,
    secondaryAssignedVehicleLicensePlate: (data.withTrailer ? data.licensePlate : undefined) ?? undefined,
  }
}

export const getDataForDeleteTrip = (trip: TripData): TripData => trip

export const processDriversAndVehicles = (trip, vehicles, drivers) => {
  trip.assignedVehicle = vehicles.find((vehicle) => vehicle.licensePlate === trip.assignedVehicleLicensePlate)
  trip.secondaryAssignedVehicle = vehicles.find(
    (vehicle) => vehicle.licensePlate === trip.secondaryAssignedVehicleLicensePlate
  )
  trip.assignedDriver = drivers.find((driver) => driver.cid === trip.assignedDriverId)
  return trip
}

export const processBranches = (trips) => {
  const branches = trips
    .reduce((branches: BranchesData, trip: TripData) => {
    branches[trip.logicalSender] = trip.mappedSender
    return branches
  }, {})

  return Object.keys(branches)
    .sort((a, b) => a.localeCompare(b))
    .reduce((sorted, key) => {
      sorted[key] = branches[key];
      return sorted;
    }, {});

}

export const groupLegsByTrip = (legs: LegData[]): LegData[][] =>
  Array.from(legs.reduce((groups: Map<string, LegData[]>, leg) => {
    if (!leg.tripCid) {
      return groups
    }
    groups.set(leg.tripCid, [
      ...groups.get(leg.tripCid) ?? [],
      leg,
    ])
    return groups
  }, new Map<string, LegData[]>()).values())

export const groupStopsByLocation = (legs: LegData[]): StopsLocationGroup[] =>
  Array.from(legs.reduce((groups: Map<string, StopsLocationGroup>, leg) => {
    leg.wayPoints.map((stop) => {
      if (!stop.geoPoint) {
        return
      }
      if (stop.type === 'CUS') {
        return
      }
      const groupId = stop.partyIdentifier ?? getGeoPointId(stop.geoPoint)
      const currentGroup = groups.get(groupId) ?? {
        id: groupId,
        geoPoint: stop.geoPoint,
        stopLegPairs: [],
      }
      currentGroup.stopLegPairs.push({
        stop,
        leg,
      })
      groups.set(groupId, currentGroup)
    })
    return groups
  }, new Map<string, StopsLocationGroup>()).values())

const getAlphaLabel = (index: number): string => {
  let label = ''
  while (index > 0) {
    index--
    label = String.fromCharCode(65 + (index % 26)) + label
    index = Math.floor(index / 26)
  }
  return label
}

export const getLocationAlphaLabels = (legs: LegData[]): Map<string, string> => {
  const labels = new Map<string, string>()
  let currentIndex = 0
  legs.map((leg) => {
    leg.wayPoints.map((stop) => {
      if (!stop.geoPoint) {
        return
      }
      const groupId = getGeoPointId(stop.geoPoint)
      if (!labels.get(groupId)) {
        labels.set(groupId, getAlphaLabel(currentIndex))
        currentIndex++
      }
    })
  })
  return labels
}

export const getStopsFromLegs = (legs: LegData[]): StopData[] =>
  legs.reduce((stops: StopData[], leg: LegData) => {
    return [
      ...stops,
      ...leg.wayPoints,
    ]
  }, [])
    // sort stops by sequence first, they might have proper order set already, but not always
    .sort((a, b) => a.sequence - b.sequence)
    // then sort by stop type as a fallback
    .sort((a, b) => {
      const typesOrder = ['PUP', 'CUS', 'DEL']
      const aSequence = typesOrder.indexOf(a.type)
      const bSequence = typesOrder.indexOf(b.type)
      return aSequence === bSequence ? 0 : aSequence < bSequence ? -1 : 1
    })

export const getGroupedGeoPointsFromLegs = (legs: LegData[]): GeoPointsGroup[] => {
  const legsByTrip = groupLegsByTrip(legs)
  return Array.from(legsByTrip.reduce((groups: Map<string, GeoPointsGroup>, legs) => {
    // transform geopoints extracted from legs to distinct from-to pairs, so only single route line will be drawn
    // even when there are multiple trips going through same stops
    const geoPoints = getStopsFromLegs(legs).filter((stop) => stop.geoPoint).map((stop) => stop.geoPoint!)
    let previousGeoPoint: GeoPoint | null = null
    geoPoints.map((geoPoint) => {
      if (previousGeoPoint) {
        const geoPointsPair = [previousGeoPoint, geoPoint]
        const groupId = geoPointsPair.map((geoPoint) => getGeoPointId(geoPoint)).join('__')
        groups.set(groupId, {
          label: 'route',
          geoPoints: geoPointsPair,
        })
      }
      previousGeoPoint = geoPoint
    })
    return groups
  }, new Map<string, GeoPointsGroup>()).values())
}

export const getGeoPointId = (geoPoint: GeoPoint) => `${geoPoint.latitude.toFixed(5)}_${geoPoint.longitude.toFixed(5)}`
