import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
} from 'react'
import MenuContextProps from './MenuContext.types'

interface Action {
  type: 'setMenuProps'
  payload: MenuContextProps
}
type Dispatch = (action: Action) => void
interface State {
  menuContext: MenuContextProps
}
interface MenuContextProviderProps {
  children: ReactNode
}
interface MenuStateContextType {
  menuState: State
  dispatch: Dispatch
}

const MenuStateContext = createContext<MenuStateContextType | undefined>(
  undefined
)

function menuReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setMenuProps': {
      return { menuContext: action.payload }
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function MenuContextProvider({
  children,
}: MenuContextProviderProps): ReactElement {
  const [menuState, dispatch] = useReducer(menuReducer, {
    menuContext: {
      menuOpen: false,
      menuExtended: true,
    },
  })
  const value = { menuState, dispatch }
  return (
    <MenuStateContext.Provider value={value}>
      {children}
    </MenuStateContext.Provider>
  )
}

function useMenuContext(): MenuStateContextType {
  const context = useContext(MenuStateContext)
  if (context === undefined) {
    throw new Error('error')
  }
  return context
}

export { MenuContextProvider, useMenuContext }
