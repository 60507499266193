// @mui imports
import LoadingButton from '@mui/lab/LoadingButton'
import { CSSInterpolation } from '@mui/system'
import { styled } from '@mui/material/styles'

// Types
import KNLoadingButtonProps from './types'

const KNLoadingButtonRoot = styled(LoadingButton)(() => (props: KNLoadingButtonProps): CSSInterpolation => {
  const { textTransform } = props

  return {
    textTransform: textTransform,
    textDecoration: 'none',
  }
})

export default KNLoadingButtonRoot
