import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import i18n from 'i18n'

// @mui imports //
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Fade from '@mui/material/Fade'

// KN imports //
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormButtonGroup from 'components/KN_Molecules/KNForm/KNFormButtonGroup'

//Functional //
import { analyticsEvent } from 'global/helpers/analytics'
import { regexEmail } from 'global/helpers/validators'
import { supportRequest } from './Support.service'
import { UserContext } from 'context/authentication/UserContext'

// Types //
import { CompanyType } from 'context/authentication/Company.types'

interface SupportRequestDialogPayload {
  weblinkToken?: string
}

export interface SupportRequestDialogProps {
  moduleName: 'cs' | 'cv'
  title?: string
  description?: string
  formTitle?: string
  formRequestType?: 'Feedback' | 'Issue' | 'Question'
  payload: SupportRequestDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface SupportRequestFormValues {
  language: string
  url: string
  customerType: CompanyType
  requestType: 'Feedback' | 'Issue' | 'Question'
  email: string
  title: string
  message: string
}

const SupportRequestDialog: React.FC<SupportRequestDialogProps> = ({
  moduleName,
  title,
  description,
  formTitle,
  formRequestType,
  payload,
  open,
  onAction,
  onClose,
}) => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, setError } = useForm<SupportRequestFormValues>()
  const [showConfirmationSnackbar, setShowConfirmationSnackbar] = useState(false)
  const { user } = useContext(UserContext)
  const isCs = moduleName === 'cs'

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          language: i18n.language.slice(0, 2),
          url: window.location.href,
          requestType: formRequestType ?? null,
          customerType: CompanyType.Carrier,
          email: user?.email ?? null,
          title: formTitle ?? null,
          message: null,
        })
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<SupportRequestFormValues> = async (data: SupportRequestFormValues) => {
    try {
      await supportRequest(data, payload.weblinkToken)
      onAction()
      setShowConfirmationSnackbar(true)
      analyticsEvent(isCs ? 'polestar_cs_support_message_sent' : 'shipmentinsight_feedback_send', [data.requestType])
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  const handleSnackbarClose = (): void => setShowConfirmationSnackbar(false)

  return (
    <>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={title ?? t('shared.support.feedback_support')}
        actions={
          <>
            <KNLoadingButton
              data-test="send-button"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('form.send')}
            </KNLoadingButton>
          </>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <DialogContentText mb={1}>{description ?? t('shared.support.notice')}</DialogContentText>
        <DialogContentText mb={3}>{t('shared.support.language_notice')}</DialogContentText>
        <Stack spacing={2}>
          {!formRequestType && (
            <KNFormButtonGroup
              name="requestType"
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              options={['Feedback', 'Issue', 'Question'].map((type) => ({
                value: type,
                label: t(`shared.support.types.${type}`),
              }))}
            />
          )}
          {payload?.weblinkToken && (
            <KNFormText
              name="email"
              label={t('shared.support.email')}
              control={control}
              rules={{
                pattern: {
                  value: regexEmail,
                  message: t('form.validation.invalid_email'),
                },
                required: t('form.validation.required'),
              }}
            />
          )}
          {!title && (
            <KNFormText
              name="title"
              label={t('shared.support.title')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
            />
          )}
          <KNFormText
            name="message"
            label={t('shared.support.message')}
            control={control}
            rows={2}
            rules={{
              required: t('form.validation.required'),
            }}
          />
        </Stack>
      </KNDialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showConfirmationSnackbar}
        onClose={handleSnackbarClose}
        TransitionComponent={Fade}
        autoHideDuration={5000}
        key="supportRequestConfirmation"
      >
        <Alert variant="filled" severity="success">
          {t('shared.support.confirmation')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SupportRequestDialog
