import typography from 'assets/theme/base/typography'
import borders from 'assets/theme/base/borders'
import pxToRem from 'assets/theme/functions/pxToRem'

const { fontWeightRegular, size } = typography
const { borderRadius } = borders

const root = {
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: size.md,
  fontWeight: fontWeightRegular,
  borderRadius: borderRadius.lg,
  padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
  lineHeight: 1.4,
  textAlign: 'center',
  userSelect: 'none',
  backgroundSize: '150% !important',
  backgroundPositionX: '25% !important',
  transition: 'all 300ms ease-in-out',

  '&:disabled': {
    pointerEvent: 'none',
    opacity: 0.65,
  },

  '& .material-icons': {
    fontSize: pxToRem(15),
    marginTop: pxToRem(-2),
  },
}

export default root
