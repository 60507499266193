// @mui imports
import Switch from '@mui/material/Switch'
import { CSSInterpolation } from '@mui/system'
import { styled } from '@mui/material/styles'

// Types
import KNSwitchProps from './types'

const KNSwitchRoot = styled(Switch)(({ theme }) => (props: KNSwitchProps): CSSInterpolation => {
  const { typography } = theme

  return {
    fontWeight: typography.fontWeightBold,
    textDecoration: 'none',
  }
})

export default KNSwitchRoot
