import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import pxToRem from 'assets/theme/functions/pxToRem'

const { primary, info, secondary, transparent } = colors
const { size } = typography

const buttonText = {
  base: {
    backgroundColor: transparent.main,
    minHeight: pxToRem(40),
    boxShadow: 'none',
    padding: `${pxToRem(12)} ${pxToRem(24)}`,

    '&:hover': {
      backgroundColor: transparent.main,
      boxShadow: 'none',
    },

    '&:focus': {
      boxShadow: 'none',
    },

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85,
      boxShadow: 'none',
    },

    '&:disabled': {
      boxShadow: 'none',
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(8)} ${pxToRem(20)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.lg,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    color: primary.main,

    '&:hover': {
      color: primary.dark,
    },

    '&:focus:not(:hover)': {
      color: primary.dark,
      boxShadow: 'none',
    },
  },

  secondary: {
    color: secondary.main,

    '&:hover': {
      color: secondary.main,
    },

    '&:focus:not(:hover)': {
      color: secondary.focus,
      boxShadow: 'none',
    },
  },
}

export default buttonText
