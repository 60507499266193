import { merge } from 'lodash'
import { Chart as ChartJS, ChartOptions, TooltipItem } from 'chart.js'

// @mui imports
import { alpha } from '@mui/material'

import theme from 'assets/theme'
import { getDateLocale, getDateFormat, zonedDate } from 'global/helpers/dateFormatters'

ChartJS.defaults.font.family = 'SuisseIntl'
ChartJS.defaults.font.size = 12

const getBaseChartDefaults = (): ChartOptions => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      labels: {
        boxWidth: 32,
        boxHeight: 6,
        padding: 8,
        font: {
          size: 12,
          weight: '400',
        }
      },
    },
    tooltip: {
      displayColors: true,
      boxWidth: 8,
      boxHeight: 8,
      boxPadding: 4,
      bodyFont: {
        size: 16,
      },
      backgroundColor: alpha(theme.palette.primary.dark, 0.85),
    },
  },
  elements: {
    point: {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      radius: 2,
    },
    line: {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
  },
  scales: {
    x: {
      grid: {
        color: theme.palette.light.main,
      },
      ticks: {
        color: theme.palette.primary.main,
        padding: 4,
        font: {
          size: 11,
          weight: '400',
        },
      },
      border: {
        color: theme.palette.primary.light,
      },
    },
    y: {
      grid: {
        color: theme.palette.light.main,
      },
      ticks: {
        color: theme.palette.primary.main,
        padding: 4,
        font: {
          size: 11,
          weight: '400',
        },
      },
      border: {
        color: theme.palette.primary.light,
      },
    },
  },
})

const getLineChartDefaults = (): ChartOptions<'line'> => ({
  plugins: {
    legend: {
      display: true,
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
})

const getTimeChartDefaults = (): ChartOptions => ({
  plugins: {
    tooltip: {
      callbacks: {
        title: (context: TooltipItem<'line'>[]) => {
          // NOTE: is context an array with multiple elements when there are multiple charts overlapping?
          return zonedDate(new Date(context[0].parsed.x), 'full') as string
        },
      },
    },
  },
  elements: {
    point: {
      pointStyle: false,
    },
    line: {
      tension: 0.2,
      cubicInterpolationMode: 'monotone' as const,
    },
  },
  scales: {
    x: {
      adapters: {
        date: {
          locale: getDateLocale(),
        },
      },
      type: 'time',
      time: {
        minUnit: 'hour',
        displayFormats: {
          hour: getDateFormat('short_no_year_with_time'),
          day: getDateFormat('medium_no_year'),
        },
      },
      ticks: {
        minRotation: 0,
        maxRotation: 45,
        major: {
          enabled: true,
        },
        callback: (value, index, values) =>
          zonedDate(new Date(value), values[index].major ? 'medium_no_year' : 'time') as string,
      },
    },
    y: {
      grid: {
        drawTicks: false,
      },
      ticks: {
        padding: 8,
      },
      border: {
        display: false,
      },
    },
  },
})

const getTemperatureChartDefaults = (): ChartOptions => ({
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: TooltipItem<'line'>) => {
          return `${parseFloat(context.parsed.y.toFixed(2))} °C`
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        // NOTE: value can be a string or a number
        callback: (value, index, ticks) => `${parseFloat(Number(value).toFixed(2))} °C`,
      },
    },
  },
})

export const getDarkModeColors = (): ChartOptions => ({
  plugins: {
    legend: {
      labels: {
        color: theme.palette.white.main,
      },
    },
  },
  elements: {
    point: {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.white.main,
    },
    line: {
      borderColor: theme.palette.white.main,
      borderWidth: 1,
    },
  },
  scales: {
    x: {
      grid: {
        color: theme.palette.primary.light,
      },
      ticks: {
        color: theme.palette.white.main,
      },
      border: {
        color: theme.palette.primary.light,
      },
    },
    y: {
      grid: {
        color: theme.palette.primary.light,
      },
      ticks: {
        color: theme.palette.white.main,
      },
      border: {
        color: theme.palette.primary.light,
      },
    },
  },
})

export const getDatasetColors = () => [
  '#b197fc', // violet
  '#ffa8a8', // red
  '#ffe066', // yellow
  '#63e6be', // teal
  '#74c0fc', // blue
]


export interface Annotation {
  drawTime: string
  type: string
  yMin?: number
  yMax?: number
  xMin?: number
  xMax?: number
  backgroundColor: string
  borderColor: string
}

export const getLineChartOptions = (extendedChartOptions: ChartOptions<'line'> = {}): ChartOptions<'line'> =>
  merge(getBaseChartDefaults(), getLineChartDefaults(), extendedChartOptions)

export const getTemperatureChartOptions = (extendedChartOptions: ChartOptions<'line'> = {}): ChartOptions<'line'> =>
  merge(
    getBaseChartDefaults(),
    getLineChartDefaults(),
    getTimeChartDefaults(),
    getTemperatureChartDefaults(),
    extendedChartOptions
  )
