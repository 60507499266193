import createStore from 'global/helpers/createStore'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'
import { DriverData } from 'screens/DriverManager/DriverManager.types'

export interface TripFiltersValues {
  keywords?: string[]
  status?: string[]
  fromDate?: Date | null
  toDate?: Date | null
  branches?: string[]
}

export interface TripListState {
  backgroundLoading: boolean
  vehicles: VehicleData[]
  vehiclesPreloaded: boolean
  drivers: DriverData[]
  driversPreloaded: boolean
  predefinedFilter: string | null
  filters: TripFiltersValues
  filtersDirty: boolean
  detailsView: 'shipments' | 'stops' | 'sequence_map' | 'locations_map'
  scroll: number | null
}

export type TripListAction =
  | { type: 'setBackgroundLoading'; payload: boolean }
  | { type: 'setVehicles'; payload: VehicleData[] }
  | { type: 'setDrivers'; payload: DriverData[] }
  | { type: 'setPredefinedFilter'; payload: string }
  | { type: 'setFilters'; payload: TripFiltersValues }
  | { type: 'resetFilters' }
  | { type: 'setDetailsView'; payload: 'shipments' | 'stops' | 'sequence_map' | 'locations_map' }
  | { type: 'setScroll'; payload: number | null }

const processFilters = (currentFilters: TripFiltersValues, extendedFilters?: TripFiltersValues) => {
  const filters = { ...currentFilters, ...extendedFilters }
  if (filters.status) {
    filters.status = filters.status.sort()
  }
  return filters
}

const initialFilters: TripFiltersValues = processFilters({
  keywords: [],
  status: ['NEW', 'TRIP_ASSIGNED', 'IN_PROGRESS', 'DELIVERED'],
  fromDate: null,
  toDate: null,
  branches: [],
})

const initialState: TripListState = {
  backgroundLoading: false,
  vehicles: [],
  vehiclesPreloaded: false,
  drivers: [],
  driversPreloaded: false,
  predefinedFilter: null,
  filters: initialFilters,
  filtersDirty: false,
  detailsView: 'shipments',
  scroll: null,
}

const reducer = (state: TripListState, action: TripListAction): TripListState => {
  switch (action.type) {
    case 'setBackgroundLoading':
      return {
        ...state,
        backgroundLoading: action.payload,
      }
    case 'setVehicles':
      return {
        ...state,
        vehicles: action.payload,
        vehiclesPreloaded: true,
      }
    case 'setDrivers':
      return {
        ...state,
        drivers: action.payload,
        driversPreloaded: true,
      }
    case 'setPredefinedFilter':
      return {
        ...state,
        predefinedFilter: state.predefinedFilter !== action.payload ? action.payload : null,
      }
    case 'setFilters': {
      const filters = processFilters(state.filters, action.payload)
      return {
        ...state,
        filters,
        filtersDirty: JSON.stringify(filters) !== JSON.stringify(initialFilters),
        scroll: 0, // NOTE: reset scroll on filters change
      }
    }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
        scroll: 0, // NOTE: reset scroll on filters change
      }
    case 'setDetailsView':
      return {
        ...state,
        detailsView: action.payload,
      }
    case 'setScroll':
      return {
        ...state,
        scroll: action.payload,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as TripListContext, StoreProvider as TripListProvider }
