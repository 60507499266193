// types
type Types = any

const dateRangePicker: Types = {
  '.Mui-DateRangePicker': {
    position: 'absolute !important',
  },
}

export default dateRangePicker
