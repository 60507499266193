import { useEffect, useState } from 'react'

// @mui imports //
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

// KN modules //
import CreatePlaygroundScenarioForm from 'modules/PlaygroundForms/CreatePlaygroundScenarioForm'
import CreatePlaygroundTripTelematicsForm from 'modules/PlaygroundForms/CreatePlaygroundTripTelematicsForm'
import { getUsers } from 'screens/UserManager/UserManager.service'
import { getCompanies } from './Playground.service'

// Data //
import { playgroundTranslations } from './Playground.data'

// Types //
import { UserData } from 'screens/UserManager/UserManager.types'
import { Company } from 'context/authentication/Company.types'

const Playground = (): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('predefinedScenario')
  const { translation } = playgroundTranslations()
  const [users, setUsers] = useState<UserData[]>([])
  const [companies, setCompanies] = useState<Company[]>([])
  const [fetchError, setFetchError] = useState<string>()
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)

  useEffect(() => {
    const getUsersData = async (): Promise<void> => {
      setFetchLoading(true)
      try {
        setUsers(await getUsers())
      } catch (error) {
        setFetchError('fetch user Error')
      }
      setFetchLoading(false)
    }

    const getCompaniesData = async (): Promise<void> => {
      setFetchLoading(true)
      try {
        setCompanies(await getCompanies())
      } catch (error) {
        setFetchError('fetch companies Error')
      }
      setFetchLoading(false)
    }

    void getUsersData()
    void getCompaniesData()
  }, [])

  return (
    <Container maxWidth={false} data-test="playground-container">
      <Paper elevation={8} sx={{ padding: 2 }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'grey.300' }}>
            <Tabs value={activeTab} onChange={(e, value): void => setActiveTab(value)} aria-label="basic tabs example">
              <Tab
                label={translation.createPredefinedScenarioFormName}
                value="predefinedScenario"
                data-test="predefined-tab"
              />
              <Tab
                label={translation.createTripTelematicsFormName}
                value="tripTelematics"
                data-test="trip-telematics-tab"
              />
            </Tabs>
          </Box>
          <Stack spacing={3} direction="column" mb={2}>
            <TabPanel value="tripTelematics">
              <CreatePlaygroundTripTelematicsForm
                fetchLoading={fetchLoading}
                fetchError={fetchError}
                companies={companies}
              />
            </TabPanel>
            <TabPanel value="predefinedScenario">
              <CreatePlaygroundScenarioForm
                fetchLoading={fetchLoading}
                fetchError={fetchError}
                users={users}
                companies={companies}
              />
            </TabPanel>
          </Stack>
        </TabContext>
      </Paper>
    </Container>
  )
}

export default Playground
