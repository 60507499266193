import { useState, ReactElement } from 'react'
import { Controller, FieldValues, ValidateResult } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// @mui imports
import TextField from '@mui/material/TextField'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { SystemStyleObject } from '@mui/system'
import InputAdornment from '@mui/material/InputAdornment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'

// KN imports
import KNDatePicker from '../KNDatePicker/KNDatePicker'
import theme from 'assets/theme'

// Functional
import { getDateFormat } from 'global/helpers/dateFormatters'

// Types
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormTimeProps } from './types'

const KNFormTime = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  size,
  sx,
}: KNFormTimeProps<T>): ReactElement => {
  const { t } = useTranslation()
  const [dateError, setDateError] = useState<string | null>(null)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          invalidDate: (value): ValidateResult => {
            if (dateError) {
              return t('form.validation.invalid_date')
            }
            return true
          },
        },
      }}
      render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
        <KNDatePicker
          selected={value ?? null}
          inputRef={ref}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          format={getDateFormat('time')}
          value={value ?? null}
          onChange={(newValue: Date | null): void => {
            if (newValue) {
              newValue.setSeconds(0)
            }
            onChange(newValue)
          }}
          renderInput={
            <TextField
              fullWidth
              label={label}
              size={size}
              sx={sx}
              error={invalid}
              helperText={getFieldErrorMessages(error)}
              InputProps={{
                endAdornment: !isMobile ? (
                  <InputAdornment position="end">
                    <ScheduleIcon
                      sx={({ palette: { grey } }: Theme): SystemStyleObject<Theme> => ({
                        color: grey[500],
                      })}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
          }
          onError={(reason, value): void => setDateError(reason)}
          disabled={formState.isSubmitting}
          id={name}
        />
      )}
    />
  )
}

export default KNFormTime
