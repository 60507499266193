import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { set as dateFnsSet } from 'date-fns'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import WarningIcon from '@mui/icons-material/Warning'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import i18n from 'i18n'
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate } from 'global/helpers/dateFormatters'
import { getRouteName } from 'global/helpers/activeRoute'
import { sleep } from 'global/helpers/sleep'
import { ErrorMessage, getExtendedErrorMessages } from 'global/helpers/errorHandler'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import StatusUpdateFields from './StatusUpdateFields'
import { StatusUpdateFormValues, getDefaultStatus, rewriteTimezone } from './StatusUpdateDialog'
import { bulkUpdateStopStatus } from './StatusManager.service'
import { getUpdatedStopsForBulkUpdateStopStatus } from './StatusManager.helpers'
import { LegData, StopData, StopLegPair } from 'screens/TripDetails/TripDetails.types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'

interface BulkStatusUpdateDialogPayload {
  trip: TripData
  stopLegPairs: StopLegPair[]
  weblinkToken?: string
}

interface BulkStatusUpdateDialogProps {
  payload: BulkStatusUpdateDialogPayload
  open: boolean
  onAction: (updatedStops: StopData[]) => void
  onError?: (errors: ErrorMessage[], updatedStops: StopData[]) => void
  onClose: () => void
}

export interface BulkStatusUpdateFormValues extends Omit<StatusUpdateFormValues, 'shipmentNumber'> {
  //
}

export const getStatusUpdateLabel = (type: string): string => {
  switch (type) {
    case 'PUP':
      return i18n.t('screens.cs.trip_details.update_status.bulk_pickup_status')
    case 'CUS':
      return i18n.t('screens.cs.trip_details.update_status.bulk_customs_status')
    case 'DEL':
      return i18n.t('screens.cs.trip_details.update_status.bulk_delivery_status')
    default:
      return i18n.t('screens.cs.trip_details.update_status.bulk_status')
  }
}

const BulkStatusUpdateDialog = ({
  payload,
  open,
  onAction,
  onError,
  onClose,
}: BulkStatusUpdateDialogProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const location = useLocation()
  const form = useForm<BulkStatusUpdateFormValues>({
    criteriaMode: 'all',
  })
  const { handleSubmit, reset, formState, setError } = form

  const isPaperlessAvailable = (): boolean => {
    const paperlessOnStopLegPairs: boolean = payload.stopLegPairs
      .map((stopLegPair) => {
        return stopLegPair.leg.paperlessAvailable && !stopLegPair.stop.isProofAttached
      })
      .every((condition) => condition)
    return isMobile && Boolean(payload.weblinkToken) && paperlessOnStopLegPairs
  }

  useEffect(() => {
    reset(
      processDefaultValues({
        wayPointType: payload.stopLegPairs[0].stop.type,
        statusId: getDefaultStatus(payload.stopLegPairs[0].stop.type),
        createdAtDate: payload.stopLegPairs[0].stop.earlyDateTime
          ? new Date(payload.stopLegPairs[0].stop.earlyDateTime)
          : new Date(),
        createdAtTime: null,
        paperless: isPaperlessAvailable(),
      })
    )
  }, [payload])

  const onSubmit: SubmitHandler<BulkStatusUpdateFormValues> = async (data: BulkStatusUpdateFormValues) => {
    // NOTE: rewrites input timezone to stop's timezone
    if (data.createdAtDate && data.createdAtTime) {
      data.createdAt = rewriteTimezone(
        data.createdAtDate,
        data.createdAtTime,
        payload.stopLegPairs[0].stop.earlyDateTime
      )
    }
    try {
      await bulkUpdateStopStatus(payload.trip.entityId, payload.stopLegPairs, data, payload.weblinkToken)
      onAction(getUpdatedStopsForBulkUpdateStopStatus(payload.stopLegPairs, data))
      analyticsEvent('polestar_cs_bulk_status_updated', [
        getRouteName(location.pathname),
        data.paperless ? 'paperless' : 'regular',
      ])
    } catch (error) {
      const errorMessages = getExtendedErrorMessages(error)
      const stopsErrorMessages = errorMessages.filter((errorMessage) => errorMessage.waypointCid !== undefined)
      // NOTE: use onError() only if all errors relate to specific stops, and are not generic server errors
      if (onError && stopsErrorMessages.length === errorMessages.length) {
        onError(
          errorMessages,
          getUpdatedStopsForBulkUpdateStopStatus(payload.stopLegPairs, data).filter((updatedStop) =>
            stopsErrorMessages.map((errorMessage) => errorMessage.waypointCid).includes(updatedStop.wayPointCid)
          )
        )
      } else {
        setError('root', processServerErrorMessages(error))
      }
    }
  }

  return (
    <FormProvider {...form}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={getStatusUpdateLabel(payload.stopLegPairs[0].stop.type)}
        actions={
          <>
            <KNLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('screens.cs.trip_details.update_status.update_status')}
            </KNLoadingButton>
          </>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <DialogContentText component="div" mb={2}>
          <KNCaption color="warning" icon={<WarningIcon />}>
            {t('screens.cs.trip_details.update_status.warning')}
          </KNCaption>
        </DialogContentText>
        <StatusUpdateFields
          payload={{
            tripId: payload.trip.entityId,
            stop: payload.stopLegPairs[0].stop,
            leg: payload.stopLegPairs[0].leg,
            weblinkToken: payload.weblinkToken,
          }}
        />
      </KNDialog>
    </FormProvider>
  )
}

export default BulkStatusUpdateDialog
