import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { VehicleData } from './VehicleManager.types'

interface TrackingLabelProps {
  vehicle: VehicleData
  compact?: boolean
}

const getStatusColor = (status?: string) => {
  switch (status) {
    case 'online':
      return 'success.main'
    case 'probably_offline':
      return 'error.main'
    case 'offline':
    default:
      return 'primary.light'
  }
}

const TrackingLabel = ({ vehicle, compact }: TrackingLabelProps): ReactElement | null => {
  const { t } = useTranslation()
  if (!vehicle.telematicsReady) {
    return null
  }
  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <GpsFixedIcon sx={{ color: getStatusColor(vehicle.status), fontSize: '1rem' }} />
      {!compact && (
        <KNTypography variant="textMD_SB" color={getStatusColor(vehicle.status)}>
          {vehicle.status === 'online'
            ? t('screens.cs.vehicle_manager.card.tracking')
            : t('screens.cs.vehicle_manager.card.tracking_offline')}
        </KNTypography>
      )}
    </Stack>
  )
}

export default TrackingLabel
