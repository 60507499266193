import { forwardRef } from 'react'

// Types
import KNSwitchProps from './types'

// Custom KN styles
import KNSwitchRoot from './KNSwitchRoot'

const KNSwitch = forwardRef<HTMLButtonElement, KNSwitchProps>(
  ({ dataAttribute, ...rest }, ref) => (
    <KNSwitchRoot
      {...rest}
      ref={ref}
      data-test={`${String(dataAttribute)}-KNSwitch`}
    />
  )
)

export default KNSwitch
