import { useState, useContext, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

// KN imports
import { UserListContext } from 'context/users/UserListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNDataTableMenu from 'components/KN_Molecules/KNDataTable/KNDataTableMenu'
import KNDataTableOverflow from 'components/KN_Molecules/KNDataTable/KNDataTableOverflow'
import CompanyTypeLabel from 'screens/CompanyManager/CompanyTypeLabel'
import CreateOrEditUserDialog from './CreateOrEditUserDialog'
import DeleteUserDialog from './DeleteUserDialog'
import GrantRoleDialog from './GrantRoleDialog'
import RevokeRoleDialog from './RevokeRoleDialog'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { hasRole, isCompanyType } from 'global/helpers/authorization'
import { getSelectedCompany } from 'context/authentication/User.helpers'

// Types
import { Role } from 'context/authentication/Role.types'
import { CompanyType } from 'context/authentication/Company.types'
import { KNDataTableColumn } from 'components/KN_Molecules/KNDataTable/types'
import { UserData } from './UserManager.types'

interface UserTableProps {
  users: UserData[]
  onChange?: () => void
}

const UserTable = ({ users, onChange }: UserTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeUser, setActiveUser] = useState<UserData>()
  const [userListState, userListDispatch] = useContext(UserListContext)
  const [createOrEditUserDialogOpen, setCreateOrEditUserDialogOpen] = useState(false)
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false)
  const [grantRoleDialogOpen, setGrantRoleDialogOpen] = useState(false)
  const [revokeRoleDialogOpen, setRevokeRoleDialogOpen] = useState(false)

  const { user } = useContext(UserContext)

  const handleEditClick = useCallback((row: UserData) => {
    setActiveUser(row)
    setCreateOrEditUserDialogOpen(true)
    analyticsEvent('polestar_edit_user_button_clicked')
  }, [])

  const handleDeleteClick = useCallback((row: UserData) => {
    setActiveUser(row)
    setDeleteUserDialogOpen(true)
    analyticsEvent('polestar_delete_user_button_clicked')
  }, [])

  const getColumns = useCallback((): KNDataTableColumn<UserData>[] => {
    const companiesColumn: KNDataTableColumn<UserData>[] = [
      {
        name: 'roles',
        label: t('screens.shared.user_manager.card.columns.companies'),
        getValue: (row: UserData) => (
          <Stack data-test="companies-info" spacing={1} direction="row" alignItems="center">
            {row.companies && row.companies.length > 0 ? (
              <KNDataTableOverflow
                row={row}
                label={t('general.view_all')}
                header={t('screens.shared.user_manager.card.columns.companies')}
                items={row.companies.map((company) => (
                  <Stack
                    data-test="company-and-user-role"
                    key={company.cid}
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <CompanyTypeLabel type={company.type} compact />
                    <Box>{company.displayName}</Box>
                    {company.role && (
                      <KNTypography variant="textMD_SB" color="primary.light">
                        {t(`screens.shared.user_manager.roles.${company.role}`)}
                      </KNTypography>
                    )}
                  </Stack>
                ))}
                rightAligned
              />
            ) : (
              <KNTypography color="primary.light">{t('general.none')}</KNTypography>
            )}
            {hasRole(Role.Admin) && (
              <KNDataTableMenu
                label={t('screens.shared.user_manager.card.actions.role_options')}
                row={row}
                actions={[
                  ...(row.companies && row.companies.length !== userListState.companies.length
                    ? [
                        {
                          name: 'grant_rle',
                          label: t('screens.shared.user_manager.grant_role.grant_role'),
                          onClick: (row: UserData) => {
                            setActiveUser(row)
                            setGrantRoleDialogOpen(true)
                          },
                        },
                      ]
                    : []),
                  ...(row.companies && row.companies.length > 0
                    ? [
                        {
                          name: 'revoke_role',
                          label: t('screens.shared.user_manager.revoke_role.revoke_role'),
                          onClick: (row: UserData) => {
                            setActiveUser(row)
                            setRevokeRoleDialogOpen(true)
                          },
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </Stack>
        ),
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
    ]

    let roleColumn: KNDataTableColumn<UserData>[] = []
    const selectedCompany = getSelectedCompany()
    if (selectedCompany) {
      roleColumn = [
        {
          name: 'role',
          label: t('screens.shared.user_manager.card.columns.role'),
          getValue: (row: UserData) => row.roles[selectedCompany.cid],
          sx: { width: { xs: '24rem', sm: 'auto' } },
        },
      ]
    }

    const generalColumns: KNDataTableColumn<UserData>[] = [
      {
        name: 'displayName',
        label: t('screens.shared.user_manager.card.columns.display_name'),
        sx: { width: { xs: '18rem', xxl: '24rem' } },
      },
      {
        name: 'email',
        label: t('screens.shared.user_manager.card.columns.email'),
        sx: { width: { xs: '18rem', xxl: '24rem' } },
      },
    ]

    return isCompanyType(CompanyType.Operator)
      ? generalColumns.concat(companiesColumn)
      : generalColumns.concat(roleColumn)
  }, [])

  const actions = useMemo(
    () => [
      {
        name: 'edit',
        label: t('screens.shared.user_manager.card.actions.edit'),
        icon: <EditIcon />,
        onClick: handleEditClick,
      },
      {
        name: 'delete',
        label: t('screens.shared.user_manager.card.actions.delete'),
        icon: <DeleteIcon />,
        onClick: handleDeleteClick,
        isVisible: (row: UserData) => row.email !== user?.email,
      },
    ],
    []
  )

  const handleCreateOrEditDialogAction = useCallback((): void => {
    setCreateOrEditUserDialogOpen(false)
    onChange?.()
  }, [])

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditUserDialogOpen(false)
  }, [])

  const handleDeleteDialogAction = useCallback((): void => {
    setDeleteUserDialogOpen(false)
    onChange?.()
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteUserDialogOpen(false)
  }, [])

  const handleGrantRoleDialogAction = useCallback((): void => {
    setGrantRoleDialogOpen(false)
    onChange?.()
  }, [])

  const handleGrantRoleDialogClose = useCallback((): void => {
    setGrantRoleDialogOpen(false)
  }, [])

  const handleRevokeRoleDialogAction = useCallback((): void => {
    setRevokeRoleDialogOpen(false)
    onChange?.()
  }, [])

  const handleRevokeRoleDialogClose = useCallback((): void => {
    setRevokeRoleDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={getColumns()}
        actions={hasRole(Role.Admin) ? actions : []}
        data={users}
        sx={{
          marginX: -2,
        }}
      />
      {activeUser && hasRole(Role.Admin) && (
        <>
          <CreateOrEditUserDialog
            payload={{
              user: activeUser,
            }}
            open={createOrEditUserDialogOpen}
            onAction={handleCreateOrEditDialogAction}
            onClose={handleCreateOrEditDialogClose}
          />
          <DeleteUserDialog
            payload={{
              user: activeUser,
            }}
            open={deleteUserDialogOpen}
            onAction={handleDeleteDialogAction}
            onClose={handleDeleteDialogClose}
          />
          <GrantRoleDialog
            payload={{
              user: activeUser,
            }}
            open={grantRoleDialogOpen}
            onAction={handleGrantRoleDialogAction}
            onClose={handleGrantRoleDialogClose}
          />
          <RevokeRoleDialog
            payload={{
              user: activeUser,
            }}
            open={revokeRoleDialogOpen}
            onAction={handleRevokeRoleDialogAction}
            onClose={handleRevokeRoleDialogClose}
          />
        </>
      )}
    </>
  )
}

export default UserTable
