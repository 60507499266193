import pxToRem from 'assets/theme/functions/pxToRem'

// types
type Types = any

const dialogActions: Types = {
  styleOverrides: {
    root: {
      padding: pxToRem(20),
    },
  },
}

export default dialogActions
