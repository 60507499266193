import i18n from '../../i18n'

// Types
import { KNUser } from 'context/authentication/UserContext.types'

interface LanguageProps {
  countryCode?: string
  languageCode: string
  label: string
}

interface UserPropertiesProps {
  label: string
  value: string
}

export const getLanguages = (): LanguageProps[] => {
  return [
    {
      countryCode: 'gb',
      languageCode: 'en',
      label: 'English',
    },
    {
      countryCode: 'za',
      languageCode: 'af',
      label: 'Afrikaans',
    },
    {
      countryCode: undefined,
      languageCode: 'ar',
      label: 'العربية',
    },
    {
      countryCode: 'bg',
      languageCode: 'bg',
      label: 'Български',
    },
    {
      countryCode: 'cz',
      languageCode: 'cs',
      label: 'Čeština',
    },
    {
      countryCode: 'de',
      languageCode: 'de',
      label: 'Deutsch',
    },
    {
      countryCode: 'ee',
      languageCode: 'et',
      label: 'Eesti',
    },
    {
      countryCode: 'fr',
      languageCode: 'fr',
      label: 'Francais',
    },
    {
      countryCode: 'gr',
      languageCode: 'el',
      label: 'Ελληνικά',
    },
    {
      countryCode: 'hr',
      languageCode: 'hr',
      label: 'Hrvatski',
    },
    {
      countryCode: 'hu',
      languageCode: 'hu',
      label: 'Magyar',
    },
    {
      countryCode: 'id',
      languageCode: 'id',
      label: 'Bahasa Indonesia',
    },
    {
      countryCode: 'mk',
      languageCode: 'mk',
      label: 'Македонски',
    },
    {
      countryCode: 'my',
      languageCode: 'ms',
      label: 'Bahasa Melayu',
    },
    {
      countryCode: 'no',
      languageCode: 'nn',
      label: 'Norsk',
    },
    {
      countryCode: 'ph',
      languageCode: 'fil',
      label: 'Wikang Filipino',
    },
    {
      countryCode: 'pl',
      languageCode: 'pl',
      label: 'Polski',
    },
    {
      countryCode: 'ro',
      languageCode: 'ro',
      label: 'Romana',
    },
    {
      countryCode: 'ru',
      languageCode: 'ru',
      label: 'Русский',
    },
    {
      countryCode: 'sk',
      languageCode: 'sk',
      label: 'Slovenský',
    },
    {
      countryCode: 'si',
      languageCode: 'sl',
      label: 'Slovenščina',
    },
    {
      countryCode: 'pt',
      languageCode: 'pt',
      label: 'Português',
    },
    {
      countryCode: 'rs',
      languageCode: 'sr',
      label: 'Srpski',
    },
    {
      countryCode: undefined,
      languageCode: 'sw',
      label: 'Swahili',
    },
    {
      countryCode: 'tr',
      languageCode: 'tr',
      label: 'Türkçe',
    },
    {
      countryCode: 'th',
      languageCode: 'th',
      label: 'ภาษาไทย',
    },
    {
      countryCode: 'ua',
      languageCode: 'uk',
      label: 'Українець',
    },
    {
      countryCode: 'vn',
      languageCode: 'vi',
      label: 'Tiếng Việt',
    },
    {
      countryCode: 'zh',
      languageCode: 'zh',
      label: '简体中文',
    },
  ]
}

export const getUserPropertiesWithValues = (user: KNUser): UserPropertiesProps[] => {
  return [
    { label: i18n.t('screens.shared.profile.account_id'), value: user.uid },
    { label: i18n.t('screens.shared.profile.email'), value: user.email },
    { label: i18n.t('screens.shared.profile.role'), value: user.type },
  ]
}
