import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { RevokeRoleFormValues } from './RevokeRoleDialog'

// Functional
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { analyticsEvent } from 'global/helpers/analytics'
import { sleep } from 'global/helpers/sleep'
import { processServerErrorMessages } from 'global/helpers/form'
import { isCompanyType } from 'global/helpers/authorization'
import { deleteUser, revokeRole } from './UserManager.service'

// Types
import { CompanyType } from 'context/authentication/Company.types'
import { UserData } from './UserManager.types'

interface DeleteUserDialogPayload {
  user: UserData
}

interface DeleteUserDialogProps {
  payload: DeleteUserDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

const DeleteUserDialog = ({ payload, open, onAction, onClose }: DeleteUserDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, formState, setError } = useForm()
  const [deletionConfirmed, setDeletionConfirmed] = useState(false)

  useEffect(() => {
    setDeletionConfirmed(false)
  }, [payload])

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      if (isCompanyType(CompanyType.Operator)) {
        await deleteUser(payload.user.cid)
      } else {
        const selectedCompany = getSelectedCompany()
        if (!selectedCompany) return
        const userRole = payload.user.roles[selectedCompany.cid]
        const data: RevokeRoleFormValues = {
          companyCid: selectedCompany.cid,
          role: userRole,
        }
        await revokeRole(payload.user.cid, data)
      }
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      analyticsEvent('polestar_user_deleted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.user.displayName} — ${t('screens.shared.user_manager.card.actions.delete')}`}
      actions={
        <>
          <KNLoadingButton
            data-test="delete-button"
            type="submit"
            color="error"
            variant="contained"
            disabled={!deletionConfirmed}
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('screens.shared.user_manager.card.actions.delete')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <KNTypography color="grey.500">
        {t('screens.shared.user_manager.delete_user.message')}
      </KNTypography>
      <FormControlLabel
        control={
          <Checkbox
            data-test="confirm-checkbox"
            value={deletionConfirmed}
            checked={deletionConfirmed}
            onChange={(): void => setDeletionConfirmed(!deletionConfirmed)}
          />
        }
        label={t('shared.manager.deletion_confirm_checkbox')}
      />
    </KNDialog>
  )
}

export default DeleteUserDialog
