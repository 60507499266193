import { useState, useEffect, useCallback, useMemo, ReactElement, Fragment } from 'react'
import { Marker } from '@react-google-maps/api'
import { throttle } from 'lodash'
import { parseISO, isThisYear, isSameDay, formatDistance } from 'date-fns'
import i18n from 'i18n'

// @mui imports
import theme from 'assets/theme'
import Popper, { PopperProps } from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

// KN imports
import { zonedDate, relativeDate } from 'global/helpers/dateFormatters'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { GeoPoint } from 'screens/TripDetails/TripDetails.types'
import { getBaseMarkerIcon, getDistance, getDistanceInPixels, getPixelFromCoords, getZIndex } from './KNMap.helpers'
import { MapMarker, MapMarkerState, MapTooltip, KNMapTooltipProps } from './types'

const getTooltipContent = (marker: MapMarker): ReactElement | null => {
  if (!marker.tooltip) {
    return null
  }
  return (
    <Box paddingY={0.75} paddingLeft={1} paddingRight={2}>
      <Stack spacing={1} direction="row" alignItems="start">
        <Box sx={{ opacity: marker.state === MapMarkerState.Muted ? 0.5 : 1, width: '24px', height: '24px', flexShrink: 0 }}>
          {getBaseMarkerIcon(marker)}
        </Box>
        <Box>
          {marker.tooltip}
        </Box>
      </Stack>
    </Box>
  )
}

const KNMapTooltip = ({
  map,
  markers,
}: KNMapTooltipProps): ReactElement | null => {
  const [mapContainer, setMapContainer] = useState<Element | null>(null)
  const [tooltips, setTooltips] = useState<MapTooltip[]>([])
  const [tooltipAnchor, setTooltipAnchor] = useState<PopperProps['anchorEl'] | null>(null)

  const handleTooltipMove = (event: google.maps.MapMouseEvent) => {
    setTooltipAnchor({
      getBoundingClientRect: () => new DOMRect((event.domEvent as MouseEvent).clientX, (event.domEvent as MouseEvent).clientY, 0, 0),
    })
  }
  const handleTooltipMoveThrottled = useMemo(() => throttle(handleTooltipMove, 16), [handleTooltipMove])

  const handleTooltipContent = (event: google.maps.MapMouseEvent) => {
    const distanceThreshold = 50 // pixels
    const newTooltips: MapTooltip[] = []
    markers.map((marker) => {
      if (!marker.tooltip) {
        return
      }
      const markerPixel = getPixelFromCoords(map, marker.latitude, marker.longitude)
      if (!markerPixel) {
        return
      }
      const distance = getDistanceInPixels((event.domEvent as MouseEvent).clientX, (event.domEvent as MouseEvent).clientY, markerPixel.x, markerPixel.y)
      if (distance > distanceThreshold) {
        return
      }
      const tooltipContent = getTooltipContent(marker)
      if (!tooltipContent) {
        return
      }
      // sort by distance, take fractions into account for better accuracy
      // heading marker always on top
      newTooltips.push({
        id: marker.id,
        content: tooltipContent,
        priority: marker.id === 'heading' ? -1 : Math.round(distance * 1000),
      })
    })
    setTooltips(newTooltips.sort((a, b) => {
      const aPriority = a.priority ?? Infinity
      const bPriority = b.priority ?? Infinity
      return aPriority === bPriority ? 0 : aPriority < bPriority ? -1 : 1
    }))
  }
  const handleTooltipContentThrottled = useMemo(() => throttle(handleTooltipContent, 200, { leading: true, trailing: false }), [handleTooltipContent, markers])

  const handleTooltipHide = useCallback((event: google.maps.MapMouseEvent) => {
    setTooltipAnchor(null)
  }, [])

  useEffect(() => {
    setMapContainer(map.getDiv().firstElementChild)
  }, [map])

  useEffect(() => {
    const mouseMoveEvent = google.maps.event.addListener(map, 'mousemove', handleTooltipMoveThrottled)
    const mouseMoveContentEvent = google.maps.event.addListener(map, 'mousemove', handleTooltipContentThrottled)
    const mouseOutEvent = google.maps.event.addListener(map, 'mouseout', handleTooltipHide)
    const dragStartEvent = google.maps.event.addListener(map, 'dragstart', handleTooltipHide)
    const zoomChangedEvent = google.maps.event.addListener(map, 'zoom_changed', handleTooltipHide)
    return () => {
      google.maps.event.removeListener(zoomChangedEvent)
      google.maps.event.removeListener(dragStartEvent)
      google.maps.event.removeListener(mouseOutEvent)
      google.maps.event.removeListener(mouseMoveContentEvent)
      google.maps.event.removeListener(mouseMoveEvent)
    }
  }, [map, markers])

  return (
    <Popper
      id="map-tooltip-popper"
      open={Boolean(tooltipAnchor)}
      anchorEl={tooltipAnchor}
      placement="auto-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [8, 8],
          },
        },
      ]}
      sx={{
        pointerEvents: 'none',
        zIndex: 2000,
      }}
      container={mapContainer}
    >
      <Paper elevation={4} sx={{ maxWidth: '20rem' }}>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {tooltips.map((tooltip) => (
            <Fragment key={tooltip.id}>
              {tooltip.content}
            </Fragment>
          ))}
        </Stack>
      </Paper>
    </Popper>
  )
}

export default KNMapTooltip
