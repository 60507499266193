import { useContext, ReactElement, Fragment } from 'react'

// @mui imports
import { SxProps } from '@mui/system'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

// KN imports
import { TripListContext } from 'context/trips/TripListContext'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'

export interface SecondaryActionsProps {
  actions: KNDataCardAction[]
  sx?: SxProps
}

const SecondaryActions = ({ actions, sx }: SecondaryActionsProps): ReactElement => {
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  return (
    <Stack spacing={1} direction="row" justifyContent="end" sx={sx}>
      {actions.map((action) => (
        <Fragment key={action.label}>
          <Tooltip title={action.label} enterDelay={500} placement="top" arrow>
            <IconButton
              size="small"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation()
                action.handler()
              }}
              disabled={tripListState.backgroundLoading}
              sx={{ color: 'primary.main' }}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        </Fragment>
      ))}
    </Stack>
  )
}

export default SecondaryActions
