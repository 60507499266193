import { ReactElement } from 'react'
import i18n from 'i18next'

// @mui imports
import Chip, { ChipProps } from '@mui/material/Chip'
import { Theme } from '@mui/material/styles/createTheme'

export interface TripStatusChipProps {
  status: string
}

export const getLabel = (status: string): string => {
  switch (status) {
    case 'NEW':
      return i18n.t('screens.cs.trip_dashboard.statuses.new')
    case 'TRIP_ASSIGNED':
      return i18n.t('screens.cs.trip_dashboard.statuses.assigned')
    case 'IN_PROGRESS':
      return i18n.t('screens.cs.trip_dashboard.statuses.in_progress')
    case 'DELIVERED':
      return i18n.t('screens.cs.trip_dashboard.statuses.delivered')
    case 'COMPLETED':
      return i18n.t('screens.cs.trip_dashboard.statuses.completed')
    default:
      return i18n.t('screens.cs.trip_dashboard.statuses.unknown')
  }
}

export const getComponentColor = (status: string): ChipProps['color'] => {
  switch (status) {
    case 'NEW':
      return 'secondary'
    case 'TRIP_ASSIGNED':
    case 'IN_PROGRESS':
      return 'primary'
    case 'DELIVERED':
      return 'success'
    case 'COMPLETED':
    default:
      return 'default'
  }
}

const TripStatusChip = ({ status }: TripStatusChipProps): ReactElement => {
  return <Chip data-test="trip-status" label={getLabel(status)} size="small" color={getComponentColor(status)} />
}

export default TripStatusChip
