import { useState, useEffect, ReactElement } from 'react'

// @mui imports
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import CircularProgress from '@mui/material/CircularProgress'

// KN imports
import { validateImage } from 'global/helpers/validators'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNFormFileItemProps } from './types'

const KNFormFileItem = ({
  file,
  pending,
  disabled,
  onDelete,
}: KNFormFileItemProps): ReactElement => {
  const [previewSrc, setPreviewSrc] = useState('')

  useEffect(() => {
    setPreviewSrc('')
    if (!pending && validateImage(file.type)) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setPreviewSrc(reader.result as string)
      }, false)
      reader.readAsDataURL(file)
    }
  }, [pending])

  return (
    <ListItem
      disableGutters
      disablePadding
      disabled={disabled}
      secondaryAction={
        onDelete && (
          <IconButton
            onClick={(event): void => onDelete()}
            sx={{
              marginTop: 1,
            }}
          >
            <ClearOutlinedIcon />
          </IconButton>
        )
      }
    >
      <ListItemButton
        dense
        sx={{
          padding: 0,
          marginTop: 1,
          borderRadius: 1,
        }}
        onClick={(): void => {
          window.open(URL.createObjectURL(file))
        }}
      >
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            sx={{ backgroundColor: 'primary.light' }}
            src={previewSrc}
          >
            {pending ? (
              <CircularProgress size={24} />
            ) : (
              <InsertDriveFileOutlinedIcon />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <KNTypography
              noWrap
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {file.name}
            </KNTypography>
          }
          sx={{
            margin: 0,
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default KNFormFileItem
