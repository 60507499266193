import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, ValidateResult } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import InfoIcon from '@mui/icons-material/Info'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import i18n from 'i18n'
import { compactZonedDate } from 'global/helpers/dateFormatters'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'
import KNFormDate from 'components/KN_Molecules/KNForm/KNFormDate'
import KNFormTime from 'components/KN_Molecules/KNForm/KNFormTime'
import KNFormSignature from 'components/KN_Molecules/KNForm/KNFormSignature'
import KNFormSwitch from 'components/KN_Molecules/KNForm/KNFormSwitch'
import {
  LegData,
  StopData,
  AvailableStopStatus,
  TripLogData
} from 'screens/TripDetails/TripDetails.types'
import { getTripLogs } from 'screens/TripDetails/TripDetails.service'

interface StatusUpdateFieldsPayload {
  tripId: string
  stop: StopData
  leg: LegData
  weblinkToken?: string
}

interface StatusUpdateFieldsProps {
  payload: StatusUpdateFieldsPayload
}

const StatusUpdateFields = ({ payload }: StatusUpdateFieldsProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const { watch, control, setValue, getValues, clearErrors } = useFormContext()
  const watchedStatusId = watch('statusId')
  const watchedPaperless = watch('paperless')
  const [selectedStatus, setSelectedStatus] = useState<AvailableStopStatus>()
  const [fromDate, setFromDate] = useState<TripLogData>()
  const [toDate, setToDate] = useState<TripLogData>()

  useEffect(() => {
    if (watchedStatusId) {
      const matchedStatus: AvailableStopStatus | undefined = payload.stop.availableStatuses.find(
        (status) => status.id === watchedStatusId
      )
      if (matchedStatus) {
        setSelectedStatus(matchedStatus)
        setValue('statusCode', matchedStatus.code)
        setValue('reasonCode', matchedStatus.reasonCode)
      } else {
        setSelectedStatus(undefined)
        setValue('statusCode', '')
        setValue('reasonCode', '')
      }
    } else {
      setSelectedStatus(undefined)
      setValue('statusCode', '')
      setValue('reasonCode', '')
    }
  }, [watchedStatusId])

  useEffect(() => {
    setValue('signature', null)
    clearErrors(['signee', 'signature'])
  }, [watchedPaperless])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const tripLogData = await getTripLogs(payload.tripId, payload.weblinkToken)
      const arrivedAtLocation: TripLogData[] = []
      const departedFromLocation: TripLogData[] = []
      for (const tripLog of tripLogData) {
        if (tripLog.data?.wayPointCid === payload.stop.wayPointCid) {
          switch (tripLog.type) {
            case 'ARRIVED_AT_LOCATION': {
              arrivedAtLocation.push(tripLog)
              break
            }
            case 'DEPARTED_FROM_LOCATION': {
              departedFromLocation.push(tripLog)
              break
            }
            case 'STATUS_UPDATED':
            default:
              break
          }
        }
        setFromDate(arrivedAtLocation[0])
        setToDate(departedFromLocation[departedFromLocation.length - 1])
      }
    }
    void fetchData()
  }, [])


  return (
    <Stack data-test="pickup-section" spacing={2}>
      <KNFormRadioGroup
        name="statusId"
        control={control}
        rules={{
          required: t('form.validation.status')
        }}
        options={payload.stop.availableStatuses.map((status) => {
          return {
            value: status.id,
            label: t(`waypoints.statuses.${status.id}`)
          }
        })}
      />
      {(!isMobile || !payload.weblinkToken) && (
        <Box data-test="date-container">
          <Grid container spacing={2}>
            <Grid data-test="execution-date" item xs={6}>
              <KNFormDate
                name="createdAtDate"
                label={t('screens.cs.trip_details.update_status.execution_date')}
                control={control}
                rules={{
                  required: t('form.validation.required')
                }}
              />
            </Grid>
            <Grid data-test="execution-time" item xs={6}>
              <KNFormTime
                name="createdAtTime"
                label={t('screens.cs.trip_details.update_status.execution_time')}
                control={control}
                rules={{
                  required: t('form.validation.required')
                }}
              />

            </Grid>
          </Grid>

          {(toDate && fromDate) ? <KNCaption color="#003369" icon={<InfoIcon />} sx={{ marginTop: '0.5rem' }}>
              {i18n.t(payload.stop.type === 'PUP' ?
                  'screens.cs.trip_details.update_status.truck_location_pickup_zone_message' :
                  'screens.cs.trip_details.update_status.truck_location_delivery_zone_message',
                {
                  radius: '500m',
                  fromDate: compactZonedDate(fromDate.createdAt, fromDate.data?.offset),
                  toDate: compactZonedDate(toDate.createdAt, toDate.data?.offset)
                })}
            </KNCaption>
            : ((toDate && !fromDate) || (!toDate && fromDate)) &&
            <KNCaption color="#003369" icon={<InfoIcon />} sx={{ marginTop: '0.5rem' }}>
              {i18n.t((toDate && !fromDate) ?
                  'screens.cs.trip_details.update_status.truck_location_departed_zone_message' :
                  'screens.cs.trip_details.update_status.truck_location_arrived_zone_message',
                {
                  radius: '500m',
                  date: (toDate && !fromDate) ? compactZonedDate(toDate.createdAt, toDate.data?.offset) : (!toDate && fromDate) ?
                    compactZonedDate(fromDate.createdAt, fromDate.data?.offset) : ''
                })
              }
            </KNCaption>
          }

        </Box>
      )}
      {selectedStatus && selectedStatus.comments !== 'DISABLED' && (
        <KNFormText
          name="comment"
          label={
            selectedStatus.comments === 'REQUIRED'
              ? t('screens.cs.trip_details.update_status.remarks')
              : t('screens.cs.trip_details.update_status.remarks_optional')
          }
          control={control}
          rows={2}
          rules={{
            maxLength: {
              value: 70,
              message: t('screens.cs.trip_details.update_status.comment_length')
            },
            validate: {
              commentsRequired: (value): ValidateResult => {
                if (!value && selectedStatus.comments === 'REQUIRED') {
                  return t('form.validation.required')
                }
                return true
              }
            }
          }}
        />
      )}
      {selectedStatus && selectedStatus.signee !== 'DISABLED' && (
        <KNFormText
          name="signee"
          label={
            selectedStatus.signee === 'REQUIRED' || getValues('paperless')
              ? t('screens.cs.trip_details.update_status.signee')
              : t('screens.cs.trip_details.update_status.signee_optional')
          }
          control={control}
          rules={{
            maxLength: {
              value: 70,
              message: t('screens.cs.trip_details.update_status.signee_length')
            },
            validate: {
              signeeRequired: (value): ValidateResult => {
                if (!value && (selectedStatus.signee === 'REQUIRED' || getValues('paperless'))) {
                  return t('form.validation.required')
                }
                return true
              }
            }
          }}
        />
      )}
      {isMobile && payload.weblinkToken && payload.leg.paperlessAvailable && (
        <>
          <Stack>
            <KNFormSwitch
              name="paperless"
              label={t('screens.cs.trip_details.update_status.paperless')}
              control={control}
              disabled={payload.stop.isProofAttached}
            />
            {payload.stop.isProofAttached && (
              <KNCaption icon={<InfoIcon />}>
                {t('screens.cs.trip_details.upload_document.proof_already_attached')}
              </KNCaption>
            )}
          </Stack>
          <Collapse in={watchedPaperless} unmountOnExit>
            <KNFormSignature
              name="signature"
              control={control}
              rules={{
                required: t('form.validation.required')
              }}
            />
          </Collapse>
        </>
      )}
    </Stack>
  )
}

export default StatusUpdateFields
