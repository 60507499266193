import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Grid from '@mui/material/Grid'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormPhone from 'components/KN_Molecules/KNForm/KNFormPhone'
import { regexEmail } from 'global/helpers/validators'
import { DriverData } from './DriverManager.types'
import { createDriver, editDriver } from './DriverManager.service'
import { getDataForCreateDriver, getDataForEditDriver } from './DriverManager.helpers'

interface CreateOrEditDriverDialogPayload {
  driver: DriverData
}

interface CreateOrEditDriverDialogProps {
  payload?: CreateOrEditDriverDialogPayload
  open: boolean
  onAction: (updatedDriver: DriverData, action: string) => void
  onClose: () => void
}

export interface CreateOrEditDriverFormValues {
  name: string
  contactNumber: string
  email: string
  internalId?: string
}

const CreateOrEditDriverDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: CreateOrEditDriverDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, getValues, setError } = useForm<CreateOrEditDriverFormValues>()

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          name: payload?.driver.name,
          contactNumber: payload?.driver.contactNumber,
          email: payload?.driver.email,
          internalId: payload?.driver.cid,
        })
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<CreateOrEditDriverFormValues> = async (data: CreateOrEditDriverFormValues) => {
    try {
      if (payload) {
        await editDriver(data)
        onAction(getDataForEditDriver(payload.driver, data), 'edit')
        analyticsEvent('polestar_cs_driver_updated')
      } else {
        await createDriver(data)
        onAction(getDataForCreateDriver(data), 'create')
        analyticsEvent('polestar_cs_driver_created')
      }
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={
        payload
          ? `${payload.driver.name} — ${t('screens.cs.driver_manager.card.actions.edit')}`
          : t('screens.cs.driver_manager.card.actions.new')
      }
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {payload
              ? t('screens.cs.driver_manager.add_edit_driver.update')
              : t('screens.cs.driver_manager.add_edit_driver.create')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <Grid container spacing={2}>
        <Grid data-test="name-input" item xs={12}>
          <KNFormText
            name="name"
            label={t('screens.cs.driver_manager.add_edit_driver.name')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
          />
        </Grid>
        <Grid data-test="phone-input" item xs={12} sm={6}>
          <KNFormPhone
            name="contactNumber"
            label={t('screens.cs.driver_manager.add_edit_driver.phone')}
            control={control}
            rules={{
              validate: {
                phoneOrEmail: (value) =>
                  value || getValues('email')
                    ? true
                    : t('screens.cs.driver_manager.add_edit_driver.phone_or_email_required'),
              },
            }}
            getValues={getValues}
          />
        </Grid>
        <Grid data-test="email-input" item xs={12} sm={6}>
          <KNFormText
            name="email"
            label={t('screens.cs.driver_manager.add_edit_driver.email')}
            control={control}
            rules={{
              pattern: {
                value: regexEmail,
                message: t('form.validation.invalid_email'),
              },
              validate: {
                phoneOrEmail: (value) =>
                  value || getValues('contactNumber')
                    ? true
                    : t('screens.cs.driver_manager.add_edit_driver.phone_or_email_required'),
              },
            }}
          />
        </Grid>
      </Grid>
    </KNDialog>
  )
}

export default CreateOrEditDriverDialog
