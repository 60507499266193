import i18n from 'i18n'

export const truckNorrisTranslations = (): Record<any, any> => {
  const truckNorris = 'shared.truck_norris'
  return {
    translation: {
      msg: i18n.t(`${truckNorris}.msg`),
      access_denied: i18n.t(`${truckNorris}.access_denied`),
      back: i18n.t(`${truckNorris}.back`),
    },
  }
}
