import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import KNSwitch from 'components/KN_Components/Base/KNSwitch/KNSwitch'
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormSwitchProps } from './types'

const KNFormSwitch = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  size,
  sx,
  disabled,
  onChange,
}: KNFormSwitchProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({
      field: { onChange: formOnChange, value, name, ref },
      fieldState: { invalid, error },
      formState,
    }): ReactElement => (
      <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting || disabled}>
        <FormControlLabel
          control={
            <KNSwitch
              checked={!!value}
              onChange={(): void => {
                formOnChange(!value)
                onChange?.()
              }}
              disabled={disabled}
            />
          }
          label={label}
        />
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormSwitch
