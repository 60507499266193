import { useState, useCallback } from 'react'

interface PopoverState {
  anchorEl: HTMLElement | null
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  handleClose: () => void
  open: boolean
}

const usePopoverState = (): PopoverState => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])
  const open = Boolean(anchorEl)
  return { anchorEl, open, handleClick, handleClose }
}

export default usePopoverState
