import { keyframes } from '@mui/system'
import { alpha } from '@mui/material/styles'

import theme from 'assets/theme'

export const outlineAnimation = (repeat = 2): string => {
  const outlineKeyframes = keyframes`
    0% {
      outline: 2px solid ${alpha(theme.palette.secondary.main, 0)};
      outline-offset: 8px;
    }
    20% {
      outline: 2px solid ${alpha(theme.palette.secondary.main, 1)};
      outline-offset: 12px;
    }
    100% {
      outline: 2px solid ${alpha(theme.palette.secondary.main, 0)};
      outline-offset: 16px;
    }
  `
  return `${outlineKeyframes} 600ms ease-in-out 0s ${repeat}`
}

export const flashAnimation = (): string => {
  const flashframes = keyframes`
    0%, 49% {
      fill: ${theme.palette.primary.main};
    }
    50%, 100% {
      fill: ${theme.palette.success.main};
    }
  `
  return `${flashframes} 1600ms infinite`
}

export const pulseAnimation = (): string => {
  const pulseFrames = keyframes`  
    0% {
      transform: scale(.6);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.6);
    }
  `
  return `${pulseFrames} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`
}
