import colors from 'assets/theme/base/colors'

const { secondary } = colors

type Types = any

const link: Types = {
  defaultProps: {
    underline: 'none',
  },
  variants: [
    {
      props: { variant: 'underlined' },
      style: {
        color: secondary.main,
        position: 'relative',
        '&::after': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '1px',
          backgroundColor: 'currentColor',
        },
      },
    },
  ],
}

export default link
