import { fetchGet } from 'global/helpers/fetchWrapper'
import { MessageData } from './MessageManager.types'

// eslint-disable-next-line no-useless-escape
const errorsPattern = /(\w+\([^\)]+\))/g

export const getRejectedMessages = async (): Promise<MessageData[]> =>
  (await fetchGet('rejected-messages')).map((message) => {
    return {
      uuid: message.messageId,
      date: message.timestamp,
      errors: message.errorMessage?.match(errorsPattern) ?? [message.errorMessage],
    }
  })
