import rgba from 'assets/theme/functions/rgba'
import colors from 'assets/theme/base/colors'

const globals = {
  html: {
    scrollBehavior: 'smooth',
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  'input:-webkit-autofill, input:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s',
  },
  'input[data-autocompleted]': {
    backgroundColor: 'transparent !important',
  },
  '.MuiTypography-root small': {
    verticalAlign: 'top',
  },
  '.hoverable': {
    '.hover-opacity': {
      opacity: 0,
      transition: 'opacity 100ms ease-out',
    },
    '.hover-display-flex': {
      display: 'none',
    },
    '.hover-link': {
      position: 'relative',
    },
    '&:hover, &.forced-hover': {
      '.hover-opacity': {
        opacity: 1,
      },
      '.hover-display-flex': {
        display: 'flex',
      },
      '.hover-link': {
        color: colors.secondary.main,
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: '3px',
          left: 0,
          width: '100%',
          height: '1px',
          backgroundColor: 'currentColor',
        },
      },
    },
  },
  'em': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontStyle: 'inherit',
    lineHeight: 'inherit',
    backgroundColor: rgba(colors.warning.main, 0.33),
  },
}

export default globals
