import i18n from '../../i18n'
import { parseISO, isEqual, isBefore } from 'date-fns'

// KN imports
import { fetchGet, fetchPost, fetchDelete, weblinkHeaders, getChunkedResults } from 'global/helpers/fetchWrapper'
import { PaginatedResults } from 'global/types/pagination'
import { LegData } from 'screens/TripDetails/TripDetails.types'
import { processShipmentLeg, sortShipmentLegs } from 'screens/TripDetails/TripDetails.service'
import { CountersData, TripData } from './TripDashboard.types'
import { ShareViaEmailFormValues, ShareViaSmsFormValues } from './ShareDialog'
import { SmsDriverFormValues } from './SmsDriverDialog'
import { AssignDriverFormValues } from './AssignDriverDialog'
import { AssignVehicleFormValues } from './AssignVehicleDialog'

export interface ShareTokenResponse {
  weblinkToken: string
}

export const getCounters = async (): Promise<CountersData> => await fetchGet('trips/filter/counters')

export const getPaginatedTrips = async (
  page = 0,
  pageSize = 100,
  weblinkToken: string | null = null
): Promise<PaginatedResults<TripData>> => {
  const trips = await fetchGet(`trips?page=${page}&size=${pageSize}`, weblinkHeaders(weblinkToken))
  trips.content = trips.content.map(processTrip)
  return trips
}

export const getTrips = async (weblinkToken: string | null = null): Promise<TripData[]> => {
  const getPaginatedTripsWrapper = (page: number, pageSize: number) => getPaginatedTrips(page, pageSize, weblinkToken)
  const trips = await getChunkedResults<TripData>(getPaginatedTripsWrapper)
  return trips.sort(sortTrips)
}

export const getPaginatedFilteredTrips = async (
  page = 0,
  pageSize = 1000,
  predefinedFilter: string,
  weblinkToken: string | null = null
): Promise<PaginatedResults<TripData>> => {
  const trips = await fetchGet(
    `trips/filter/${predefinedFilter}?page=${page}&size=${pageSize}`,
    weblinkHeaders(weblinkToken)
  )
  trips.content = trips.content.map(processTrip)
  return trips
}

export const getFilteredTrips = async (
  predefinedFilter: string,
  weblinkToken: string | null = null
): Promise<TripData[]> => {
  const getPaginatedFilteredTripsWrapper = (page: number, pageSize: number) =>
    getPaginatedFilteredTrips(page, pageSize, predefinedFilter, weblinkToken)
  const trips = await getChunkedResults<TripData>(getPaginatedFilteredTripsWrapper)
  return trips.sort(sortTrips)
}

export const getTrip = async (tripCid: string, weblinkToken: string | null = null): Promise<TripData> =>
  processTrip(await fetchGet(`trips/${tripCid}`, weblinkHeaders(weblinkToken)))

export const getLegsForTrips = async (tripCids: string[]): Promise<LegData[]> => {
  const legs = await fetchPost(`trips/shipmentlegs`, {
    tripCids,
  })
  return legs.map(processShipmentLeg).sort(sortShipmentLegs)
}

export const deleteTrip = async (tripCid: string): Promise<void> =>
  await fetchDelete(`trips/${tripCid}`)

export const getShareToken = async (tripCid: string, weblinkToken: string | null = null): Promise<ShareTokenResponse> =>
  await fetchGet(`trips/${tripCid}/weblink`, weblinkHeaders(weblinkToken))

export const shareTokenViaEmail = async (
  tripCid: string,
  data: ShareViaEmailFormValues,
  weblinkToken: string | null = null
): Promise<void> => await fetchPost(`trips/${tripCid}/weblink/mail`, data, weblinkHeaders(weblinkToken))

export const shareTokenViaSms = async (
  tripCid: string,
  data: ShareViaSmsFormValues,
  weblinkToken: string | null = null
): Promise<void> => await fetchPost(`trips/${tripCid}/weblink/sms`, data, weblinkHeaders(weblinkToken))

export const sendSmsToDriver = async (tripCid: string, data: SmsDriverFormValues): Promise<void> =>
  await fetchPost(`trips/${tripCid}/weblink/smsDriver`, data)

export const assignDriver = async (tripCid: string, data: AssignDriverFormValues): Promise<void> =>
  await fetchPost(`assignDriver?tripCid=${tripCid}&driverCid=${data.driverCid!}`)

export const assignVehicle = async (tripCid: string, data: AssignVehicleFormValues): Promise<void> => {
  if (data.withTrailer) {
    const processedData = {
      truckLicensePlate: data.licensePlate,
      truckIsTrackable: data.isTrackable,
      trailerLicensePlate: data.trailerLicensePlate,
      trailerIsTrackable: data.trailerIsTrackable,
    }
    return await fetchPost(`trips/${tripCid}/truck-and-trailer`, processedData)
  }
  const processedData = {
    licensePlate: data.licensePlate,
    isTrackable: data.isTrackable,
  }
  return await fetchPost(`trips/${tripCid}/vehicle`, processedData)
}

const processTrip = (trip: TripData): TripData => {
  // NOTE: backwards compatibility
  if (!trip.voyageNumber) {
    trip.voyageNumber = trip.tripNumber
  }
  // NOTE: backwards compatibility
  if (!trip.dangerousGoodsDetails) {
    trip.dangerousGoodsDetails = []
  }
  trip.countryPickUpPlace = i18n.t(`countries.${trip.countryCodePickUpPlace}`)
  trip.countryDeliveryPlace = i18n.t(`countries.${trip.countryCodeDeliveryPlace}`)
  trip.flags?.sort(sortTripFlags)
  return trip
}

const sortTrips = (a, b): number => {
  const firstDate = parseISO(a.creationDate)
  const secondDate = parseISO(b.creationDate)
  const date = isEqual(firstDate, secondDate) ? 0 : isBefore(firstDate, secondDate) ? 1 : -1
  if (date !== 0) {
    return date
  }
  return a.voyageNumber === b.voyageNumber ? 0 : a.voyageNumber < b.voyageNumber ? 1 : -1
}

const sortTripFlags = (a, b): number => {
  const flagsOrder = ['REFUSED', 'SHORT', 'DAMAGED', 'UPDATE_STATUS', 'POP_MISSING', 'POD_MISSING', 'IMAGE_MISSING']
  const aSequence = flagsOrder.indexOf(a.name)
  const bSequence = flagsOrder.indexOf(b.name)
  return aSequence === bSequence ? 0 : aSequence < bSequence ? -1 : 1
}
