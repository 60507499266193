import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'
import { DriverData } from 'screens/DriverManager/DriverManager.types'

export interface TripFiltersValues {
  keywords?: string[]
  status?: string[]
  onlyRelatedTrips?: boolean
}

export interface TripMapState {
  filters: TripFiltersValues
  filtersDirty: boolean
  activeLocationGroup: number | string | null
  relatedTripCids: string[]
}

export type TripMapAction =
  | { type: 'setState'; payload: Partial<TripMapState> }
  | { type: 'setFilters'; payload: TripFiltersValues }
  | { type: 'resetFilters' }
  | { type: 'setActiveLocationGroup'; payload: string | null }
  | { type: 'forceActiveLocationGroup'; payload: string | null }
  | { type: 'setRelatedTripCids'; payload: string[] }
  | { type: 'clearRelatedTripCids' }

const processFilters = (currentFilters: TripFiltersValues, extendedFilters?: TripFiltersValues) => {
  const filters = { ...currentFilters, ...extendedFilters }
  if (filters.status) {
    filters.status = filters.status.sort()
  }
  return filters
}

const initialFilters: TripFiltersValues = processFilters({
  keywords: [],
  status: ['NEW', 'TRIP_ASSIGNED', 'IN_PROGRESS', 'DELIVERED'],
  onlyRelatedTrips: false,
})

const initialState: TripMapState = {
  filters: initialFilters,
  filtersDirty: false,
  activeLocationGroup: null,
  relatedTripCids: [],
}

const reducer = (state: TripMapState, action: TripMapAction): TripMapState => {
  switch (action.type) {
    case 'setState': {
      const filters = processFilters(state.filters, action.payload.filters)
      return {
        ...state,
        ...action.payload,
        filters,
        filtersDirty: JSON.stringify(filters) !== JSON.stringify(initialFilters),
      }
    }
    case 'setFilters': {
      const filters = processFilters(state.filters, action.payload)
      return {
        ...state,
        filters,
        filtersDirty: JSON.stringify(filters) !== JSON.stringify(initialFilters),
      }
    }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
      }
    case 'setActiveLocationGroup':
      return {
        ...state,
        // make setting active marker toggleable and reset do default when unchecked
        activeLocationGroup: state.activeLocationGroup !== action.payload ? action.payload : initialState.activeLocationGroup,
        filters: {
          ...state.filters,
          onlyRelatedTrips: false,
        },
      }
    case 'forceActiveLocationGroup':
      return {
        ...state,
        // forces active marker, no matter which one is already active
        activeLocationGroup: action.payload,
        filters: {
          ...state.filters,
          onlyRelatedTrips: false,
        },
      }
    case 'setRelatedTripCids':
      return {
        ...state,
        relatedTripCids: action.payload,
      }
    case 'clearRelatedTripCids':
      return {
        ...state,
        relatedTripCids: [],
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as TripMapContext, StoreProvider as TripMapProvider }
