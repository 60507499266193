import { ReactElement } from 'react'

// @mui imports
import MenuItem from '@mui/material/MenuItem'

import { KNMenuItemProps } from './types'

const KNMenuItem = ({
  label,
  icon,
  onClick,
}: KNMenuItemProps): ReactElement => (
  <MenuItem onClick={onClick}>
    {icon}
    {label}
  </MenuItem>
)

export default KNMenuItem
