import { useEffect, useState, useCallback, ReactElement, ElementType } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import useSuppressFlag from 'components/KN_Molecules/KNDialog/useSuppressFlag'
import RequestAccountDialog from 'screens/Weblink/RequestAccountDialog'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

export interface RequestAccountPromoProps {
  weblinkToken: string
}

export const BulletList = styled(List)<{ component?: ElementType }>({
  '&': {
    listStyleType: 'disc',
    listStylePosition: 'inside',
    padding: 0,
  },
  '& .MuiListItem-root': {
    display: 'list-item',
    padding: 0,
  },
})

const RequestAccountPromo = ({ weblinkToken }: RequestAccountPromoProps): ReactElement => {
  const { t } = useTranslation()
  const { suppressForDays, isSuppressed } = useSuppressFlag('skip-request-account')
  // const [showPromo, setShowPromo] = useState(!isSuppressed)
  const [showPromo, setShowPromo] = useState(false) // popup temporarily disabled
  const [requestAccountDialogOpen, setRequestAccountDialogOpen] = useState(false)

  useEffect(() => {
    if (showPromo) {
      setRequestAccountDialogOpen(true)
    }
  }, [showPromo])

  const handleRequestAccountClick = useCallback((): void => setRequestAccountDialogOpen(true), [])
  const handleRequestAccountAction = useCallback((): void => {
    setRequestAccountDialogOpen(false)
  }, [])
  const handleRequestAccountClose = useCallback((): void => {
    if (showPromo) {
      suppressForDays(7)
      setShowPromo(false)
      analyticsEvent('polestar_cs_account_request_skipped')
    }
    setRequestAccountDialogOpen(false)
  }, [])

  return (
    <>
      <Box>
        <KNTypography color="primary.dark" variant="displayXXS_SB">
          {t('screens.cs.weblink.request_account_promo.header')}
        </KNTypography>
        <KNTypography color="primary.light">{t('screens.cs.weblink.request_account_promo.intro')}</KNTypography>
        <BulletList sx={{ color: 'primary.light' }}>
          <ListItem>{t('screens.cs.weblink.request_account_promo.bullet_1')}</ListItem>
          <ListItem>{t('screens.cs.weblink.request_account_promo.bullet_2')}</ListItem>
          <ListItem>{t('screens.cs.weblink.request_account_promo.bullet_3')}</ListItem>
        </BulletList>
        <KNTypography color="primary.light">{t('screens.cs.weblink.request_account_promo.outro')}</KNTypography>
        <KNButton
          onClick={handleRequestAccountClick}
          data-test="request-account-button"
          variant="contained"
          color="secondary"
          size="small"
          sx={{
            marginTop: 2,
            width: '100%',
          }}
        >
          {t('screens.cs.weblink.request_account.request_account')}
        </KNButton>
      </Box>

      <RequestAccountDialog
        payload={{
          weblinkToken,
          promo: showPromo,
        }}
        open={requestAccountDialogOpen}
        onAction={handleRequestAccountAction}
        onClose={handleRequestAccountClose}
      />
    </>
  )
}

export default RequestAccountPromo
