import { fetchDelete, fetchGet, fetchPost } from 'global/helpers/fetchWrapper'
import { DriverData } from './DriverManager.types'
import { CreateOrEditDriverFormValues } from './CreateOrEditDriverDialog'

export const getDrivers = async (): Promise<DriverData[]> => {
  const drivers = await fetchGet('drivers')
  return drivers.sort(sortDrivers)
}

export const createDriver = async (data: CreateOrEditDriverFormValues): Promise<void> =>
  await fetchPost('drivers', data)

// NOTE: for now looks the same as createDriver above
// TODO: make backend endpoint restful
export const editDriver = async (data: CreateOrEditDriverFormValues): Promise<void> => await fetchPost('drivers', data)

export const deleteDriver = async (driverCid: string): Promise<void> => await fetchDelete(`drivers/${driverCid}`)

export const sortDrivers = (a, b): number => {
  // NOTE: makes comparison case insensitive
  const lowercaseA = a.name?.toLowerCase() || ''
  const lowercaseB = b.name?.toLowerCase() || ''
  return lowercaseA === lowercaseB ? 0 : lowercaseA < lowercaseB ? -1 : 1
}
