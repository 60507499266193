import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'

const { primary } = colors
const { borderWidth } = borders

// types
type Types = any

const stepConnector: Types = {
  styleOverrides: {
    root: {
      color: primary.light,
      transition: 'all 200ms linear',

      '&.Mui-active': {
        color: primary.main,
      },

      '&.Mui-completed': {
        color: primary.main,
      },
    },

    line: {
      borderWidth: `${borderWidth[2]} !important`,
      borderRadius: `${borderWidth[2]}`,
      borderColor: 'currentColor',
      opacity: 1,
    },
  },
}

export default stepConnector
