import { useState, forwardRef, useImperativeHandle } from 'react'

// @mui imports
import Tooltip from '@mui/material/Tooltip'

// KN imports
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNTooltipButtonProps, { KNTooltipButtonHandle } from './types'

const KNTooltipButton = forwardRef<KNTooltipButtonHandle, KNTooltipButtonProps>(({
  children,
  tooltip,
  variant,
  size,
  color,
  disabled,
  loading,
  sx,
  onClick,
}, ref) => {
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    showTooltip (): void {
      setOpen(true)
    },
  }))

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <Tooltip
      title={tooltip}
      open={open}
      arrow
      placement="top"
      disableInteractive
      leaveDelay={1500}
      onClose={handleClose}
    >
      <KNLoadingButton
        variant={variant}
        size={size}
        color={color}
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        sx={sx}
      >
        {children}
      </KNLoadingButton>
    </Tooltip>
  )
})

export default KNTooltipButton
