import { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

// KN imports
import { hasHighlights, replaceHighlights } from 'global/helpers/highlights'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { KNDataTableOverflowProps } from './types'

const itemWrapper = (item) => (
  <KNTypography
    component="div"
    color="inherit"
    fontSize="0.75rem"
    sx={{
      paddingX: 2,
      paddingY: 0.25,
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: 'primary.dark',
      },
    }}
  >
    {item}
  </KNTypography>
)

const KNDataTableOverflow = <T extends object>({
  row,
  label,
  header,
  items,
  limit = 1,
  rightAligned,
  withoutWrapper,
  highlights,
}: KNDataTableOverflowProps<T>): ReactElement | null => {
  const [processedItems, setProcessedItems] = useState(items)
  const [isHighlighted, setIsHighlighted] = useState(false)
  const {
    anchorEl: moreItemsAnchorEl,
    open: moreItemsOpen,
    handleClick: handleMoreItemsClick,
    handleClose: handleMoreItemsClose,
  } = usePopoverState()

  const handlePropagation = useCallback((event) => event.stopPropagation(), [])
  const { t } = useTranslation()

  if (items.length === 0) {
    return <KNTypography color="primary.light">{t('general.none')}</KNTypography>
  }

  useEffect(() => {
    let isHighlighted = false
    setProcessedItems(items.map((item) => {
      // don't override isHighlighted if it's already set to true
      isHighlighted = isHighlighted || hasHighlights(item, highlights)
      return replaceHighlights(item, highlights)
    }))
    setIsHighlighted(isHighlighted)
  }, [items, highlights])

  return (
    <Box onClick={handlePropagation}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Stack data-test="shipmentId" direction="column">
          {processedItems.slice(0, limit).map((item, index) => (
            <Box key={index}>{item}</Box>
          ))}
        </Stack>
        {processedItems.length > limit && (
          <Tooltip title={label} arrow enterDelay={500}>
            <Chip
              data-test="shipmentMoreIcon"
              label={`+${items.length - limit}`}
              size="small"
              onClick={handleMoreItemsClick}
              color={isHighlighted ? 'warning' : undefined}
            />
          </Tooltip>
        )}
      </Stack>
      {processedItems.length > limit && (
        <KNDetailsPopover
          open={moreItemsOpen}
          onClose={handleMoreItemsClose}
          anchorEl={moreItemsAnchorEl}
          rightAligned={rightAligned}
        >
          <KNDetailsPopoverSection header={header} items={withoutWrapper ? processedItems : processedItems.map(itemWrapper)} />
        </KNDetailsPopover>
      )}
    </Box>
  )
}

export default KNDataTableOverflow
