import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import { useTheme } from '@mui/material/styles'

// KN Components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import { TripMapContext } from 'context/trips/TripMapContext'
import QuickStatusUpdateDialog from 'screens/StatusManager/QuickStatusUpdateDialog'
import TripHeader from './TripHeader'
import TripCargo from './TripCargo'

// Types
import { TripData } from './TripDashboard.types'

interface TripMapListProps {
  trips: TripData[]
  onChange?: (panel: string, opening: boolean) => void
}

const TripMapList = ({ trips, onChange }: TripMapListProps): ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [tripMapState, tripMapDispatch] = useContext(TripMapContext)
  const [activeTrip, setActiveTrip] = useState<TripData | null>(null)
  const [quickStatusUpdateDialogOpen, setQuickStatusUpdateDialogOpen] = useState(false)

  const handleQuickStatusUpdateClick = useCallback((event) => {
    event.stopPropagation()
    setQuickStatusUpdateDialogOpen(true)
  }, [])

  const handleQuickStatusUpdateDialogAction = useCallback((updatedTrip: TripData): void => {
    setQuickStatusUpdateDialogOpen(false)
    // onChange?.(updatedTrip, 'quick_status_update')
  }, [])

  const handleQuickStatusUpdateDialogClose = useCallback((): void => {
    setQuickStatusUpdateDialogOpen(false)
  }, [])

  return (
    <>
      <List disablePadding sx={{ marginLeft: -2, marginRight: -2 }}>
        {trips.map((trip) => (
          <ListItem
            key={trip.entityId}
            disablePadding
            divider
            sx={{ opacity: tripMapState.relatedTripCids.includes(trip.entityId) || tripMapState.relatedTripCids.length === 0 ? 1 : 0.5 }}
            className="hoverable"
          >
            <ListItemButton
              onClick={(): void => {
                const opening = activeTrip?.entityId !== trip.entityId
                setActiveTrip(opening ? trip : null)
                onChange?.(trip.entityId, opening)
              }}
            >
              <Stack spacing={0.5} sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  alignItems="start"
                  justifyContent="space-between"
                >
                  <TripHeader trip={trip} linked stacked withPropagation />
                  <ExpandMoreIcon
                    data-test="expand-icon"
                    sx={{
                      color: 'text.main',
                      transform: activeTrip?.entityId === trip.entityId ? 'rotate(-180deg)' : 'rotate(0deg)',
                      marginLeft: 'auto',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    }}
                  />
                </Stack>
                <Collapse in={activeTrip?.entityId === trip.entityId}>
                  <Stack direction="column" spacing={1} alignItems="end">
                    <TripCargo trip={trip} sx={{ width: '100%' }} />
                    <KNButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleQuickStatusUpdateClick}
                    >
                      {t('screens.cs.trip_dashboard.card.actions.quick_update')}
                    </KNButton>
                  </Stack>
                </Collapse>
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {activeTrip && (
        <QuickStatusUpdateDialog
          payload={{
            trip: activeTrip,
          }}
          open={quickStatusUpdateDialogOpen}
          onAction={handleQuickStatusUpdateDialogAction}
          onClose={handleQuickStatusUpdateDialogClose}
        />
      )}
    </>
  )
}

export default TripMapList
