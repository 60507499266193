import { ReactElement } from 'react'
import './errornotfound.css'

const TruckNorrisNotFound = (): ReactElement | null => {
  return (
    <div style={{ width: '600px', height: '400px', position: 'relative', overflow: 'hidden' }}>
      <div className="background">
        <div className="road"></div>
        <div className="truck"></div>
        <div className="wheel wheel1"></div>
        <div className="wheel wheel2"></div>
        <div className="wheel wheel3"></div>
        <div className="wheel wheel4"></div>
      </div>
    </div>
  )
}

export default TruckNorrisNotFound
