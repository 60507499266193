import { fetchPost, weblinkHeaders } from 'global/helpers/fetchWrapper'
import { RequestAccountFormValues } from './RequestAccountDialog'
import { ClientJS } from 'clientjs'

export const getWeblinkToken = async (token: string): Promise<any> => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wl`, options)
  const text = await response.text()
  const data = text && JSON.parse(text)
  if (!response.ok) {
    const error = data?.message || response.statusText
    return Promise.reject(error)
  }
  return data
}

export const sendFingerprint = async (cid: string): Promise<void> => {
  const client = new ClientJS()
  const fingerprint = client.getFingerprint()

  const fingerprintData = {
    fp: fingerprint,
    cid: cid,
  }
  await fetchPost(`fp`, fingerprintData, {})
}

export const requestAccountInWeblink = async (data: RequestAccountFormValues, token: string): Promise<void> =>
  await fetchPost('signup', data, weblinkHeaders(token))
