import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'
import CompanyTypeLabel from 'screens/CompanyManager/CompanyTypeLabel'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { revokeRole } from './UserManager.service'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import { sleep } from 'global/helpers/sleep'

// Types
import { UserData } from './UserManager.types'
import { Role } from 'context/authentication/Role.types'

interface RevokeRoleDialogPayload {
  user: UserData
}

interface RevokeRoleDialogProps {
  payload: RevokeRoleDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface RevokeRoleFormValues {
  companyCid: string
  role: Role
}

const RevokeRoleDialog = ({ payload, open, onAction, onClose }: RevokeRoleDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, watch, reset, control, formState, setValue, setError } = useForm<RevokeRoleFormValues>()
  const watchedCompanyCid = watch('companyCid')

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          companyCid: null,
          role: null,
        })
      )
    }
  }, [open])

  useEffect(() => {
    // NOTE: matching company exists for sure at this point, hence the "!"
    const matchedCompany = payload.user.companies!.find((company) => company.cid === watchedCompanyCid)
    if (matchedCompany) {
      setValue('role', matchedCompany.role)
    }
  }, [watchedCompanyCid])

  const onSubmit: SubmitHandler<RevokeRoleFormValues> = async (data: RevokeRoleFormValues) => {
    try {
      await revokeRole(payload.user.cid, data)
      // NOTE: avoid stale data
      await sleep(500)
      analyticsEvent('polestar_user_role_revoked')
      onAction()
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={`${payload.user.displayName} — ${t('screens.shared.user_manager.revoke_role.revoke_role')}`}
        actions={
          <>
            <KNLoadingButton
              data-test="revoke-button"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('screens.shared.user_manager.revoke_role.revoke')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Stack spacing={2}>
          <KNFormRadioGroup
            name="companyCid"
            label={t('screens.shared.user_manager.revoke_role.company')}
            control={control}
            rules={{
              required: t('form.validation.required'),
            }}
            options={
              payload.user.companies
                ? payload.user.companies.map((company) => {
                    return {
                      value: company.cid,
                      label: (
                        <Stack spacing={1} direction="row" alignItems="center">
                          <CompanyTypeLabel type={company.type} compact />
                          <Box>{company.displayName}</Box>
                          <KNTypography variant="textMD_SB" color="primary.light">
                            {t(`screens.shared.user_manager.roles.${company.role as string}`)}
                          </KNTypography>
                        </Stack>
                      ),
                    }
                  })
                : []
            }
          />
        </Stack>
      </KNDialog>
    </KNForm>
  )
}

export default RevokeRoleDialog
