export interface BruceBannerProps {
  moduleName: 'cv' | 'cs' | 'global'
}

export enum BruceBannerType {
  downtime = 'downtime',
  update = 'update',
  maintenance = 'maintenance',
}

export interface BruceBannerInputProps {
  title: string
  type: BruceBannerType
  showLink: boolean
  updateDate: number
  liveDate?: number
  visibleUntil: number
}

export interface BruceBannerMessageProps {
  title: string
  type: BruceBannerType
  showLink: boolean
  updateDate: number
  liveDate?: number
  visibleUntil: number
  visible: boolean
  visualDate: string | 0
  visualLiveDate: string | 0
  pastToday: boolean
}
