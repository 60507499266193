import { Role } from './Role.types'

export enum CompanyType {
  Carrier = 'Carrier',
  Customer = 'Customer',
  Operator = 'Operator',
}

export enum VisibilityLevel {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Max = 'Max',
}

export enum CompanyModule {
  road_shipments = 'road_shipments',
  air_shipments = 'air_shipments',
  location = 'location',
  temperature = 'temperature',
  temperature_prediction = 'temperature_prediction',
  polestar_tro = 'polestar_tro',
  eta = 'eta',
}

export interface CompanyModules {
  road_shipments: boolean
  air_shipments: boolean
  location: boolean
  temperature: boolean
  temperature_prediction: boolean
  polestar_tro: boolean
  eta: boolean
}

export interface Company {
  cid: string
  type: CompanyType
  displayName: string
  customerIdentifiers: string[]
  properties?: {
    visibilityLevel?: VisibilityLevel
    address?: string
  }
  modules: CompanyModules
  role?: Role
  groupIds: string[]
}
