import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldValues, ValidateResult } from 'react-hook-form'

import InputAdornment from '@mui/material/InputAdornment'
import LanguageIcon from '@mui/icons-material/Language'

import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import KNFormText from './KNFormText'
import { KNFormPhoneProps } from './types'

const KNFormPhone = <T extends FieldValues>(props: KNFormPhoneProps<T>): ReactElement => {
  const { t } = useTranslation()
  const [flagAdornment, setFlagAdornment] = useState(<LanguageIcon />)
  const [validMobile, setValidMobile] = useState(false)

  const validatePhoneNumber = async (value): Promise<void> => {
    if (!value) {
      return
    }
    const libphonenumber = await import('libphonenumber-js/mobile')
    const parsedPhoneNumber = libphonenumber.parsePhoneNumber(value)
    if (parsedPhoneNumber?.country) {
      setFlagAdornment(<KNCountryFlag countryCode={parsedPhoneNumber.country} sx={{ marginLeft: 0.5 }} /> || <LanguageIcon />)
      setValidMobile(parsedPhoneNumber.isValid())
    } else {
      setFlagAdornment(<LanguageIcon />)
      setValidMobile(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validatePhoneNumber(props.getValues?.(props.name))
  }, [])

  return (
    <KNFormText
      {...props}
      rules={{
        ...props.rules,
        validate: {
          ...props.rules?.validate,
          validateMobile: (value): ValidateResult => {
            if (value) {
              return validMobile || t('form.validation.invalid_mobile')
            }
            return true
          },
        },
      }}
      startAdornment={
        <InputAdornment position="start">
          {flagAdornment}
        </InputAdornment>
      }
      type="tel"
      transformValue={(value): string | number => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        validatePhoneNumber(value)
        return value
      }}
    />
  )
}

export default KNFormPhone
