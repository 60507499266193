import React, { useState, useEffect, useCallback, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Tooltip from '@mui/material/Tooltip'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate } from 'global/helpers/dateFormatters'
import { getRouteName } from 'global/helpers/activeRoute'
import { hasHighlights, replaceHighlights } from 'global/helpers/highlights'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverList from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { KNADRIcon, KNSharedViaEmailIcon, KNSharedViaSMSIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import Tracking from 'screens/TripDetails/Tracking'
import Temperature from 'screens/TripDetails/Temperature'
import { LegData } from 'screens/TripDetails/TripDetails.types'
import TripStatusChip from './TripStatusChip'
import { TripData } from './TripDashboard.types'
import rgba from 'assets/theme/functions/rgba'
import colors from 'assets/theme/base/colors'

export interface TripHeaderProps {
  trip: TripData
  legs?: LegData[]
  linked?: boolean
  onLinkClick?: (trip: TripData) => void
  stacked?: boolean
  size?: 'large'
  withTemperature?: boolean
  withPropagation?: boolean
  weblinkToken?: string
  highlights?: string[]
}

export const getLastShareIcon = (channel: string): ReactElement => {
  switch (channel) {
    case 'SMS':
      return <KNSharedViaSMSIcon />
    case 'EMAIL':
    default:
      return <KNSharedViaEmailIcon />
  }
}

const TripHeader = ({
  trip,
  legs,
  linked = false,
  onLinkClick,
  stacked = false,
  size,
  withTemperature,
  withPropagation,
  weblinkToken,
  highlights,
}: TripHeaderProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const [processedReferences, setProcessedReferences] = useState<string[] | ReactNode[]>(trip.customerReferences)
  const [isHighlighted, setIsHighlighted] = useState(false)

  const {
    anchorEl: tripDetailsAnchorEl,
    open: tripDetailsOpen,
    handleClick: handleTripDetailsClick,
    handleClose: handleTripDetailsClose,
  } = usePopoverState()
  const {
    anchorEl: dangerousDetailsAnchorEl,
    open: dangerousDetailsOpen,
    handleClick: handleDangerousDetailsClick,
    handleClose: handleDangerousDetailsClose,
  } = usePopoverState()

  useEffect(() => {
    let isHighlighted = false
    setProcessedReferences(trip.customerReferences.map((reference) => {
      // don't override isHighlighted if it's already set to true
      isHighlighted = isHighlighted || hasHighlights(reference, highlights)
      return replaceHighlights(reference, highlights)
    }))
    setIsHighlighted(isHighlighted)
  }, [trip.customerReferences, highlights])

  const handlePropagation = useCallback((event) => event.stopPropagation(), [])

  const handleLinkClick = useCallback((): void => onLinkClick?.(trip), [])

  const handleTripDetailsClickWrapper = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    handleTripDetailsClick(event)
    analyticsEvent('polestar_cs_trip_details_popover', [getRouteName(location.pathname)])
  }, [])

  const handleDangerousDetailsClickWrapper = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    handleDangerousDetailsClick(event)
    analyticsEvent('polestar_cs_trip_dangerous_goods_popover', [getRouteName(location.pathname)])
  }, [])

  return (
    <Box onClick={withPropagation ? undefined : handlePropagation}>
      <Stack data-test="trip-id-status-container" data-guide="trip-summary" direction="column" alignItems="start">
        <Stack spacing={1} direction="row" alignItems="center" sx={{ maxWidth: '100%' }}>
          {linked ? (
            <Link
              data-test="trips-id-link"
              component={RouterLink}
              to={`/trips/${trip.entityId}`}
              color="text.main"
              variant={size === 'large' ? 'displaySM_SB' : 'displayXXS_SB'}
              onClick={handleLinkClick}
              className="hover-link"
              noWrap
            >
              {replaceHighlights(trip.voyageNumber, highlights)}
            </Link>
          ) : (
            <KNTypography color="text.main" variant={size === 'large' ? 'displaySM_SB' : 'displayXXS_SB'} noWrap>
              {replaceHighlights(trip.voyageNumber, highlights)}
            </KNTypography>
          )}
          {!stacked && <TripStatusChip status={trip.status} />}
          <Stack direction="row" alignItems="center">
            <IconButton
              onClick={handleTripDetailsClickWrapper}
              size="small"
              color={isHighlighted ? 'warning' : 'primary'}
              sx={{ margin: '-5px 0 !important' }}
            >
              {isHighlighted ? <InfoIcon /> : <InfoOutlinedIcon />}
            </IconButton>
            {trip.dangerousGoodsDetails.length > 0 && (
              <IconButton
                onClick={handleDangerousDetailsClickWrapper}
                size="small"
                color="error"
                sx={{ margin: '-5px 0 !important' }}
              >
                <KNADRIcon />
              </IconButton>
            )}
            <Tracking trip={trip} preloadedLegs={legs} weblinkToken={weblinkToken} />
            {withTemperature && <Temperature trip={trip} legs={legs} weblinkToken={weblinkToken} graphRequired />}
            {(trip.lastShareChannel && trip.lastShareTimestamp) && (
              <Tooltip
                title={t(`screens.cs.trip_dashboard.share.last_shared_via.${trip.lastShareChannel}`, {
                  timestamp: zonedDate(trip.lastShareTimestamp, 'full_no_year')
                })}
                enterDelay={200}
                placement="top"
                arrow
              >
                <IconButton
                  size="small"
                  sx={{ color: 'primary.main' }}
                  disableRipple
                >
                  {getLastShareIcon(trip.lastShareChannel)}
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        {stacked && <TripStatusChip status={trip.status} />}
      </Stack>
      <KNDetailsPopover
        open={tripDetailsOpen}
        onClose={handleTripDetailsClose}
        anchorEl={tripDetailsAnchorEl}
        rightAligned
      >
        <KNDetailsPopoverList
          items={[
            {
              label: t('screens.cs.trip_dashboard.card.created_on'),
              value: zonedDate(trip.creationDate) ?? undefined,
            },
            ...(trip.carrierInstructions
              ? [
                {
                  label: t('screens.cs.trip_dashboard.trip_details.instructions'),
                  value: trip.carrierInstructions,
                  stacked: true,
                },
              ]
              : []),
            ...(processedReferences.length > 0
              ? [
                {
                  label: t('screens.cs.trip_dashboard.trip_details.references'),
                  value: processedReferences,
                  stacked: true,
                },
              ]
              : []),
            {
              label: t('screens.cs.trip_dashboard.trip_details.kn_branch'),
              value: trip.mappedSender,
            },
          ]}
        />
      </KNDetailsPopover>
      {trip.dangerousGoodsDetails.length > 0 && (
        <KNDetailsPopover
          open={dangerousDetailsOpen}
          onClose={handleDangerousDetailsClose}
          anchorEl={dangerousDetailsAnchorEl}
          rightAligned
        >
          {trip.dangerousGoodsDetails.map((details, index) => (
            <KNDetailsPopoverList
              key={index}
              description={details.description}
              items={[
                {
                  label: t('screens.cs.trip_details.dangerous_goods.packages'),
                  value: details.packagesType
                    ? `${details.numberOfPackages} × ${details.packagesType}`
                    : details.numberOfPackages,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.un_number'),
                  value: details.unNumber,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.class'),
                  value: details.classType,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.packing_group'),
                  value: details.packingGroup,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.quantity'),
                  value: details.quantityType ? `${details.quantity} × ${details.quantityType}` : details.quantity,
                },
              ]}
            />
          ))}
        </KNDetailsPopover>
      )}
    </Box>
  )
}

export default TripHeader
