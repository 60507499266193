import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import pxToRem from 'assets/theme/functions/pxToRem'

const { size } = typography

const chip = {
  styleOverrides: {
    sizeSmall: {
      height: pxToRem(20),
    },
    sizeMedium: {
      height: pxToRem(24),
      fontSize: size.lg,
    },
    iconSmall: {
      color: 'inherit',
      fontSize: size.lg,
      marginLeft: pxToRem(6),
    },
    outlined: {
      backgroundColor: colors.white.main,
      borderColor: colors.primary.light,
      color: colors.primary.light,
      transition: 'opacity 300ms ease-in-out',
      '&:hover.MuiChip-clickable': {
        backgroundColor: colors.white.main,
        opacity: 0.7,
      },
    },
    iconMedium: {
      fontSize: size.xl,
    },
    colorDefault: {
      color: colors.white.main,
      backgroundColor: colors.primary.light,
      transition: 'opacity 300ms ease-in-out',
      '&:hover.MuiChip-clickable': {
        backgroundColor: colors.primary.light,
        opacity: 0.8,
      },
    },
  },
}

export default chip
