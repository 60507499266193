import { ReactNode } from 'react'

import { KNLogo, KNSymbol } from 'components/KN_Molecules/KNIcon/KNLogo'

export const getLogo = (type: 'logo' | 'symbol', width: string, height: string): ReactNode => {
  if (type === 'symbol') {
    return (
      <KNSymbol
        sx={{
          width: width,
          height: height,
          color: 'primary.main',
        }}
      />
    )
  }
  return (
    <KNLogo
      sx={{
        width: width,
        height: height,
        color: 'primary.main',
      }}
    />
  )
}
