import { forwardRef } from 'react'

// Types
import KNButtonProps from './types'

// Custom KN styles
import KNButtonRoot from './KNButtonRoot'

const KNButton = forwardRef<HTMLButtonElement, KNButtonProps>(
  ({ color, variant, children, dataAttribute, ...rest }, ref) => (
    <KNButtonRoot
      data-test={`${String(dataAttribute)}-KNButton`}
      {...rest}
      ref={ref}
      ownerState={{ color, variant }}
    >
      {children}
    </KNButtonRoot>
  )
)

// KNButton Default Props
KNButton.defaultProps = {
  color: 'primary',
}

export default KNButton
