const accordionSummary = {
  styleOverrides: {
    root: {
      '& .Mui-expanded': {
        margin: 0,
        minHeight: 32,
      },
    },
  },
}

export default accordionSummary
