import { KNUser } from 'context/authentication/UserContext.types'

export const getEnv = (): string => process.env.REACT_APP_API_ENV

export const isDev = (): boolean => getEnv() === 'dev'
export const isUat = (): boolean => getEnv() === 'uat'
export const isDemo = (): boolean => getEnv() === 'demo'
export const isProd = (): boolean => getEnv() === 'prod'

export const isSentryEnabled = (): boolean => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return false
  }
  if (!process.env.REACT_APP_API_ENV) {
    return false
  }
  return true
}

export const isSupportUser = (user?: KNUser): boolean => {
  if (!user) {
    return false
  }
  const supportEmails = [
    'sandra.vasic@kuehne-nagel.com',
    'ivana.cvetkovic@kuehne-nagel.com',
    'milica.d.panic@kuehne-nagel.com',
  ]
  return supportEmails.includes(user.email)
}
