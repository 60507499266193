import { ReactElement } from 'react'

// @mui imports
import { alpha } from '@mui/material'
import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import { KNDetailsPopoverProps } from './types'

const StyledPopover = styled(Popover)(({ theme }) => ({
  '.MuiPopover-paper': {
    color: 'white',
    backgroundColor: alpha(theme.palette.primary.dark, 0.85),
    backdropFilter: 'blur(3px)',
    border: 0,
  },
}))

const KNDetailsPopover = ({
  children,
  open,
  onClose,
  anchorEl,
  rightAligned,
  sx,
}: KNDetailsPopoverProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = (event, reason): void => {
    onClose()
  }

  return (
    <StyledPopover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: rightAligned ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: -4,
        horizontal: rightAligned ? 'right' : 'left',
      }}
      elevation={8}
    >
      <Stack
        data-test="info-details"
        direction="column"
        divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: 'primary.light' }} />}
        sx={{
          minWidth: '16rem',
          maxWidth: isMobile ? '20rem' : '32rem',
          maxHeight: isMobile ? '20rem' : '32rem',
          ...sx,
        }}
      >
        {children}
      </Stack>
    </StyledPopover>
  )
}

export default KNDetailsPopover
