import { PaginatedResults } from 'global/types/pagination'
import { getUserToken } from 'context/authentication/User.helpers'
import { handleErrors } from './errorHandler'
import { cleanupRequestValues } from 'global/helpers/form'
import { analyticsEvent } from './analytics'

const fetchRequest = async (
  method: string,
  endpoint: string,
  body: any = null,
  headers: Record<string, string> = {},
  prefix?: string,
  version?: string
): Promise<any> => {
  const token = await getUserToken()
  const options: any = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }
  if (body) {
    if (body instanceof FormData) {
      // NOTE: let browser generate Content-Type automatically with proper boundary
      // options.headers['Content-Type'] = 'multipart/form-data';
      options.body = body
    } else {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(cleanupRequestValues(body))
    }
  }
  let apiUrl = `${process.env.REACT_APP_API_URL}/api/${version ?? process.env.REACT_APP_API_VERSION}/${endpoint}`
  if (prefix) {
    apiUrl = `${process.env.REACT_APP_API_URL}/api/${prefix}/${
      version ?? process.env.REACT_APP_API_VERSION
    }/${endpoint}`
  }
  const response = await fetch(apiUrl, options)
  if (
    response.status === 401 &&
    window.location.pathname !== '/login' &&
    !window.location.pathname.includes('/share_shipment')
  ) {
    // TODO: better integrate with react router?
    window.location.href = '/login'
  }

  const contentType = response.headers.get('Content-Type') ?? ''
  const isJson = contentType.startsWith('application/json')
  const isPDF = contentType.startsWith('application/pdf')
  const isFile = contentType.startsWith('application/octet-stream')
  const isText = contentType.startsWith('text/plain')

  let data: any = null
  if (isJson) {
    data = await response.json()
  } else if (isFile || isPDF) {
    data = await response.blob()
  } else {
    data = await response.text()
  }
  if (!response.ok) {
    const errorContent = isJson ? handleErrors(data) : isText ? data : response.statusText
    analyticsEvent('polestar_fetch_error_occured', [endpoint, options.body, errorContent])
    return Promise.reject(errorContent)
  }
  return data
}

export const weblinkHeaders = (token: string | null): Record<string, string> => {
  return token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {}
}

export const fetchGet = async (
  endpoint: string,
  headers: Record<string, string> = {},
  prefix?: string,
  version?: string
): Promise<any> => fetchRequest('GET', endpoint, null, headers, prefix, version)

export const fetchPost = async (
  endpoint: string,
  body: any = null,
  headers: Record<string, string> = {},
  prefix?: string,
  version?: string
): Promise<any> => fetchRequest('POST', endpoint, body, headers, prefix, version)

export const fetchPut = async (
  endpoint: string,
  body: any = null,
  headers: Record<string, string> = {},
  prefix?: string,
  version?: string
): Promise<any> => fetchRequest('PUT', endpoint, body, headers, prefix, version)

export const fetchDelete = async (
  endpoint: string,
  body: any = null,
  headers: Record<string, string> = {},
  prefix?: string,
  version?: string
): Promise<any> => fetchRequest('DELETE', endpoint, body, headers, prefix, version)

export const getChunkedResults = async <T extends object>(
  getPaginatedResults: (page: number, pageSize: number) => Promise<PaginatedResults<T>>,
  pageSize = 1000
): Promise<T[]> => {
  const firstPage = await getPaginatedResults(0, pageSize)
  const pageCount = Math.ceil(firstPage.totalElements / pageSize)
  if (pageCount === 1) {
    return firstPage.content
  }
  const chunkedRequests: Promise<PaginatedResults<T>>[] = []
  for (let page = 1; page < pageCount; page++) {
    chunkedRequests.push(getPaginatedResults(page, pageSize))
  }
  const chunkedResults = await Promise.all(chunkedRequests)
  return [
    ...firstPage.content,
    ...chunkedResults.reduce(
      (results: T[], paginatedChunk: PaginatedResults<T>) => results.concat(paginatedChunk.content),
      []
    ),
  ]
}
