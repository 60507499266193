// Functional //
import { isDemo, isProd, isUat } from 'global/helpers/environment'
import { hasAccess, hasRole, hasVisibilityLevel, isCompanyType } from 'global/helpers/authorization'

// @mui imports //
import AirplanemodeActiveRoundedIcon from '@mui/icons-material/AirplanemodeActiveRounded'
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded'
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded'

// KN imports //
import {
  KNLoopIcon,
  KNCarriersIcon,
  KNCompaniesIcon,
  KNDriverIcon,
  KNHelpAndInfoIcon,
  KNPairingIcon,
  KNTripIcon,
  KNVehicleIcon,
  KNUsersIcon,
  KNShipmentDescriptionIcon,
  KNRoadIcon,
} from 'components/KN_Molecules/KNIcon/KNIcon'
import { KNNotificationsActiveIcon, KNShareIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

// Types //
import { CompanyModule, CompanyType, VisibilityLevel } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

interface Section {
  sectionName: string
  sectionShortName?: string
  shortName?: string
  sectionOptions: SectionOption[]
  predicate?: () => boolean
}

interface SectionOption {
  name: string
  label: string
  link: string
  icon: JSX.Element
  predicate?: () => boolean
}

export const menuOptions = (): Section[] => [
  {
    sectionName: 'drawer_menu.carrier_solution',
    sectionShortName: 'drawer_menu.carrier_solution_short',
    predicate: () => isCompanyType(CompanyType.Carrier),
    sectionOptions: [
      {
        name: 'trips',
        label: 'drawer_menu.trips',
        link: '/trips',
        icon: <KNTripIcon />,
      },
      {
        name: 'live_map',
        label: 'drawer_menu.live_map',
        link: '/live-map',
        icon: <KNRoadIcon />,
      },
      {
        name: 'drivers',
        label: 'drawer_menu.drivers',
        link: '/drivers',
        icon: <KNDriverIcon />,
      },
      {
        name: 'vehicles',
        label: 'drawer_menu.vehicles',
        link: '/vehicles',
        icon: <KNVehicleIcon />,
      },
    ],
  },
  {
    sectionName: 'drawer_menu.admin',
    predicate: () => hasRole(Role.Admin) || isCompanyType(CompanyType.Operator),
    sectionOptions: [
      {
        name: 'users',
        label: 'drawer_menu.users',
        link: '/users',
        icon: <KNUsersIcon />,
        predicate: () => hasRole(Role.Admin) || isCompanyType(CompanyType.Operator),
      },
      {
        name: 'companies',
        label: 'drawer_menu.companies',
        link: '/companies',
        icon: <KNCompaniesIcon />,
        predicate: () => isCompanyType(CompanyType.Operator),
      },
      {
        name: 'carriers',
        label: 'drawer_menu.carriers',
        link: '/carriers',
        icon: <KNCarriersIcon />,
        predicate: () => isCompanyType(CompanyType.Operator),
      },
      {
        name: 'playground',
        label: 'drawer_menu.playground',
        link: '/playground',
        icon: <KNLoopIcon />,
        predicate: () => hasRole(Role.Admin) && isCompanyType(CompanyType.Operator) && !isProd(),
      },
      {
        name: 'rejected_messages',
        label: 'drawer_menu.rejected_messages',
        link: '/rejected-messages',
        icon: <KNShipmentDescriptionIcon />,
        predicate: () => hasRole(Role.Admin) && isCompanyType(CompanyType.Operator),
      },
    ],
  },
  {
    sectionName: 'drawer_menu.user',
    predicate: () => isCompanyType(CompanyType.Carrier),
    sectionOptions: [
      {
        name: 'releasenotes',
        label: 'drawer_menu.releasenotes',
        link: '/help&info',
        icon: <KNHelpAndInfoIcon />,
      },
    ],
  },
]

export const getMenuOptions = (): Section[] =>
  menuOptions()
    .filter((section) => !section.predicate || section.predicate())
    .map((section) => ({
      sectionName: section.sectionName,
      sectionShortName: section.sectionShortName,
      sectionOptions: section.sectionOptions.filter((option) => !option.predicate || option.predicate()),
    }))
