import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'

const { dark } = colors
const { size } = typography

// types
type Types = any

const input: Types = {
  styleOverrides: {
    root: {
      fontSize: size.md,
      color: dark.main,
    },
  },
}

export default input
