import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'
import { Company } from 'context/authentication/Company.types'

export interface UserFiltersValues {
  keywords?: string[]
}

export interface UserListState {
  companies: Company[]
  companiesPreloaded: boolean
  filters: UserFiltersValues
  filtersDirty: boolean
}

export type UserListAction =
  | { type: 'setCompanies'; payload: Company[] }
  | { type: 'setFilters'; payload: UserFiltersValues }
  | { type: 'resetFilters' }

const initialFilters: UserFiltersValues = {
  keywords: [],
}

const initialState: UserListState = {
  companies: [],
  companiesPreloaded: false,
  filters: initialFilters,
  filtersDirty: false,
}

const reducer = (state: UserListState, action: UserListAction): UserListState => {
  switch (action.type) {
    case 'setCompanies':
      return {
        ...state,
        companies: action.payload,
        companiesPreloaded: true,
      }
    case 'setFilters':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        filtersDirty: JSON.stringify({ ...state.filters, ...action.payload }) !== JSON.stringify(initialFilters),
      }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as UserListContext, StoreProvider as UserListProvider }
