import { useState, ReactElement } from 'react'
import { Controller, FieldValues, ValidateResult } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// @mui imports
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

// KN imports
import KNDatePicker from 'components/KN_Molecules/KNDatePicker/KNDatePicker'

// Functional
import { getDateFormat } from 'global/helpers/dateFormatters'
import { getFieldErrorMessages } from 'global/helpers/form'

// Types
import { KNFormDateProps } from './types'

const KNFormDate = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  size,
  sx,
  showTimeSelect,
  format,
  allowFuture,
}: KNFormDateProps<T>): ReactElement => {
  const { t } = useTranslation()
  const [dateError, setDateError] = useState<string | null>(null)
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          invalidDate: (value): ValidateResult => {
            if (dateError) {
              return t('form.validation.invalid_date')
            }
            return true
          },
        },
      }}
      render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
        <FormControl fullWidth sx={sx} error={invalid}>
          <KNDatePicker
            showTimeSelect={showTimeSelect}
            label={label}
            inputRef={ref}
            selected={value ?? null}
            value={value ?? null}
            allowFuture={allowFuture}
            format={format ?? getDateFormat('short')}
            onChange={(newValue: Date | null): void => {
              if (newValue && !showTimeSelect) {
                newValue.setSeconds(0)
              }
              onChange(newValue)
            }}
            onError={(reason, value): void => setDateError(reason)}
            error={invalid}
            disabled={formState.isSubmitting}
            id={label}
            size={undefined}
          />
          {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default KNFormDate
