import { isProd, isUat } from 'global/helpers/environment'
import { getSelectedCompany } from 'context/authentication/User.helpers'

export const analyticsPageView = (name: string): void => {
  const company = getSelectedCompany()
  const params = {
    event: 'virtualPageview',
    pageName: name,
    cid: company?.cid ?? 'unknown',
  }
  if (isProd() || (isUat() && name.includes('shipmentinsight'))) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(params)
  } else {
    console.log('analyticsPageView', params)
  }
}

export const analyticsEvent = (name: string, dimensions: string[] = []): void => {
  const company = getSelectedCompany()
  const params = {
    event: name,
    eventDimension1: company?.cid ?? 'unknown',
  }
  dimensions.forEach((value, index) => {
    // NOTE: dimensions are 1-index based, and eventDimension1 is always carrier identifier,
    // hence additional dimensions start at 2
    params[`eventDimension${index + 2}`] = value
  })
  if (isProd() || (isUat() && name.includes('shipmentinsight'))) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(params)
  } else {
    console.log('analyticsEvent', params)
  }
}
