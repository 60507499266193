import { fetchGet, fetchPost } from 'global/helpers/fetchWrapper'

// Types //
import {
  CreatePredefinedScenarioFormValues,
  CreateTripTelematicsFormValues,
  GeoPoint,
  PlaygroundResponse,
} from './Playground.types'
import { Company } from 'context/authentication/Company.types'

export const createPredefinedScenario = async (data: CreatePredefinedScenarioFormValues): Promise<PlaygroundResponse> =>
  await fetchPost('generate/scenario', data, {}, 'playground')

export const createTripTelematics = async (data: CreateTripTelematicsFormValues): Promise<PlaygroundResponse> =>
  await fetchPost('generate/trip/telematics', data, {}, 'playground')

export const geocodeAddress = async (data: string): Promise<GeoPoint> =>
  await fetchGet(`geocode${data}`, {}, 'playground')

export const getCompanies = async (): Promise<Company[]> => await fetchGet(`companies`)
