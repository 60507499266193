import React, { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import LegHeader from './LegHeader'
import LegCargo from './LegCargo'
import UitReference from './UitReference'
import Directions from './Directions'
import LegCardCustomsStop from './LegCardCustomsStop'
import LegCardPrimaryStop from './LegCardPrimaryStop'
import { LegCardProps } from './LegCard.types'

const LegCard = ({ trip, leg, weblinkToken, onChange }: LegCardProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const isCollapsible = isMobile && leg.wayPoints.every((stop) => stop.availableStatuses.length === 0)
  const [collapsed, setCollapsed] = useState(isCollapsible)
  const primaryStops = leg.wayPoints.filter((stop) => ['PUP', 'DEL'].includes(stop.type))
  const customsStops = leg.wayPoints.filter((stop) => ['CUS'].includes(stop.type))

  return (
    <Paper
      elevation={8}
      sx={{
        padding: 2,
      }}
    >
      <Box>
        <Stack
          data-test="leg-number"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          onClick={(): void => {
            if (!isCollapsible) {
              return
            }
            setCollapsed(!collapsed)
          }}
          mb={{ xs: 0.5, md: 0 }}
          sx={{
            ...(isCollapsible && {
              cursor: 'pointer',
            }),
          }}
        >
          <Stack
            spacing={{ xs: 0.5, md: 1 }}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'start', md: 'center' }}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            sx={{
              flexGrow: 1,
            }}
          >
            <LegHeader trip={trip} leg={leg} size="large" />
            <Stack
              data-test="google-maps-button"
              spacing={{ xs: 0.5, md: 1 }}
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'start', md: 'center' }}
            >
              {trip.sendingApplication !== 'MERCURYGATE' && <Directions leg={leg} />}
            </Stack>
          </Stack>
          {isCollapsible && (
            <ExpandMoreIcon
              data-test="expand-more-button"
              sx={{
                color: 'text.main',
                transform: collapsed ? 'rotate(0deg)' : 'rotate(-180deg)',
                marginLeft: 'auto',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          )}
        </Stack>
        <LegCargo leg={leg} />
        <UitReference leg={leg} />
      </Box>

      {!isMobile && <Divider orientation="horizontal" sx={{ my: 2, mx: -2 }} flexItem />}

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        divider={!isMobile ? <Divider orientation="vertical" flexItem /> : undefined}
        alignItems="stretch"
        spacing={{ xs: 1, md: 3 }}
        mt={{ xs: 2, md: 0 }}
        mx={{ xs: -2, md: 0 }}
      >
        {primaryStops.map((stop) => (
          <LegCardPrimaryStop
            key={stop.wayPointCid}
            trip={trip}
            leg={leg}
            stop={stop}
            weblinkToken={weblinkToken}
            onChange={onChange}
            collapsed={collapsed}
          />
        ))}
      </Stack>

      {customsStops.length > 0 && (
        <>
          {!isMobile && <Divider orientation="horizontal" sx={{ my: 2, mx: -2 }} flexItem />}
          <Stack
            direction="column"
            divider={!isMobile ? <Divider orientation="horizontal" flexItem /> : undefined}
            alignItems="stretch"
            spacing={{ xs: 1, md: 2 }}
            mx={{ xs: -2, md: 0 }}
          >
            {customsStops.map((stop) => (
              <LegCardCustomsStop
                key={stop.wayPointCid}
                trip={trip}
                leg={leg}
                stop={stop}
                weblinkToken={weblinkToken}
                onChange={onChange}
                collapsed={collapsed}
              />
            ))}
          </Stack>
        </>
      )}
    </Paper>
  )
}

export default LegCard
