import i18n from '../../i18n'
import { MeasurementWithUnit } from 'screens/TripDashboard/TripDashboard.types'

export const getFormattedUnit = (unit: string): string => {
  switch (unit) {
    case 'KG':
    case 'KGM':
      return 'kg'
    case 'M':
      return 'm'
    case 'M3':
    case 'MTQ':
      return 'm³'
    case 'MTR':
    case 'LDM':
      return i18n.t('screens.cs.trip_details.card.ldm')
    default:
      return unit
  }
}
