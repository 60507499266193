import { useEffect, useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import InfoIcon from '@mui/icons-material/Info'

// KN imports //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormCheckbox from 'components/KN_Molecules/KNForm/KNFormCheckbox'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'

// Functional //
import { getErrorMessage } from 'global/helpers/errorHandler'
import { createTripTelematics } from 'screens/Playground/Playground.service'
import { getCompanyOptions, getPlaygroundLogs } from './PlaygroundForms.helpers'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import { Company } from 'context/authentication/Company.types'
import { CreateTripTelematicsFormValues, PlaygroundResponse } from 'screens/Playground/Playground.types'

interface CreatePlaygroundTripTelematicsFormProps {
  fetchLoading: boolean
  fetchError?: string
  companies: Company[]
}

const CreatePlaygroundTripTelematicsForm = ({
  fetchLoading,
  fetchError,
  companies,
}: CreatePlaygroundTripTelematicsFormProps): ReactElement => {
  const [loading, setLoading] = useState(false)
  const [requestMessage, setRequestMessage] = useState<PlaygroundResponse | null>(null)
  const { translation } = playgroundTranslations()
  const { handleSubmit, control, reset, formState, setError, getValues } = useForm<CreateTripTelematicsFormValues>({
    defaultValues: {
      user: null,
      company: {
        createCompany: false,
        type: 'Carrier',
      },
      telematics: {
        withoutApproachAndDeparture: false,
        randomizedOrder: false,
      },
    },
  })

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (fetchError) {
      setError('root.server', {
        message: fetchError,
      })
    }
  }, [fetchError])

  const onSubmit: SubmitHandler<CreateTripTelematicsFormValues> = async (data: CreateTripTelematicsFormValues) => {
    setLoading(true)
    setRequestMessage(null)
    try {
      const response = await createTripTelematics(data)
      setRequestMessage(response)
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
    setLoading(false)
  }

  return loading || fetchLoading ? (
    <KNLoader />
  ) : (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <Stack data-test="trip-telematics-container" spacing={3}>
        {formState.errors?.root?.server && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="error">{formState.errors.root.server.message}</Alert>
          </DialogContentText>
        )}
        {requestMessage && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="success">{getPlaygroundLogs(requestMessage)}</Alert>
          </DialogContentText>
        )}
        <KNTypography variant="displayMD_SB" color="primary.focus">
          {translation.createTripTelematicsFormName}
        </KNTypography>
        <Stack direction="column" spacing={2} alignItems="start">
          <KNFormDropdown
            name="company.name"
            label={translation.tripTelematics.company}
            control={control}
            options={getCompanyOptions(companies)}
            rules={{
              required: translation.required,
            }}
            sx={{
              maxWidth: 'sm',
            }}
          />
          <KNFormText
            name="telematics.tripCid"
            label={translation.tripTelematics.tripId}
            control={control}
            rules={{
              required: translation.required,
            }}
            sx={{
              maxWidth: 'sm',
            }}
          />
          <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
            <KNFormText
              name="telematics.licensePlate"
              label={translation.tripTelematics.licensePlate}
              control={control}
              sx={{
                maxWidth: 'sm',
              }}
            />
            <KNCaption icon={<InfoIcon />}>{translation.tripTelematics.licensePlateDisclaimer}</KNCaption>
          </Stack>
          <KNTypography variant="textLG_SB">{translation.tripTelematics.modifiers}</KNTypography>
          <KNFormCheckbox
            name="telematics.withoutApproachAndDeparture"
            label={translation.tripTelematics.withoutApproachAndDeparture}
            control={control}
          />
          <KNFormCheckbox
            name="telematics.randomizedOrder"
            label={translation.tripTelematics.randomizedOrder}
            control={control}
          />
          <KNLoadingButton
            type="submit"
            color="success"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {translation.saveButtonText}
          </KNLoadingButton>
        </Stack>
      </Stack>
    </KNForm>
  )
}

export default CreatePlaygroundTripTelematicsForm
