import { useState } from 'react'

interface MenuState {
  anchorEl: HTMLElement | null
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  handleClose: () => void
  open: boolean
}

const useMenuState = (): MenuState => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return { anchorEl, open, handleClick, handleClose }
}

export default useMenuState
