import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import App from './App'
import './i18n'
import { UserProvider } from 'context/authentication/UserContext'
import { getEnv, isSentryEnabled } from 'global/helpers/environment'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { escapeRegExp } from 'lodash'

if (isSentryEnabled()) {
  const history = createBrowserHistory()
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: getEnv(),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.25,
    tracePropagationTargets: [escapeRegExp(process.env.REACT_APP_API_URL)],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <UserProvider>
    <Suspense fallback="Loading ...">
      <Router>
        <App />
      </Router>
    </Suspense>{' '}
  </UserProvider>
)
