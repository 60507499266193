import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem'
import linearGradient from 'assets/theme/functions/linearGradient'

const { borderColor } = borders
const { transparent, primary, secondary, dark } = colors

// types
type Types = any

const radio: Types = {
  styleOverrides: {
    root: {
      paddingTop: '4px',
      paddingBottom: '4px',
      '& .MuiSvgIcon-root': {
        borderRadius: '50%',
        color: dark.light,
        transition: 'all 0.3s ease-in-out',
      },

      '&:hover': {
        backgroundColor: transparent.main,
        color: primary.dark,
      },

      '&:after': {
        transition: 'all 250ms ease-in-out',
        content: `""`,
        position: 'absolute',
        width: pxToRem(20),
        height: pxToRem(20),
        borderRadius: '50%',
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3e%3cpath fill='none' stroke='%23fff' stroke-width='2' d='M9 16.2L4.8 12L3.4 13.4L9 19L21 7L19.6 5.6z'/%3e%3c/svg%3e"), ${linearGradient(
          primary.main,
          primary.main
        )}`,
        color: primary.main,
        opacity: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
      },

      '&.Mui-focusVisible': {
        color: primary.main,
      },
    },

    colorPrimary: {
      color: borderColor,

      '&.Mui-checked': {
        color: primary.main,

        '& .MuiSvgIcon-root': {
          borderColor: primary.main,
        },

        '&:after': {
          opacity: 1,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      '&.Mui-checked': {
        color: secondary.main,

        '& .MuiSvgIcon-root': {
          borderColor: secondary.main,
        },

        '&:after': {
          opacity: 1,
        },
      },
    },
  },
}

export default radio
