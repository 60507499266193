import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { parseISO, isThisYear, isSameDay, formatDistance } from 'date-fns'
import i18n from 'i18n'

// @mui imports
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'

// KN imports
import theme from 'assets/theme'
import { sleep } from 'global/helpers/sleep'
import { zonedDate, compactZonedDate } from 'global/helpers/dateFormatters'
import { StopsViewContext } from 'context/trips/StopsViewContext'
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNMap from 'components/KN_Molecules/KNMap/KNMap'
import { getDistance } from 'components/KN_Molecules/KNMap/KNMap.helpers'
import {
  MapMarker,
  MapMarkerState,
  BasicMapMarker,
  SequenceGroupMapMarker,
  hasSequenceGroup
} from 'components/KN_Molecules/KNMap/types'
import { TripData, StopsLocationGroup } from 'screens/TripDashboard/TripDashboard.types'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'
import GroupedStopsCard from './GroupedStopsCard'
import { getTripGeofences, getTripVehiclePositions } from './TripDetails.service'
import {
  groupStopsBySequence,
  getStopsGroupStateColor,
  positionDataTransformer,
  groupGeoPointsBySpeed
} from './TripDetails.helpers'
import { getTypeIcon } from './StopHeader'
import {
  LegData,
  StopData,
  TripLogData,
  StopsSequenceGroup,
  Geofence,
  GeoPoint,
  GeoPointsGroup,
  StatusTripLogData
} from './TripDetails.types'

export interface SequenceMapViewProps {
  trip: TripData
  legs: LegData[]
  tripLogs: TripLogData[]
  weblinkToken?: string
  onChange: (updatedStops: StopData[]) => void
}

export const getStopTooltip = (group: StopsSequenceGroup | StopsLocationGroup, tripLogs: TripLogData[] = []): ReactElement => {
  const relatedTripLogs = tripLogs.filter((tripLog) => tripLog.data
    && 'geoPoint' in tripLog.data
    && tripLog.data.geoPoint
    && tripLog.data.geoPoint.latitude === group.stopLegPairs[0].stop.geoPoint!.latitude
    && tripLog.data.geoPoint.longitude === group.stopLegPairs[0].stop.geoPoint!.longitude)
  let pickupsCount = 0
  let deliveriesCount = 0
  let customsCount = 0
  group.stopLegPairs.map((pair) => {
    if (pair.stop.type === 'PUP') {
      pickupsCount++
    }
    if (pair.stop.type === 'DEL') {
      deliveriesCount++
    }
    if (pair.stop.type === 'CUS') {
      customsCount++
    }
  })
  const PickupIcon = getTypeIcon('PUP')
  const DeliveryIcon = getTypeIcon('DEL')
  const CustomsIcon = getTypeIcon('CUS')
  return (
    <>
      <KNTypography component="p" variant="textLG_SB" color="primary.main">{group.stopLegPairs[0].stop.type === 'CUS' ? i18n.t('waypoints.types.CUS') : group.stopLegPairs[0].stop.address.name.join(' ')}</KNTypography>
      {pickupsCount > 0 && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <PickupIcon sx={{ width: '.75rem', height: '.75rem', fill: theme.palette.primary.main }} />
          <KNTypography component="p" variant="textMD">{i18n.t('screens.cs.trip_details.card.pickups_count', { count: pickupsCount })}</KNTypography>
        </Stack>
      )}
      {deliveriesCount > 0 && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <DeliveryIcon sx={{ width: '.75rem', height: '.75rem', fill: theme.palette.primary.main }} />
          <KNTypography component="p" variant="textMD">{i18n.t('screens.cs.trip_details.card.deliveries_count', { count: deliveriesCount })}</KNTypography>
        </Stack>
      )}
      {customsCount > 0 && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <CustomsIcon sx={{ width: '.75rem', height: '.75rem', fill: theme.palette.primary.main }} />
          <KNTypography component="p" variant="textMD">{i18n.t('screens.cs.trip_details.card.customs_count', { count: customsCount })}</KNTypography>
        </Stack>
      )}
      {relatedTripLogs.map((tripLog, i) => (
        <KNTypography component="p" variant="textMD" color="primary.light" key={i}>{i18n.t(`screens.cs.trip_details.events_tooltip.${tripLog.type}`, { date: compactZonedDate(tripLog.createdAt, tripLog.data?.offset), shipmentNumber: getShipmentId(tripLog)})}</KNTypography>
      ))}
    </>
  )
}
const getShipmentId = (tripLog: TripLogData): string => (tripLog.data as StatusTripLogData).shipmentNumber

export const getStopsMarkers = (groups: StopsSequenceGroup[], tripLogs: TripLogData[]): SequenceGroupMapMarker[] =>
  groups.reduce((markers: SequenceGroupMapMarker[], group: StopsSequenceGroup) => {
    if (group.stopLegPairs[0].stop.geoPoint) {
      markers.push({
        id: group.stopLegPairs[0].stop.wayPointCid,
        latitude: group.stopLegPairs[0].stop.geoPoint.latitude,
        longitude: group.stopLegPairs[0].stop.geoPoint.longitude,
        geofence: group.stopLegPairs[0].stop.geofenceRadius ?? 500,
        label: group.id.toString(),
        color: getStopsGroupStateColor(group.state),
        tooltip: getStopTooltip(group, tripLogs),
        cluster: 'markers',
        sequenceGroup: group,
      })
    }
    return markers
  }, [])

export const getDwellLocations = (geoPoints: GeoPoint[]): GeoPoint[][] => {
  const distanceThershold = 1 // kilometers
  const timeThreshold = 30 * 60 // seconds

  const dwellLocations: GeoPoint[][] = []
  let currentDwell: GeoPoint[] = []
  let elapsedTime = 0
  geoPoints.map((geoPoint) => {
    if (currentDwell.length === 0) {
      currentDwell.push(geoPoint)
      return
    }
    if (getDistance(geoPoint, currentDwell[0]) <= distanceThershold) {
      currentDwell.push(geoPoint)
      elapsedTime += geoPoint.timeToPrevious ?? 0
    } else {
      if (elapsedTime >= timeThreshold) {
        dwellLocations.push(currentDwell)
      }
      currentDwell = [geoPoint]
      elapsedTime = 0
    }
  })
  if (elapsedTime >= timeThreshold) {
    dwellLocations.push(currentDwell)
  }
  return dwellLocations
}

export const getDwellTooltip = (dwell: GeoPoint[]): ReactElement => {
  const first = dwell[0]
  const last = dwell[dwell.length - 1]
  const parsedFirstTimestamp = parseISO(first.timestamp!)
  const parsedLastTimestamp = parseISO(last.timestamp!)
  const fullDateFormat = isThisYear(parsedFirstTimestamp) ? 'full_no_year' : 'full'
  const mediumDateFormat = isThisYear(parsedFirstTimestamp) ? 'medium_no_year' : 'medium'
  const difference = formatDistance(parseISO(last.timestamp!), parseISO(first.timestamp!))
  return (
    <>
      <KNTypography>{i18n.t('screens.cs.trip_details.map.dwell_time', { difference })}</KNTypography>
      {isSameDay(parsedFirstTimestamp, parsedLastTimestamp) ? (
        <KNTypography component="p" variant="textMD">{`${zonedDate(parsedFirstTimestamp, mediumDateFormat, 'UTC') as string} ${zonedDate(parsedFirstTimestamp, 'time', 'UTC') as string}-${zonedDate(parsedLastTimestamp, 'time', 'UTC') as string} (UTC)`}</KNTypography>
      ) : (
        <KNTypography component="p" variant="textMD">{`${zonedDate(parsedFirstTimestamp, fullDateFormat, 'UTC') as string} - ${zonedDate(parsedLastTimestamp, fullDateFormat, 'UTC') as string} (UTC)`}</KNTypography>
      )}
    </>
  )
}

export const getDwellMarkers = (dwellLocations: GeoPoint[][]): BasicMapMarker[] =>
  dwellLocations.map((dwell) => {
    return {
      id: `dwell_${dwell[0].latitude}_${dwell[0].longitude}`,
      latitude: dwell[0].latitude,
      longitude: dwell[0].longitude,
      type: 'DWELL',
      geofence: 1000,
      tooltip: getDwellTooltip(dwell),
      cluster: 'markers',
    }
  })

export const getGeofenceTooltip = (geofence: Geofence, tripLogs: TripLogData[]): ReactElement | null => {
  const relatedTripLogs = tripLogs.filter((tripLog) => tripLog.data
    && 'geoPoint' in tripLog.data
    && tripLog.data.geoPoint
    && tripLog.data.geoPoint.latitude === geofence.geometry.coordinates[0].lat
    && tripLog.data.geoPoint.longitude === geofence.geometry.coordinates[0].lon)
  return (
    <>
      <KNTypography variant="textLG_SB" color="primary.main">{geofence.name}</KNTypography>
      {relatedTripLogs.map((tripLog, i) => (
        <KNTypography component="p" variant="textMD" color="primary.light"
                      key={i}>{i18n.t(`screens.cs.trip_details.events_tooltip.${tripLog.type}`, { date: compactZonedDate(tripLog.createdAt, tripLog.data?.offset) })}</KNTypography>
      ))}
    </>
  )
}

export const getGeofenceMarkers = (geofences: Geofence[], tripLogs: TripLogData[]): BasicMapMarker[] =>
  // ignore waypoint geofences, those are set already on waypoint markers
  // only circular geofences are supported for now
  geofences.filter((geofence) =>  geofence.type !== 'WAYPOINT_MILESTONE region' && geofence.geometry.type === 'POINT').map((geofence) => {
    return {
      id: geofence.id,
      latitude: geofence.geometry.coordinates[0].lat,
      longitude: geofence.geometry.coordinates[0].lon,
      type: 'GEOFENCE',
      geofence: geofence.geometry.radius ?? 500,
      tooltip: getGeofenceTooltip(geofence, tripLogs) ?? undefined,
      cluster: 'markers',
    }
  })

export const getVehicleMarker = (vehicle: VehicleData, lastVehiclePosition: GeoPoint): BasicMapMarker => {
  return {
    id: vehicle.licensePlate,
    latitude: lastVehiclePosition.latitude,
    longitude: lastVehiclePosition.longitude,
    type: 'VEHICLE',
    tooltip: (
      <KNTypography variant="textLG_SB" color="primary.main">{vehicle.licensePlate}</KNTypography>
    ),
    cluster: 'markers'
  }
}

const SequenceMapView = ({ trip, legs, tripLogs, weblinkToken, onChange }: SequenceMapViewProps): ReactElement => {
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)
  const [groupedStopsData, setGroupedStopsData] = useState<StopsSequenceGroup[]>([])
  const [loading, setLoading] = useState(true)
  const [markers, setMarkers] = useState<MapMarker[]>([])
  const [geoPoints, setGeoPoints] = useState<GeoPoint[]>([])
  const [groupedGeoPoints, setGroupedGeoPoints] = useState<GeoPointsGroup[]>([])
  const [centeredGeoPoint, setCenteredGeoPoint] = useState<GeoPoint>()
  const [zoom, setZoom] = useState<number>()
  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const groupedStops = groupStopsBySequence(legs)
    setGroupedStopsData(groupedStops)

    try {
      const geofences = await getTripGeofences(trip.entityId, weblinkToken)
      const vehiclePositions = positionDataTransformer(await getTripVehiclePositions(trip.entityId, weblinkToken))
      const groupedVehiclePositions = groupGeoPointsBySpeed(vehiclePositions)
      if (vehiclePositions.length > 0) {
        const dwellLocations = getDwellLocations(vehiclePositions)
        const baseMarkers: MapMarker[] = [
          ...getDwellMarkers(dwellLocations),
          ...getGeofenceMarkers(geofences, tripLogs),
          ...getStopsMarkers(groupedStops, tripLogs),
        ]
        const lastVehiclePosition = vehiclePositions[vehiclePositions.length - 1]
        if (trip.assignedVehicle) {
          baseMarkers.push(getVehicleMarker(trip.assignedVehicle, lastVehiclePosition))
        }
        setMarkers(baseMarkers)
        setGeoPoints(vehiclePositions)
        setGroupedGeoPoints(groupedVehiclePositions)
      } else {
        setMarkers(getStopsMarkers(groupedStops, tripLogs))
      }
    } catch (error) {
      //
    }
    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [legs])

  useEffect(() => {
    setMarkers(markers.map((marker) => {
      marker.state = MapMarkerState.Default
      if (hasSequenceGroup(marker)) {
        marker.state = stopsViewState.activeStopsGroup === marker.sequenceGroup.id ? MapMarkerState.Active : MapMarkerState.Default
      }
      return marker
    }))
  }, [stopsViewState.activeStopsGroup])

  const handleMarkerClick = useCallback((marker: MapMarker, map) => {
    if (hasSequenceGroup(marker)) {
      stopsViewDispatch({
        type: 'setActiveStopsGroup',
        payload: marker.sequenceGroup.id,
      })
    }
  }, [])

  useEffect(() => {
    if (stopsViewState.geoPoint.latitude!== 0) {
      setCenteredGeoPoint(stopsViewState.geoPoint)
      setZoom(15)
    }
  }, [stopsViewState.geoPoint])

  return (
    <Stack spacing={0} sx={{ flexGrow: 1 }}>
      <Paper
        elevation={8}
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          backgroundColor: '#e9ecef',
        }}
      >
        {loading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={64} color="primary" />
          </Stack>
        ) : (
           <KNMap
            markers={markers}
            geoPoints={geoPoints}
            groupedGeoPoints={groupedGeoPoints}
            withHeading
            onMarkerClick={handleMarkerClick}
            center={centeredGeoPoint}
            zoom={zoom}
          />
        )}
      </Paper>
      {groupedStopsData
        .filter((group) => stopsViewState.activeStopsGroup === group.id)
        .map((group) => (
          <GroupedStopsCard
            key={group.id}
            trip={trip}
            group={group}
            weblinkToken={weblinkToken}
            onChange={onChange}
            minimal
          />
        ))}
    </Stack>
  )
}

export default SequenceMapView
