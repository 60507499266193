import { processAttachments } from './StatusManager.service'
import { StatusUpdateFormValues } from './StatusUpdateDialog'
import { StatusUpdateWithAttachmentsFormValues } from './StatusUpdateWithAttachmentsDialog'
import { DocumentsUploadFormValues } from './DocumentsUploadDialog'
import { BulkStatusUpdateFormValues } from './BulkStatusUpdateDialog'
import { StopData, StopLegPair } from 'screens/TripDetails/TripDetails.types'

export const getUpdatedStopForUpdateStopStatus = (stop: StopData, data: StatusUpdateFormValues): StopData => {
  return {
    ...stop,
    ...generateStatusData(stop, data),
  }
}

export const getUpdatedStopForUpdateStopStatusWithAttachments = (
  stop: StopData,
  data: StatusUpdateWithAttachmentsFormValues
): StopData => {
  return {
    ...stop,
    ...generateStatusData(stop, data),
    ...generateAttachmentsData(stop, data),
  }
}

export const getUpdatedStopForAttachFiles = (stop: StopData, data: DocumentsUploadFormValues): StopData => {
  return {
    ...stop,
    ...generateAttachmentsData(stop, data),
  }
}

export const getUpdatedStopsForBulkUpdateStopStatus = (
  stopLegPairs: StopLegPair[],
  data: BulkStatusUpdateFormValues
): StopData[] => {
  return stopLegPairs.map((stopLegPair) => {
    return {
      ...stopLegPair.stop,
      ...generateStatusData(stopLegPair.stop, data),
    }
  })
}

const generateStatusData = (
  stop: StopData,
  data: StatusUpdateFormValues | StatusUpdateWithAttachmentsFormValues | BulkStatusUpdateFormValues
): Partial<StopData> => {
  return {
    statuses: [
      ...stop.statuses,
      {
        id: data.reasonCode ? `${data.statusCode}_${data.reasonCode}` : data.statusCode,
        statusCode: data.statusCode,
        reasonCode: data.reasonCode,
        createdAt: data.createdAt!, // NOTE: it's no longer undefined at this point
        comment: data.comment,
      },
    ],
  }
}

const generateAttachmentsData = (
  stop: StopData,
  data: DocumentsUploadFormValues | StatusUpdateWithAttachmentsFormValues
): Partial<StopData> => {
  const processedAttachments = processAttachments(data).map((attachment) => {
    return {
      id: attachment.fileId,
      name: '...',
      createdAt: 'pending',
      checksum: 'pending',
      fileType: attachment.fileType,
    }
  })
  return {
    documents: [...(stop.documents ?? []), ...processedAttachments],
  }
}
