import { ReactElement } from 'react'
import { GlobalError } from 'react-hook-form'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { getGlobalErrorMessages } from 'global/helpers/form'

interface KNDialogFormErrorsProps {
  errors?: Record<string, GlobalError>
}

const KNDialogFormErrors = ({ errors }: KNDialogFormErrorsProps): ReactElement | null => {
  if (!errors) {
    return null
  }
  return (
    <DialogContentText component="div" mb={3}>
      <Alert severity="error">{getGlobalErrorMessages(errors)}</Alert>
    </DialogContentText>
  )
}

export default KNDialogFormErrors
