import { merge } from 'lodash'
import React, { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ChartDataset
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'

// @mui imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import i18n from 'i18n'
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { temperatureDataTransformer } from './TripDetails.helpers'
import { getTripVehicleTemperatures } from './TripDetails.service'
import {
  LegData,
  PharmaExtendedInformation,
} from './TripDetails.types'

// Functional
import { getTemperatureChartOptions, getDarkModeColors, Annotation } from 'global/helpers/charts'
import KNDetailsPopoverList from '../../components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import theme from '../../assets/theme'

interface TemperatureProps {
  trip: TripData
  legs?: LegData[]
  graphRequired: boolean
  weblinkToken?: string
}

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip)
const getBoxBoundaries = (tripTempRanges: PharmaExtendedInformation[]) => {
  if(tripTempRanges.length === 0) return null
  const annotations: Annotation[] = []
  tripTempRanges.forEach((value) => {


    annotations.push({
        drawTime: 'beforeDraw',
        type: 'box',
        yMin: value.minimum,
        yMax: value.maximum,
        backgroundColor: theme.palette.primary.dark,
        borderColor:theme.palette.primary.dark
      } )
  })

  return {
    plugins: {
      annotation: {
        annotations: annotations
      }
    }
  }
}
const Temperature = ({ trip, legs, weblinkToken, graphRequired }: TemperatureProps): ReactElement | null => {
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [temperatureLoading, setTemperatureLoading] = useState(true)
  const [temperatureDatasets, setTemperatureDatasets] = useState<ChartDataset<'line'>[]>([])
  const [tripTempRanges, setTripTempRanges] = useState<PharmaExtendedInformation[]>([])

  const {
    anchorEl: temperatureDetailsAnchorEl,
    open: temperatureDetailsOpen,
    handleClick: handleTemperatureDetailsClick,
    handleClose: handleTemperatureDetailsClose,
  } = usePopoverState()

  const handleTemperatureDetailsClickWrapper = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      handleTemperatureDetailsClick(event)
      analyticsEvent('polestar_cs_trip_temperature_details_popover', [getRouteName(location.pathname)])
    },
    [location]
  )

  const fetchTemperatureDetailsData = useCallback(async (): Promise<void> => {
    setTemperatureLoading(true)
    try {
      setTemperatureDatasets(temperatureDataTransformer(await getTripVehicleTemperatures(trip.entityId, weblinkToken)))
    } catch (error) {
      setTemperatureDatasets([])
    }
    setTemperatureLoading(false)
  }, [trip])

  const fetchTempRanges = useCallback(() =>{
    if (legs?.length) {
      const tempRanges: PharmaExtendedInformation[] = []
      for (const leg of legs) {
        if (leg.pharmaExtendedInformation && leg.pharmaExtendedInformation?.setPoint !==null
          && leg.pharmaExtendedInformation?.minimum!==null && leg.pharmaExtendedInformation?.maximum!==null) {
          tempRanges.push(leg.pharmaExtendedInformation)
        }
      }
      setTripTempRanges(tempRanges)
    }
  }, [trip])

  useEffect(() => {
    fetchTempRanges()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    graphRequired && fetchTemperatureDetailsData()
  }, [])

  if (temperatureDatasets.length === 0 && tripTempRanges.length === 0) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={handleTemperatureDetailsClickWrapper}
        size="small"
        color="primary"
        sx={{ margin: '-5px 0 !important' }}
      >
        <ThermostatIcon data-test="temperature-icon" />
      </IconButton>

      <KNDetailsPopover
        open={temperatureDetailsOpen}
        onClose={handleTemperatureDetailsClose}
        anchorEl={temperatureDetailsAnchorEl}
        rightAligned
      >
        {(temperatureDatasets.length > 0 && graphRequired)&& <KNDetailsPopoverSection
          header={t('screens.cs.trip_details.temperature_details.temperature')}
          items={[
            <Box
              key="chart"
              sx={{
                width: isMobile ? '20rem' : '30rem',
                height: isMobile ? '20rem' : '20rem',
                marginTop: '-1px',
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              <Line
                options={merge(getTemperatureChartOptions(), getDarkModeColors(), getBoxBoundaries(tripTempRanges))}
                data={{ datasets: temperatureDatasets }}
              />
            </Box>,
          ]}
        />}

        {tripTempRanges.map((tripTempRange) => (
          <div key={tripTempRange.setPoint}>
            <KNDetailsPopoverList
              items={[
                {
                  label: i18n.t('screens.cs.trip_details.temperature_details.required_temperature_range'),
                  value: i18n.t('screens.cs.trip_details.temperature_details.temperature_between_ranges',
                    { minTemp:tripTempRange.minimum, maxTemp: tripTempRange.maximum })
                },
                {
                  label: t('screens.cs.trip_details.temperature_details.set_point_required'),
                  value: t('screens.cs.trip_details.temperature_details.temperature_in_degrees',{ temperature:tripTempRange.setPoint  })
                }
              ]}
            />
          </div>))}
      </KNDetailsPopover>
    </>
  )
}

export default Temperature
