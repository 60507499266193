import boxShadows from 'assets/theme/base/boxShadows'
import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import borders from 'assets/theme/base/borders'

const { lg } = boxShadows
const { size } = typography
const { dark, primary } = colors
const { borderRadius } = borders

// types
type Types = any

const menu: Types = {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      border: `1px solid ${primary.contrastText as string}`,
      boxShadow: lg,
      padding: '1 0',
      fontSize: size.sm,
      color: dark.main,
      borderRadius: `${borderRadius.lg} !important`,
    },
  },
}

export default menu
