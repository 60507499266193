import { fetchGet, fetchPost, getChunkedResults } from 'global/helpers/fetchWrapper'
import { PaginatedResults } from 'global/types/pagination'
import { CarrierData } from './CarrierManager.type'
import { CreateOrEditCarrierFormValues } from './CreateOrEditCarrierDialog'

export const getPaginatedCarriers = async (page = 0, pageSize = 1000): Promise<PaginatedResults<CarrierData>> =>
  await fetchGet(`carriers?page=${page}&size=${pageSize}`)

export const getCarriers = async (): Promise<CarrierData[]> => {
  const carriers = await getChunkedResults<CarrierData>(getPaginatedCarriers)
  return carriers.sort(sortCarriers)
}

export const createCarrier = async (data: CreateOrEditCarrierFormValues): Promise<void> =>
  await fetchPost('carriers', data)

export const editCarrier = async (cid: string, data: CreateOrEditCarrierFormValues): Promise<void> =>
  await fetchPost(`carriers/${cid}`, data)

const sortCarriers = (a, b): number => {
  // NOTE: makes comparison case insensitive
  const lowercaseA = a.name?.toLowerCase() || ''
  const lowercaseB = b.name?.toLowerCase() || ''
  return lowercaseA === lowercaseB ? 0 : (lowercaseA < lowercaseB ? -1 : 1)
}
