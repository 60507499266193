// KN base
import colors from './colors'

// KN helper functions
import boxShadow from '../functions/boxShadow'

const { black, white, primary, grey } = colors

const boxShadows = {
  xs: boxShadow([0, 2], [9, -5], black.main, 0.15),
  sm: boxShadow([0, 5], [10, 0], black.main, 0.12),
  md: `${boxShadow([0, 4], [6, -1], black.light, 0.12)}, ${boxShadow([0, 2], [4, -1], black.light, 0.07)}`,
  lg: `${boxShadow([0, 8], [26, -4], black.light, 0.15)}, ${boxShadow([0, 8], [9, -5], black.light, 0.06)}`,
  xl: boxShadow([0, 23], [45, -11], black.light, 0.25),
  xxl: boxShadow([0, 20], [27, 0], black.main, 0.05),
  inset: boxShadow([0, 1], [2, 0], black.main, 0.075, 'inset'),
  navbarBoxShadow: `${boxShadow([0, 0], [1, 1], white.main, 0.9, 'inset')}, ${boxShadow(
    [0, 20],
    [27, 0],
    black.main,
    0.05
  )}`,
  buttonBoxShadow: {
    main: `${boxShadow([0, 0], [0, 3], primary.contrastText, 1)}, ${boxShadow(
      [0, 1],
      [2, 0],
      primary.contrastText,
      1
    )}`,
  },
  sliderBoxShadow: {
    thumb: boxShadow([0, 1], [13, 0], black.main, 0.2),
  },
  standardBoxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  tabsBoxShadow: {
    indicator: boxShadow([0, 1], [5, 1], grey[200], 1),
  },
}

export default boxShadows
