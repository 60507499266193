import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'
import { CompanyType } from 'context/authentication/Company.types'

export interface CompanyFiltersValues {
  keywords?: string[]
  types?: CompanyType[]
}

export interface CompanyListState {
  filters: CompanyFiltersValues
  filtersDirty: boolean
}

export type CompanyListAction = { type: 'setFilters'; payload: CompanyFiltersValues } | { type: 'resetFilters' }

const initialFilters: CompanyFiltersValues = {
  keywords: [],
  types: [],
}

const initialState: CompanyListState = {
  filters: initialFilters,
  filtersDirty: false,
}

const reducer = (state: CompanyListState, action: CompanyListAction): CompanyListState => {
  switch (action.type) {
    case 'setFilters':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        filtersDirty: JSON.stringify({ ...state.filters, ...action.payload }) !== JSON.stringify(initialFilters),
      }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as CompanyListContext, StoreProvider as CompanyListProvider }
