import { forwardRef } from 'react'

// Types
import KNLoadingButtonProps from './types'

// Custom KN styles
import KNLoadingButtonRoot from './KNLoadingButtonRoot'

const KNLoadingButton = forwardRef<HTMLButtonElement, KNLoadingButtonProps>(
  ({ children, ...rest }, ref) => (
    <KNLoadingButtonRoot {...rest} ref={ref}>
      {children}
    </KNLoadingButtonRoot>
  )
)

export default KNLoadingButton
