import { useState, useCallback } from 'react'
import { addDays, formatISO, isFuture, parseISO } from 'date-fns'
import { uniqueId } from 'lodash'

interface SuppressFlag {
  suppressFlagName: string
  suppressIndefinitely: () => void
  suppressForDays: (days: number) => void
  isSuppressed: boolean
}

const useSuppressFlag = (name?: string): SuppressFlag => {
  const finalName = name ?? uniqueId('suppress-')

  const suppressIndefinitely = () => {
    localStorage.setItem(finalName, formatISO(new Date(2999, 11, 31)))
  }

  const suppressForDays = (days: number) => {
    localStorage.setItem(finalName, formatISO(addDays(new Date(), days)))
  }

  const flagValue = localStorage.getItem(finalName)
  const isSuppressed = flagValue ? isFuture(parseISO(flagValue)) : false

  return { suppressFlagName: finalName, suppressIndefinitely, suppressForDays, isSuppressed }
}

export default useSuppressFlag
