import { useState, useEffect, useCallback, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import InfoIcon from '@mui/icons-material/Info'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import { DriverListContext, DriverFiltersValues } from 'context/drivers/DriverListContext'
import DriverFilters from './DriverFilters'
import DriverTable from './DriverTable'
import CreateOrEditDriverDialog from './CreateOrEditDriverDialog'

// Functional
import { getDrivers, sortDrivers } from './DriverManager.service'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { hasRole } from 'global/helpers/authorization'
import { sleep } from 'global/helpers/sleep'

// Types
import { DriverData } from './DriverManager.types'
import { Role } from 'context/authentication/Role.types'

const DriverManager = (): ReactElement => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [driversData, setDriversData] = useState<DriverData[]>([])
  const [filteredDriversData, setFilteredDriversData] = useState<DriverData[]>([])
  const [driverListState, driverListDispatch] = useContext(DriverListContext)
  const [createOrEditDriverDialogOpen, setCreateOrEditDriverDialogOpen] = useState(false)

  const fetchData = async (backgroundLoading = false): Promise<void> => {
    if (backgroundLoading) {
      driverListDispatch({ type: 'setBackgroundLoading', payload: true })
      await sleep(1000)
    } else {
      setLoading(true)
    }
    setDriversData(await getDrivers())
    if (backgroundLoading) {
      driverListDispatch({ type: 'setBackgroundLoading', payload: false })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [])

  useEffect(() => {
    filterData(driverListState.filters)
  }, [driversData, driverListState.filters])

  const handleOnChange = useCallback(
    async (updatedDriver: DriverData, action: string): Promise<void> => {
      if (action === 'create') {
        const updatedDriversData = [...driversData, updatedDriver].sort(sortDrivers)
        setDriversData(updatedDriversData)
      } else if (action === 'edit') {
        const updatedDriversData = driversData.map((driver) =>
          updatedDriver.cid === driver.cid ? updatedDriver : driver
        )
        setDriversData(updatedDriversData)
      } else if (action === 'delete') {
        const updatedDriversData = driversData.filter((driver) => updatedDriver.cid !== driver.cid)
        setDriversData(updatedDriversData)
      }
      await fetchData(true)
    },
    [driversData]
  )

  const filterData = (filters: DriverFiltersValues): void => {
    setFilteredDriversData(
      driversData.filter((driver) => {
        const lowercaseKeywords = filters.keywords?.map((keyword: string) => keyword.toLowerCase())
        let keywordsCondition = true
        if (lowercaseKeywords?.length) {
          const lowercaseValues = [driver.name, driver.contactNumber ?? '', driver.email ?? ''].map((value: string) =>
            value.toLowerCase()
          )
          keywordsCondition = lowercaseValues
            .map((value) => {
              return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
            })
            .some((condition: boolean) => condition)
        }

        return [keywordsCondition].every((condition: boolean) => condition)
      })
    )
  }

  const handleAddClick = useCallback((): void => {
    setCreateOrEditDriverDialogOpen(true)
    analyticsEvent('polestar_cs_add_driver_button_clicked')
  }, [])

  const handleCreateOrEditDialogAction = useCallback(
    async (updatedDriver: DriverData, action: string): Promise<void> => {
      setCreateOrEditDriverDialogOpen(false)
      await handleOnChange(updatedDriver, action)
    },
    [driversData]
  )

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditDriverDialogOpen(false)
  }, [])

  return (
    <Container maxWidth="xl" data-test="drivers-container">
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.cs.driver_manager.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          <Paper elevation={8} sx={{ padding: 2 }}>
            <Stack direction="column" spacing={1} mb={1}>
              <Stack
                spacing={1}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'start', md: 'center' }}
                mb={2}
              >
                <KNTypography variant="h4">
                  {t('screens.cs.driver_manager.drivers_count', { count: filteredDriversData.length })}
                </KNTypography>
                {hasRole(Role.Editor) && (
                  <KNButton
                    onClick={handleAddClick}
                    data-test="add-new-driver-button"
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    {t('screens.cs.driver_manager.card.actions.new')}
                  </KNButton>
                )}
              </Stack>
              <DriverFilters />
              <KNCaption icon={<InfoIcon />}>
                {t('screens.cs.driver_manager.disclaimer')}
              </KNCaption>
            </Stack>
            <DriverTable drivers={filteredDriversData} onChange={handleOnChange} />
          </Paper>
        </>
      )}
      {hasRole(Role.Editor) && (
        <CreateOrEditDriverDialog
          data-test="edit-dialog"
          open={createOrEditDriverDialogOpen}
          onAction={handleCreateOrEditDialogAction}
          onClose={handleCreateOrEditDialogClose}
        />
      )}
    </Container>
  )
}

export default DriverManager
