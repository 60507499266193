import colors from 'assets/theme/base/colors'

const { transparent } = colors

// types
type Types = any

const formControl: Types = {
  styleOverrides: {
    root: {
      backgroundColor: `${
        transparent.main as string
      } !important`,
    },
  },
}

export default formControl
