import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, ValidateResult } from 'react-hook-form'

// @mui imports
import Grid from '@mui/material/Grid'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import { VehicleData } from './VehicleManager.types'
import { createVehicle, editVehicle } from './VehicleManager.service'
import { getDataForCreateVehicle, getDataForEditVehicle } from './VehicleManager.helpers'

interface CreateOrEditVehicleDialogPayload {
  vehicle: VehicleData
}

interface CreateOrEditVehicleDialogProps {
  payload?: CreateOrEditVehicleDialogPayload
  open: boolean
  onAction: (updatedVehicle: VehicleData, action: string) => void
  onClose: () => void
}

export interface CreateOrEditVehicleFormValues {
  vehicleType: string
  licensePlate: string
  displayLicensePlate?: string
  weightCapacity?: number
  fuelType?: string
  makeModel?: string
  assetId?: string
}

const CreateOrEditVehicleDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: CreateOrEditVehicleDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, reset, control, formState, setError } = useForm<CreateOrEditVehicleFormValues>()

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          licensePlate: payload?.vehicle.displayLicensePlate ?? payload?.vehicle.licensePlate,
          vehicleType: payload?.vehicle.vehicleType,
          weightCapacity: payload?.vehicle.weightCapacity,
          fuelType: payload?.vehicle.fuelType,
          makeModel: payload?.vehicle.makeModel,
          assetId: payload?.vehicle.assetId,
        })
      )
    }
  }, [open])

  const onSubmit: SubmitHandler<CreateOrEditVehicleFormValues> = async (data: CreateOrEditVehicleFormValues) => {
    try {
      if (payload) {
        await editVehicle(payload.vehicle.id, data)
        onAction(getDataForCreateVehicle(data), 'create')
        analyticsEvent('polestar_cs_vehicle_updated')
      } else {
        await createVehicle(data)
        onAction(getDataForCreateVehicle(data), 'create')
        analyticsEvent('polestar_cs_vehicle_created')
      }
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={
          payload
            ? `${payload.vehicle.displayLicensePlate} — ${t('screens.cs.vehicle_manager.card.actions.edit')}`
            : t('screens.cs.vehicle_manager.card.actions.new')
        }
        actions={
          <>
            <KNLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {payload
                ? t('screens.cs.vehicle_manager.add_edit_vehicle.update')
                : t('screens.cs.vehicle_manager.add_edit_vehicle.create')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Grid container spacing={2}>
          <Grid data-test="license-plate" item xs={12} sm={6}>
            <KNFormText
              name="licensePlate"
              label={t('screens.cs.vehicle_manager.add_edit_vehicle.license_plate')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              disabled={!!payload}
            />
          </Grid>
          <Grid data-test="vehicle-type" item xs={12} sm={6}>
            <KNFormDropdown
              name="vehicleType"
              label={t('screens.cs.vehicle_manager.add_edit_vehicle.vehicle_type')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              options={['TRUCK', 'TRAILER'].map((type) => {
                return {
                  value: type,
                  label: t(`screens.cs.vehicle_manager.vehicle_types.${type}`),
                }
              })}
            />
          </Grid>
          <Grid data-test="weight-capacity" item xs={6}>
            <KNFormText
              name="weightCapacity"
              label={t('screens.cs.vehicle_manager.add_edit_vehicle.weight_capacity')}
              control={control}
              rules={{
                validate: {
                  isNumber: (value): ValidateResult => {
                    if (value && isNaN(Number(value))) {
                      return t('form.validation.number')
                    }
                    return true
                  },
                },
              }}
              endAdornment="kg"
            />
          </Grid>
          <Grid data-test="fuel-type" item xs={6}>
            <KNFormDropdown
              name="fuelType"
              label={t('screens.cs.vehicle_manager.add_edit_vehicle.fuel_type')}
              control={control}
              options={['DIESEL', 'PETROL', 'CNG', 'LNG', 'ELECTRIC'].map((type) => {
                return {
                  value: type,
                  label: t(`screens.cs.vehicle_manager.fuel_types.${type}`),
                }
              })}
            />
          </Grid>
          <Grid data-test="make-model" item xs={12} sm={6}>
            <KNFormText
              name="makeModel"
              label={t('screens.cs.vehicle_manager.add_edit_vehicle.make_model')}
              control={control}
            />
          </Grid>
          <Grid data-test="asset-id" item xs={12} sm={6}>
            <KNFormText
              name="assetId"
              label={t('screens.cs.vehicle_manager.add_edit_vehicle.asset_id')}
              control={control}
            />
          </Grid>
        </Grid>
      </KNDialog>
    </KNForm>
  )
}

export default CreateOrEditVehicleDialog
