import { ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const KNDetailsPopoverLoader = (): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: isMobile ? '20rem' : '30rem',
        height: isMobile ? '20rem' : '30rem',
        backgroundColor: '#e9ecef',
      }}
    >
      <CircularProgress size={64} color="primary" />
    </Stack>
  )
}

export default KNDetailsPopoverLoader
