import { ReactElement } from 'react'
import { Switch, Redirect } from 'react-router-dom'

// @mui imports //
import useMediaQuery from '@mui/material/useMediaQuery'
import Stack from '@mui/material/Stack'

// KN components //
import theme from 'assets/theme'
import GuardedRoute from './GuardedRoute'
import OperatorAdminRoute from './OperatorAdminRoute'
import ErrorCV from 'screens/ErrorPage/ErrorCV'

// Screens Shared //
import DrawerMenu from 'modules/DrawerMenu/DrawerMenu'
import TopNavigation from 'modules/TopNavigation/TopNavigation'
import Support from 'modules/Support/Support'
import ProfilePage from 'screens/ProfilePage/ProfilePage'
import ReleaseNotes from 'screens/ReleaseNotes/ReleaseNotes'
import UserManager from 'screens/UserManager/UserManager'
import CompanyManager from 'screens/CompanyManager/CompanyManager'
import CarrierManager from 'screens/CarrierManager/CarrierManager'
import MessageManager from 'screens/MessageManager/MessageManager'

import CSRoot from './CSRoot'
import DriverManager from 'screens/DriverManager/DriverManager'
import VehicleManager from 'screens/VehicleManager/VehicleManager'
import Playground from 'screens/Playground/Playground'

// Functional //
import { getStartPage } from 'context/authentication/User.helpers'

// Context //
import { useMenuContext } from 'context/menu/MenuContext'
import { NavigationContextProvider } from 'context/navigation/NavigationContext'
import { DriverListProvider } from 'context/drivers/DriverListContext'
import { VehicleListProvider } from 'context/vehicles/VehicleListContext'
import { UserListProvider } from 'context/users/UserListContext'
import { CompanyListProvider } from 'context/companies/CompanyListContext'
import { CarrierListProvider } from 'context/carriers/CarrierListContext'

const Home = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const startPage = getStartPage()

  // Drawer Menu
  const { menuState } = useMenuContext()
  const { menuExtended } = menuState.menuContext

  return (
    <>
      <NavigationContextProvider>
        <DrawerMenu />
        <Stack
          direction="column"
          paddingY={2}
          sx={{
            marginLeft: isMobile ? 0 : menuExtended ? '270px' : '120px',
            marginRight: isMobile ? 0 : '16px',
            transition: 'margin-left 0.1s',
            minHeight: '100vh',
          }}
        >
          <TopNavigation />
          <Switch>
            <GuardedRoute exact path="/">
              <Redirect to={startPage} />
            </GuardedRoute>
            <GuardedRoute path="/visibility-dashboard/:type" companyTypeGuard="Customer" excludedRoles={['Pairer']}>
              <ErrorCV />
            </GuardedRoute>
            <GuardedRoute path="/pairing" visibilityGuard="Max" excludedRoles={['Viewer', 'Guest']}>
              <ErrorCV />
            </GuardedRoute>
            <GuardedRoute exact path="/drivers" companyTypeGuard="Carrier">
              <Support moduleName="cs" />
              <DriverListProvider>
                <DriverManager />
              </DriverListProvider>
            </GuardedRoute>
            <GuardedRoute exact path="/vehicles" companyTypeGuard="Carrier">
              <Support moduleName="cs" />
              <VehicleListProvider>
                <VehicleManager />
              </VehicleListProvider>
            </GuardedRoute>
            <GuardedRoute exact path="/users" adminOnly>
              <UserListProvider>
                <UserManager />
              </UserListProvider>
            </GuardedRoute>
            <GuardedRoute exact path="/companies" adminOnly>
              <CompanyListProvider>
                <CompanyManager />
              </CompanyListProvider>
            </GuardedRoute>
            <GuardedRoute exact path="/carriers" adminOnly>
              <CarrierListProvider>
                <CarrierManager />
              </CarrierListProvider>
            </GuardedRoute>
            <GuardedRoute exact path="/rejected-messages" adminOnly>
              <MessageManager />
            </GuardedRoute>
            <GuardedRoute exact path="/profile" noRedirect>
              <ProfilePage />
            </GuardedRoute>
            <OperatorAdminRoute exact path="/playground">
              <Playground />
            </OperatorAdminRoute>
            <GuardedRoute path="/help&info" noRedirect>
              <ReleaseNotes />
            </GuardedRoute>
            <>
              <CSRoot />
            </>
          </Switch>
        </Stack>
      </NavigationContextProvider>
    </>
  )
}

export default Home
