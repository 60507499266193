import colors from 'assets/theme/base/colors'

const { primary, transparent, dark } = colors

// types
type Types = any

const paginationItem: Types = {
  styleOverrides: {
    root: {
      '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: primary.contrastText,
        color: dark.main,
      },

      '&:hover, &:focus': {
        backgroundColor: transparent.main,
        color: dark.focus,
      },
    },
  },
}

export default paginationItem
