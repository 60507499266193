import { useEffect, useState, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

// KN imports
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNDialog from './KNDialog'
import { KNConfirmationDialogProps } from './types'
import useSuppressFlag from './useSuppressFlag'

const KNConfirmationDialog = ({
  message,
  continueLabel,
  skipConfirmationFlag,
  open,
  onAction,
  onClose,
}: KNConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation()
  const [skipConfirmationActive, setSkipConfirmationActive] = useState(false)
  const { suppressIndefinitely } = useSuppressFlag(skipConfirmationFlag)

  const handleConfirmation = useCallback(() => {
    if (skipConfirmationFlag && skipConfirmationActive) {
      suppressIndefinitely()
    }
    onAction()
  }, [skipConfirmationFlag, skipConfirmationActive])

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      actions={
        <>
          <KNLoadingButton type="submit" color="primary" variant="contained" onClick={handleConfirmation}>
            {continueLabel ?? t('general.continue')}
          </KNLoadingButton>
        </>
      }
      maxWidth="xs"
    >
      <DialogContentText component="div">{message}</DialogContentText>
      {skipConfirmationFlag && (
        <DialogContentText component="div" mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                value={skipConfirmationActive}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setSkipConfirmationActive(event.target.checked || false)
                }
                sx={{
                  py: 0,
                }}
              />
            }
            label={t('general.dont_show_again')}
          />
        </DialogContentText>
      )}
    </KNDialog>
  )
}

export default KNConfirmationDialog
