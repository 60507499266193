import i18n from 'i18n'

export const resetPasswordTranslations = (): Record<any, any> => {
  const resetPassword = 'shared.reset_password'
  return {
    translation: {
      loading: i18n.t(`${resetPassword}.loading`),
      new_password: i18n.t(`${resetPassword}.new_password`),
      backButton: i18n.t(`${resetPassword}.backButton`),
      resetSuccessful: i18n.t(`${resetPassword}.resetSuccessful`),
      resetSuccessfulMsg: i18n.t(`${resetPassword}.resetSuccessfulMsg`),
      resetFail: i18n.t(`${resetPassword}.resetFail`),
      resetFailMsg: i18n.t(`${resetPassword}.resetFailMsg`),
      resetPasswordButton: i18n.t(`${resetPassword}.resetPasswordButton`),
      resetPasswordRequirements: i18n.t(`${resetPassword}.resetPasswordRequirements`),
      invalidactioncode: i18n.t(`${resetPassword}.invalidactioncode`),
      expiredactioncode: i18n.t(`${resetPassword}.expiredactioncode`),
      email: i18n.t(`${resetPassword}.email`),
      emailResetSuccessfulMsg: i18n.t(`${resetPassword}.emailResetSuccessfulMsg`),
    },
  }
}
