import { ReactElement } from 'react'
import { Marker, Circle } from '@react-google-maps/api'

import { getMarkerIcon, getZIndex, getGeofenceOptions } from './KNMap.helpers'
import { KNMapMarkerProps } from './types'

const KNMapMarker = ({
  marker,
  onMarkerClick,
  clusterer,
}: KNMapMarkerProps): ReactElement => (
  <>
    {marker.geofence && (
      <Circle
        center={{
          lat: marker.latitude,
          lng: marker.longitude,
        }}
        radius={marker.geofence}
        options={getGeofenceOptions(marker)}
      />
    )}
    <Marker
      position={{
        lat: marker.latitude,
        lng: marker.longitude,
      }}
      icon={getMarkerIcon(marker)}
      zIndex={getZIndex(marker.type)}
      clusterer={clusterer}
      clickable={Boolean(onMarkerClick)}
      onClick={(event: google.maps.MapMouseEvent) => onMarkerClick?.(marker)}
    />
  </>
)

export default KNMapMarker
