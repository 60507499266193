import { ReactElement } from 'react'

// KN components
import GuardedRoute from './GuardedRoute'

// Screens Shared
import Support from 'modules/Support/Support'

// Screens CS
import TripDashboard from 'screens/TripDashboard/TripDashboard'
import MapView from 'screens/TripDashboard/MapView'
import TripDetails from 'screens/TripDetails/TripDetails'

// Context
import { TripListProvider } from 'context/trips/TripListContext'
import { TripMapProvider } from 'context/trips/TripMapContext'
import { StopsViewProvider } from 'context/trips/StopsViewContext'

const CSRoot = (): ReactElement => {
  return (
    <TripListProvider>
      <GuardedRoute exact path="/live-map" companyTypeGuard="Carrier">
        <Support moduleName="cs" />
        <TripMapProvider>
          <MapView />
        </TripMapProvider>
      </GuardedRoute>
      <GuardedRoute exact path="/trips" companyTypeGuard="Carrier">
        <Support moduleName="cs" />
        <TripDashboard />
      </GuardedRoute>
      <GuardedRoute exact path="/trips/:tripCid" companyTypeGuard="Carrier">
        <Support moduleName="cs" />
        <StopsViewProvider>
          <TripDetails />
        </StopsViewProvider>
      </GuardedRoute>
    </TripListProvider>
  )
}

export default CSRoot
