// @mui imports
import Typography from '@mui/material/Typography'
import { CSSInterpolation } from '@mui/system'
import { styled } from '@mui/material/styles'

// types
import KNTypographyProps from './types'

const KNTypographyRoot = styled(Typography)(({ theme }) => (props: KNTypographyProps): CSSInterpolation => {
  const { palette } = theme
  const { color = 'primary', textTransform, opacity } = props

  const { grey } = palette

  const greyColors = {
    'grey-100': grey[100],
    'grey-200': grey[200],
    'grey-300': grey[300],
    'grey-400': grey[400],
    'grey-500': grey[500],
    'grey-600': grey[600],
    'grey-700': grey[700],
    'grey-800': grey[800],
    'grey-900': grey[900],
  }

  const validColors = [
    'transparent',
    'white',
    'black',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'grey-100',
    'grey-200',
    'grey-300',
    'grey-400',
    'grey-500',
    'grey-600',
    'grey-700',
    'grey-800',
    'grey-900',
  ]

  // color value
  let colorValue = color

  if (validColors.find((el) => el === color)) {
    colorValue = palette[color] ? palette[color].main : greyColors[color]
  }

  return {
    color: colorValue,
    opacity,
    textTransform,
    textDecoration: 'none',
  }
})

export default KNTypographyRoot
