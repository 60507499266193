import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import KNButtonGroup from 'components/KN_Molecules/KNButtonGroup/KNButtonGroup'
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormButtonGroupProps } from './types'

const KNFormButtonGroup = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  options,
  orientation,
  size,
  sx,
  disabled,
}: KNFormButtonGroupProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting}>
        {label && <FormLabel>{label}</FormLabel>}
        <KNButtonGroup value={value} onChange={onChange} options={options} orientation={orientation} size={size} />
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormButtonGroup
