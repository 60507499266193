const hexToRgb = (color: string): string => {
  if (color.length === 4) {
    return [
      parseInt(`0x${color[1]}${color[1]}`),
      parseInt(`0x${color[2]}${color[2]}`),
      parseInt(`0x${color[3]}${color[3]}`),
    ].join(',')
  } else if (color.length === 7) {
    return [
      parseInt(`0x${color[1]}${color[2]}`),
      parseInt(`0x${color[3]}${color[4]}`),
      parseInt(`0x${color[5]}${color[6]}`),
    ].join(',')
  }
  return '0,0,0'
}

export default hexToRgb
