import { useEffect, ReactElement, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import ListItem from '@mui/material/ListItem'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormFile from 'components/KN_Molecules/KNForm/KNFormFile'
import { BulletList } from 'screens/Weblink/RequestAccountPromo'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import { uploadBulkUsers } from './UserManager.service'

interface BulkCreateUsersDialogProps {
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface BulkCreateUsersDialogFormValues {
  csv: File
}

const BulkCreateUsersDialog = ({ open, onAction, onClose }: BulkCreateUsersDialogProps): ReactElement => {
  const { t } = useTranslation()
  const [createdUsers, setCreatedUsers] = useState<string[] | null>(null)
  const { handleSubmit, reset, control, formState, setValue, setError, clearErrors } =
    useForm<BulkCreateUsersDialogFormValues>()

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          csv: null,
        })
      )
      setCreatedUsers(null)
    }
  }, [open])

  const onSubmit: SubmitHandler<BulkCreateUsersDialogFormValues> = async (data: BulkCreateUsersDialogFormValues) => {
    try {
      setCreatedUsers(await uploadBulkUsers(data))
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={createdUsers ? onAction : onClose}
        closeLabel={createdUsers ? t('general.close') : t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={t('screens.shared.user_manager.bulk_create_users.bulk_create')}
        actions={
          !createdUsers && (
            <>
              <KNLoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={formState.isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {t('general.send')}
              </KNLoadingButton>
            </>
          )
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        {createdUsers ? (
          <DialogContentText component="div">
            {createdUsers.length == 0 ? (
              <KNTypography color="text.main">
                {t('screens.shared.user_manager.bulk_create_users.no_new_users_created')}
              </KNTypography>
            ) : (
              <>
                <KNTypography color="text.main">
                  {t('screens.shared.user_manager.bulk_create_users.created_users_list')}
                </KNTypography>
                <BulletList sx={{ color: 'text.main' }}>
                  {createdUsers.map((createdUser) => (
                    <ListItem key={createdUser}>{createdUser}</ListItem>
                  ))}
                </BulletList>
              </>
            )}
          </DialogContentText>
        ) : (
          <Stack spacing={2}>
            <KNFormFile
              label={t('screens.shared.user_manager.bulk_create_users.choose_file')}
              name="csv"
              control={control}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              accept="text/csv"
              size="small"
            />
          </Stack>
        )}
      </KNDialog>
    </KNForm>
  )
}

export default BulkCreateUsersDialog
