import { forwardRef } from 'react'

// Types
import KNChipProps from './types'

// Custom styles for KNTypography
import KNChipRoot from './KNChipRoot'

const KNChip = forwardRef<HTMLDivElement, KNChipProps>(({ children, color, variant, ...rest }, ref) => (
  <KNChipRoot {...rest} ref={ref} ownerState={{ color, variant }}>
    {children}
  </KNChipRoot>
))

export default KNChip
