import { ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'

// @mui imports //
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'

// KN components //
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'

// Functional //
import { processDefaultValues } from 'global/helpers/form'
import { getCompanyVisibilityLevels } from './PlaygroundForms.helpers'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import { CreateCompanyDialogProps, CompanyProps } from 'screens/Playground/Playground.types'

const CreateCompanyDialog = ({
  companyData,
  handleClose,
  dialogOpen,
  scenario,
  handleSave,
}: CreateCompanyDialogProps): ReactElement => {
  const { handleSubmit, reset, control, formState, setValue, getValues, setError, clearErrors } =
    useForm<CompanyProps>()
  const { translation } = playgroundTranslations()

  useEffect(() => {
    if (companyData) reset(processDefaultValues(companyData))
    else resetForm()
  }, [companyData])

  const resetForm = (): void => {
    reset(
      processDefaultValues({
        name: null,
        visibilityLevel: null,
        createCompany: false,
      })
    )
  }

  const onSave = (data: CompanyProps): void => {
    data.createCompany = true
    handleClose()
    resetForm()
    handleSave(data)
  }

  const onClose = (): void => {
    clearErrors()
    handleClose()
  }

  const getDialogContent = (): ReactElement => (
    <Box my={2}>
      <Box mb={3}>
        <Stack spacing={3}>
          <Box mt={1}>
            <KNFormText
              name="name"
              label={translation.companyNameLabel}
              control={control}
              onType={clearErrors}
              rules={{
                required: translation.required,
              }}
            />
          </Box>
          {!scenario && (
            <Box mt={1}>
              <KNFormRadioGroup
                label={translation.companyVisibilityLevelLabel}
                name="visibilityLevel"
                control={control}
                rules={{
                  required: translation.required,
                }}
                options={getCompanyVisibilityLevels()}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  )
  return (
    <Dialog
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          zIndex: 9999,
        },
      }}
      maxWidth="xs"
      fullWidth
      open={dialogOpen}
    >
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          disabled={Object.keys(formState.errors).length > 0}
          onClick={handleSubmit(onSave)}
        >
          {translation.companyCreateButton}
        </KNButton>
        <KNButton
          size="medium"
          color="primary"
          type="button"
          variant="contained"
          startIcon={<CloseIcon fontSize="inherit" />}
          onClick={onClose}
        >
          {translation.shipmentCancelButton}
        </KNButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCompanyDialog
