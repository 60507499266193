// types
type Types = any

const autocomplete: Types = {
  styleOverrides: {
    inputRoot: {
      paddingTop: '9px',
      paddingBottom: '9px',
      '&.MuiInputBase-sizeSmall': {
        paddingTop: '3px',
        paddingBottom: '3px',
      },
      '&.MuiOutlinedInput-root .MuiAutocomplete-input': {
        paddingTop: '3px',
        paddingBottom: '3px',
      },
    },
  },
}

export default autocomplete
